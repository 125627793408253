import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import Select from "../../Partials/Select/Select";
import {
  selectConstants,
  selectDynamicConstants,
} from "../../Partials/constantsSlice";
import Input from "../../Partials/Input/Input";
import ArtemisKeyboardDatePicker from "../../Partials/KeyboardDatePicker/ArtemisKeyboardDatePicker";

export default function CreditForm({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  index,
  handleRemove,
  setFieldValue,
  constants,
  coEmpIndex,
}) {
  // const safeErrors = errors || {};
  // const safeTouched = touched || {};
  const safeValues = values || {
    creditType: "",
    remainingCapital: "",
    monthlyRate: "",
    endDate: null,
  };
  const c = useSelector(selectConstants);
  const dynamicConstants = useSelector(selectDynamicConstants);
  const creditTypeConstants = c.creditType;
  // console.log(index);
  return (
    <div className="grid-cols-12 grid col-gap-8 items-end relative p-4">
      {handleRemove && (
        <div
          className="absolute cursor-pointer"
          style={{ right: "15px", top: "-15px" }}
          onClick={handleRemove}
        >
          <DeleteIcon className="icon-red" />
          <span className="font-qSCBold text-small text-red">
            Supprimer le crédit
          </span>
        </div>
      )}
      <div className="col-span-12 sm:col-span-4">
        <Select
          label="Titulaire du crédit"
          value={safeValues.creditHolder}
          name={`${coEmpIndex}.credits.${index}.creditHolder`}
          onChange={handleChange}
          trailIconName="arrowUpDown"
          options={
            dynamicConstants.creditHolders
              ? dynamicConstants.creditHolders.map((key) => ({
                  label: key.label,
                  value: key.id,
                }))
              : [{ label: "Loading", value: "loading" }]
          }
        />
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Select
          label="Type de crédit"
          value={safeValues.creditType}
          name={`${coEmpIndex}.credits.${index}.creditType`}
          onChange={handleChange}
          trailIconName="arrowUpDown"
          options={Object.keys(creditTypeConstants).map((key) => ({
            label: creditTypeConstants[key],
            value: key,
          }))}
        />
      </div>
      <div className="hidden sm:block sm:col-span-4" />
      <div className="col-span-12 sm:col-span-4">
        <Input
          label="Capital retant du"
          type="number"
          trailIconName="euro"
          onChange={handleChange}
          onBlur={handleBlur}
          value={safeValues.remainingCapital}
          name={`${coEmpIndex}.credits.${index}.remainingCapital`}
        />
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Input
          label="Montant de la mensualité"
          type="number"
          step="0.01"
          trailIconName="euro"
          onChange={handleChange}
          onBlur={handleBlur}
          value={safeValues.monthlyRate}
          name={`${coEmpIndex}.credits.${index}.monthlyRate`}
        />
      </div>
      <div className="hidden sm:block sm:col-span-4" />
      <div className="col-span-12 sm:col-span-4">
        <ArtemisKeyboardDatePicker
          name={`${coEmpIndex}.credits.${index}.endDate`}
          label="Date de fin"
          setFieldValue={setFieldValue}
          value={safeValues.endDate}
        />
      </div>
    </div>
  );
}
