import React, { useEffect, useState } from "react";
import "./Chat.scss";
import Drift from "react-driftjs";
import { useDispatch, useSelector } from "react-redux";
import { InlineWidget } from "react-calendly";
import { IconButton, Modal, Tooltip } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  getBroker,
  selectBroker,
  // selectBroker,
  selectUser,
} from "../Authentication/authenticationSlice";
import { ReactComponent as MailIcon } from "./assets/mail.svg";
import { ReactComponent as BlackMailIcon } from "./assets/mail-black.svg";
import MailTestIcon from "./assets/mailTest.svg";
import { ReactComponent as CalendarIcon } from "./assets/calendar-rdv.svg";
import { ReactComponent as TelephoneIcon } from "./assets/telephone-rdv.svg";
import { ReactComponent as NavigationIcon } from "./assets/navigation.svg";
import avatarFallback from "./assets/fallback-avatar.svg";
// import Button from "../Partials/Button/Button";
// import calendarWhite from "../../images/calendar-white.svg";
import { calendlyUrl } from "../../utils";
import ImageWithFallback from "../Partials/ImageWithFallback/ImageWithFallback";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const Chat = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    dispatch(getBroker());
  }, [dispatch]);
  const broker = useSelector(selectBroker);
  const userInformation = useSelector(selectUser);
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isModalOpen]);
  // const openModal = () => {
  //   setIsModalOpen(true);
  // };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { height } = windowDimensions;
  const [isInformationModalOpen, setIsInformationModalOpen] = useState(false);
  return (
    <div>
      <Modal
        disableBackdropClick={false}
        open={isInformationModalOpen}
        onClose={() => setIsInformationModalOpen(false)}
      >
        <div className="flex items-center justify-center w-full h-full">
          <div className="relative flex flex-col w-full max-w-lg px-10 pt-8 bg-white">
            <div className="absolute top-0 right-0 flex mt-4 mr-4">
              <IconButton onClick={() => setIsInformationModalOpen(false)}>
                <Close />
              </IconButton>
            </div>
            <div className="flex justify-center mb-6 text-red-500 font-qSCBold text-modalHeader">
              Votre Conseiller
            </div>
            <div className="flex items-center mb-5 text-blue-500 font-qSCBold text-taxesHeader">
              <div className="w-12 h-12 mr-4 border-2 border-yellow-500 rounded-full">
                {/* <img
                  src="https://www.artemiscourtage.com/wp-content/uploads/sites/39/2020/06/Vincent-Argentino.jpg"
                  alt="avatar"
                  className="rounded-full"
                /> */}
                <ImageWithFallback
                  src={broker.avatar}
                  fallbackSrc={avatarFallback}
                  alt="avatar"
                  className="rounded-full"
                />
              </div>
              {broker.firstName} {broker.lastName}
            </div>
            <div className="flex items-center mb-4 font-qSCRegular">
              <TelephoneIcon fill="#000000" className="mr-4" />
              {broker.phoneNumber}
            </div>
            <div className="flex items-center mb-4 font-qSCRegular">
              <BlackMailIcon fill="#000000" className="mr-4" />
              {broker.email}
            </div>
            <div
              className="flex items-center mb-4 cursor-pointer font-qSCRegular"
              onClick={() => window.open(broker.calendly, "_blank", "noopener")}
            >
              <CalendarIcon fill="#000000" className="mr-4" />
              Prendre rendez-vous
            </div>
            <div className="items-center py-2 -ml-10 -mr-10 font-qSCRegular cool-blue">
              <div className="flex px-10 py-4">
                <NavigationIcon fill="#000000" className="mr-4" />
                Accéder à la FAQ
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div style={{ height: "20vh" }} />
      <div className="fixed bottom-0 z-40 block w-full pb-4 sm:hidden">
        <div className="w-32 mx-auto">
          <CalendarIcon
            onClick={() => window.open(broker.calendly, "_blank", "noopener")}
            fill="#4860BD"
            className="inline w-6 mr-4 cursor-pointer"
          />
          <Tooltip title={broker.phoneNumber}>
            <TelephoneIcon
              fill="#4860BD"
              onClick={() => setIsInformationModalOpen(true)}
              className="inline w-6 mr-4 cursor-pointer"
            />
          </Tooltip>

          <Tooltip title={broker.email}>
            <MailIcon
              onClick={() => setIsInformationModalOpen(true)}
              className="inline w-6 mr-4 cursor-pointer"
            />
          </Tooltip>
        </div>
      </div>
      <div className="fixed bottom-0 z-30 flex items-center w-full bg-white">
        <div className="flex items-center w-full h-full max-w-screen-lg px-2 py-4 mx-auto">
          <div>
            <div
              className="w-12 h-12 border-2 border-yellow-500 rounded-full cursor-pointer"
              onClick={() => setIsInformationModalOpen(true)}
            >
              {/* <img
                src="https://www.artemiscourtage.com/wp-content/uploads/sites/39/2020/06/Vincent-Argentino.jpg"
                alt="avatar"
                className="rounded-full"
              /> */}
              <ImageWithFallback
                src={broker.avatar}
                fallbackSrc={avatarFallback}
                alt="avatar"
                className="rounded-full"
              />
            </div>
          </div>
          <div className="flex items-end ml-4">
            <div className="hidden pr-4 sm:block">
              <p className="pb-2 chat-counsellor font-qSCBold">
                Votre conseiller
              </p>
              <p className="chat-name">
                <span>
                  {broker.firstName} {broker.lastName}
                </span>
              </p>
            </div>
            <div className="hidden sm:flex">
              <CalendarIcon
                onClick={() =>
                  window.open(broker.calendly, "_blank", "noopener")
                }
                fill="#4860BD"
                className="w-6 mr-4 cursor-pointer"
              />
              <Tooltip title={broker.phoneNumber}>
                <TelephoneIcon
                  fill="#4860BD"
                  onClick={() => setIsInformationModalOpen(true)}
                  className="w-6 mr-4 cursor-pointer"
                />
              </Tooltip>
              <Tooltip title={broker.email}>
                <img
                  alt="mail"
                  src={MailTestIcon}
                  onClick={() => setIsInformationModalOpen(true)}
                  className="w-6 mr-4 cursor-pointer"
                />
              </Tooltip>
            </div>
          </div>
        </div>
        {/* <div className="z-40 flex items-center w-full max-w-screen-lg px-4 mx-auto chat-section lg:px-0"> */}
        {/*  <div className="flex items-center justify-between p-1 chat-container sm:hidden sm:p-4"> */}
        {/*    <div className="flex flex-row items-center justify-between w-full chat-description"> */}
        {/*      <div className="chat-icon" /> */}
        {/*      <div className="flex flex-grow" /> */}
        {/*      <div className="flex items-center"> */}
        {/*        {broker.isDefault ? ( */}
        {/*          <div> */}
        {/*            <Button */}
        {/*              className="px-2 py-2 text-white uppercase bg-blue-500 shadow-sm font-qSCBold hover:bg-blue-500 sm:py-2 sm:px-6 sm:text-sm sm:leading-5" */}
        {/*              variant="next" */}
        {/*              withIcon */}
        {/*              type="button" */}
        {/*              onClick={openModal} */}
        {/*            > */}
        {/*              <span className="mr-2">Prendre rendez-vous</span> */}
        {/*              <span className="hidden sm:block">|</span> */}
        {/*              <div className="flex items-center h-full"> */}
        {/*                <img */}
        {/*                  className="sm:ml-2" */}
        {/*                  src={calendarWhite} */}
        {/*                  alt="button" */}
        {/*                /> */}
        {/*              </div> */}
        {/*            </Button> */}
        {/*          </div> */}
        {/*        ) : ( */}
        {/*          <div> */}
        {/*            <p className="inline chat-counsellor"> */}
        {/*              Votre conseiller est disponible au{" "} */}
        {/*            </p> */}
        {/*            <p className="inline chat-number">{broker.phoneNumber}</p> */}
        {/*          </div> */}
        {/*        )} */}
        {/*        /!* <div> */}
        {/*          <img src={calendarYellow} alt="calendar" /> */}
        {/*        </div> */}
        {/*        <div className="ml-3 font-qBold text-body">04/03/2020 15h</div> *!/ */}
        {/*      </div> */}
        {/*      <div className="flex flex-grow" /> */}
        {/*      /!* <div className="chat-button"> */}
        {/*      <i className="fa fa-comment" /> */}
        {/*    </div> *!/ */}
        {/*    </div> */}
        {/*  </div> */}
        {/*  <div className="hidden w-full sm:block"> */}
        {/*    <div className="flex items-center justify-between p-1 chat-container sm:p-2"> */}
        {/*      <div className="flex items-center chat-description"> */}
        {/*        <div className="chat-icon" /> */}
        {/*        <div> */}
        {/*          <p className="chat-counsellor">Votre conseiller</p> */}
        {/*          <p className="chat-name"> */}
        {/*            <span> */}
        {/*              {broker.firstName} {broker.lastName} */}
        {/*            </span>{" "} */}
        {/*            - {broker.agency} */}
        {/*          </p> */}
        {/*        </div> */}
        {/*      </div> */}
        {/*      <div className="flex items-center chat-description"> */}
        {/*        {broker.isDefault ? ( */}
        {/*          <div> */}
        {/*            <Button */}
        {/*              className="px-4 py-4 text-white uppercase bg-blue-500 shadow-sm font-qSCBold hover:bg-blue-500 sm:py-2 sm:px-6 sm:text-sm sm:leading-5" */}
        {/*              variant="next" */}
        {/*              withIcon */}
        {/*              type="button" */}
        {/*              // onClick={() => history.push("/calendly")} */}
        {/*              onClick={openModal} */}
        {/*            > */}
        {/*              <span className="mr-2">Prendre rendez-vous</span> */}
        {/*              <span className="hidden sm:block">|</span> */}
        {/*              <div className="flex items-center h-full"> */}
        {/*                <img */}
        {/*                  className="sm:ml-2" */}
        {/*                  src={calendarWhite} */}
        {/*                  alt="button" */}
        {/*                /> */}
        {/*              </div> */}
        {/*            </Button> */}
        {/*          </div> */}
        {/*        ) : ( */}
        {/*          <div> */}
        {/*            <p className="inline chat-counsellor"> */}
        {/*              Votre conseiller est disponible au{" "} */}
        {/*            </p> */}
        {/*            <p className="inline chat-number">{broker.phoneNumber}</p> */}
        {/*          </div> */}
        {/*        )} */}
        {/*      </div> */}
        {/*      <div /> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
        {isModalOpen && (
          <div className="h-full">
            <Modal
              title="Prendre rendez-vous"
              open={isModalOpen}
              hideControls
              hasNext={false}
              hasPrevious={false}
              hideModal={handleCloseModal}
            >
              <div
                className="mt-4"
                style={{ height: `calc(${height}px - 200px)` }}
              >
                <InlineWidget
                  pageSettings={{
                    backgroundColor: "ffffff",
                    hideEventTypeDetails: false,
                    hideLandingPageDetails: false,
                    primaryColor: "00a2ff",
                    textColor: "4d5055",
                  }}
                  prefill={{
                    email: userInformation.email,
                    // phoneNumber: `33${userInformation.phoneNumber}`,
                    // a1: `33${userInformation.phoneNumber}`,
                    // a2: `33${userInformation.phoneNumber}`,
                    // a3: `33${userInformation.phoneNumber}`,
                    name: `${userInformation.firstName} ${userInformation.lastName}`,
                  }}
                  styles={{
                    height: "100%",
                    width: "100%",
                  }}
                  url={calendlyUrl}
                />
              </div>
            </Modal>
          </div>
        )}
        {/* <div className="hidden w-1/12 mr-0 sm:mr-5 sm:block"> */}
        {/* <div className="chat-button"> */}
        {/* <i className="fa fa-comment" />
         */}
        {/* <Drift appId="hm273xrm7585" /> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Chat;
