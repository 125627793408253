/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import classNames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import NumberFormat from "react-number-format";
import { Tooltip, withStyles } from "@material-ui/core";
import userIcon from "../../../images/user.svg";
import homeIcon from "../../../images/home.svg";
import airplaneIcon from "../../../images/airplane.svg";
import mobileIcon from "../../../images/mobile.svg";
import documentIcon from "../../../images/document.svg";
import euroIcon from "../../../images/euro.svg";
import calendarIcon from "../../../images/calendar.svg";
import "./input.scss";

const icons = {
  user: userIcon,
  home: homeIcon,
  airplane: airplaneIcon,
  mobile: mobileIcon,
  document: documentIcon,
  euro: euroIcon,
  calendar: calendarIcon,
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function Input(props) {
  const allProps = { ...props, ...props.inputProps, ...props.InputProps };
  const {
    label,
    step,
    placeholder,
    value,
    onChange,
    name,
    error,
    touched,
    onBlur,
    helperText,
    noError,
    onClick,
    type,
    readOnly,
    trailIconName,
    leadingIconName,
    hasTrailButton,
    min,
    noFullWidth,
    isRequired,
    hasLabelIcon,
    labelIconText,
    max,
    autoComplete,
  } = allProps;
  const hasLeadingIcon = !!leadingIconName;
  const hasTrailIcon = !!trailIconName;
  const [formattedValue, setFormattedValue] = useState(value);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const customWrapperClass = classNames(
    {
      // "sm:pl-4": hasTrailIcon,
      "flex-grow": !noFullWidth,
    },
    "mt-1 relative rounded-md shadow-sm"
  );

  const customTrailContainerClass = classNames(
    {
      "left-0 pl-3": !hasTrailIcon,
      "right-0 pr-3": hasTrailIcon,
    },
    "absolute inset-y-0 flex items-center" // pointer-events-none
  );
  const customLeadingContainerClass = classNames(
    {
      "left-0 pl-3": hasLeadingIcon,
      "right-0 pr-3": !hasLeadingIcon,
    },
    "absolute inset-y-0 flex items-center pointer-events-none"
  );

  const customInputClass = classNames(
    {
      "border-red-500 border-solid border": error && touched,
      "focus:border-blue-500 focus:border-solid focus:border": !(
        error && touched
      ),
      "pl-10": hasLeadingIcon,
      "pr-10": hasTrailIcon,
      "w-full": !noFullWidth,
      // "pr-20": hasTrailButton,
    },
    "form-input block sm:text-sm sm:leading-5 artemis-input"
  );
  const customLabelClass = classNames(
    {
      "flex items-end": hasLabelIcon,
      block: !hasLabelIcon,
    },
    "text-sm font-medium leading-5 artemis-label"
  );
  const trailIconClickFunction =
    allProps.InputProps && allProps.InputProps.endAdornment
      ? allProps.InputProps.endAdornment.props.children.props.onClick
      : () => {};
  return (
    <>
      <div className="flex">
        <label htmlFor={name} className={customLabelClass}>
          {label}
          {isRequired && <span>*</span>}
        </label>
        {hasLabelIcon && (
          <span className="inline ml-2 artemis-label">
            <LightTooltip
              title={labelIconText}
              onClose={() => setTooltipOpen(false)}
              onClick={() => setTooltipOpen(true)}
              open={tooltipOpen}
              placement="top"
              enterTouchDelay={300}
            >
              <InfoIcon />
            </LightTooltip>
          </span>
        )}
      </div>
      {helperText && (
        <span className="relative text-gray-700 font-qBold text-small">
          {helperText}
        </span>
      )}
      <div className="flex">
        <div className={customWrapperClass}>
          {hasLeadingIcon && (
            <div className={customLeadingContainerClass}>
              <img src={icons[leadingIconName]} alt="input-icon" />
            </div>
          )}
          {type === "number" ? (
            <NumberFormat
              id={name}
              thousandSeparator=" "
              isNumericString
              className={customInputClass}
              style={{ minWidth: "240px" }}
              placeholder={placeholder}
              onValueChange={({ formattedValue: fv, value: v }) => {
                onChange({
                  target: {
                    name,
                    value: v,
                  },
                });
                setFormattedValue(fv);
              }}
              value={formattedValue}
              autoComplete={autoComplete || "on"}
              readOnly={readOnly}
              // onChange={onChange}
              onClick={onClick}
              onBlur={onBlur}
              name={name}
              min={min || 0}
              max={max || 1000000000}
              step={step || 1}
            />
          ) : type === "phoneNumber" ? (
            <NumberFormat
              id={name}
              format="## ## ## ## ##"
              // isNumericString
              className={customInputClass}
              style={{ minWidth: "240px" }}
              placeholder={placeholder}
              onValueChange={({ formattedValue: fv, value: v }) => {
                onChange({
                  target: {
                    name,
                    value: v,
                  },
                });
                setFormattedValue(fv);
                console.log("Value", v);
                console.log("Formatted value", fv);
              }}
              value={formattedValue}
              autoComplete={autoComplete || "on"}
              readOnly={readOnly}
              // onChange={onChange}
              onClick={onClick}
              onBlur={onBlur}
              name={name}
              min={min || 0}
              max={max || 1000000000}
              step={step || 1}
            />
          ) : (
            <input
              id={name}
              className={customInputClass}
              placeholder={placeholder}
              value={value}
              autoComplete={autoComplete || "on"}
              readOnly={readOnly}
              onChange={onChange}
              onClick={onClick}
              onBlur={onBlur}
              name={name}
              min={type === "number" ? min || 0 : ""}
              type={type || "text"}
              max={type === "number" ? max : 0}
              step={type === "number" ? step || 1 : ""}
              onKeyPress={
                type === "number"
                  ? (event) => {
                      if (value !== undefined) {
                        if (
                          value.toString().includes(".") &&
                          event.charCode === 46
                        ) {
                          event.preventDefault();
                        }
                        if (
                          event.charCode !== 46 &&
                          (event.charCode < 48 || event.charCode > 57)
                        ) {
                          event.preventDefault();
                        }
                      }
                    }
                  : () => {}
              }
            />
          )}

          {hasTrailIcon && (
            <div className={customTrailContainerClass}>
              {trailIconName === "addCalendar" ? (
                <AddIcon style={{ color: "#4860BD" }} />
              ) : (
                <div onClick={trailIconClickFunction} id="trail-icon">
                  <img src={icons[trailIconName]} alt="input-icon" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {hasTrailButton && (
        <div
          className="absolute inline-flex items-center -ml-px"
          style={{ top: "31px", right: "3px" }}
        >
          <button
            type="button"
            id={`${name}-decrease`}
            disabled={value <= 0}
            onClick={(e) => {
              onChange({
                ...e,
                target: { ...e.target, value: value - 1, name },
              });
              setFormattedValue(value - 1);
            }}
            className="inline-flex items-center px-4 text-sm leading-5 text-white transition duration-150 ease-in-out bg-blue-500 rounded-l-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-blue-500"
          >
            {/* svg */}
            <span className="text-header">-</span>
          </button>
          <button
            type="button"
            id={`${name}-increase`}
            onClick={(e) => {
              if (value < max) {
                onChange({
                  ...e,
                  target: { ...e.target, value: value + 1, name },
                });
                setFormattedValue(parseInt(value, 10) + 1);
              }
              return false;
            }}
            className="inline-flex items-center px-4 text-sm leading-5 text-white transition duration-150 ease-in-out bg-blue-500 rounded-r-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-blue-500"
          >
            {/* svg */}
            <span className="text-header">+</span>
          </button>
        </div>
      )}
      {!noError && (
        <p
          className="mt-2 text-sm leading-none tracking-normal artemis-error-text"
          style={{ minHeight: "2em" }}
          id={`${name}-error`}
        >
          {!!error && !!touched ? error : ""}
        </p>
      )}
    </>
  );
}
