import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Formik, Form } from "formik";
// import yoga from "../../../images/yoga.png";
import "./Login.scss";
import Button from "../../Partials/Button/Button";
import Input from "../../Partials/Input/Input";
import { sendMagicLink } from "../authenticationSlice";

const Login = (props) => {
  // const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    // evt.preventDefault();
    dispatch(sendMagicLink(values.email));
  };

  return (
    <div className="relative px-6 sm:pr-0 w-full">
      <div className=" max-w-md mx-auto">
        <h2 className="text-red-500 text-header pb-2">J’ai déjà un compte</h2>
        <p className="text-red-500 pb-8 font-qBold text-headline">
          et j’accède à mon dossier en cours
        </p>
        <p className="pb-6 text-body">
          Nous vous simplifions la vie et ne vous demandons pas de mot de passe:
          vous recevez simplement un lien de connexion unique et sécurisé par
          email.
        </p>
        <Formik
          validationSchema={yup.object().shape({
            email: yup
              .string()
              .required("Veuillez entrer un email valide")
              .email("Veuillez entrer un email valide"),
          })}
          initialValues={{ email: "" }}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <div className="max-w-xs">
                <Input
                  name="email"
                  error={errors.email}
                  onBlur={handleBlur}
                  touched={touched.email}
                  onChange={handleChange}
                  placeholder="Email"
                  label="Votre adresse email"
                  value={values.email}
                  // onChange={(e) => setEmail(e.target.value)}
                  iconName="airplane"
                />
              </div>
              <div>
                <Button
                  className="sm:w-auto w-full"
                  variant="primary"
                  type="submit"
                >
                  recevoir un lien de connexion
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        {/* <div className="mt-28 hidden sm:block">
          <img src={yoga} alt="yoga" className="absolute" />
        </div> */}
      </div>
    </div>
  );
};

export default Login;
