import request from "superagent";
import { serverUrl } from "../../utils";

export const selectMesCharges = (state) => state.modals.mesCharges;
export const selectMesRevenus = (state) => state.modals.revenus;
export const selectBesoin = (state) => state.modals.besoin;
export const selectCoordonnees = (state) => state.modals.coordonnees;
export const selectSituation = (state) => state.modals.situation;
export const selectPatrimoine = (state) => state.modals.patrimoine;
export const selectProjet = (state) => state.modals.projet;
export const selectParainage = (state) => state.modals.parainage;

export const modalsAPI = {
  getBesoin: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/step/1`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  sendBesoin: ({ jwt, values }) => {
    const objectToSend = {
      ...values,
      projectProgress:
        values.projectType === "CREDIT_REPURCHASE"
          ? ""
          : values.projectProgress,
    };
    return request
      .post(`${serverUrl}/projects/step/1`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(objectToSend);
  },
  sendPatrimoine: ({ jwt, values }) => {
    return request
      .post(`${serverUrl}/projects/step/5`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(values);
  },
  getPatrimoine: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/step/5`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  sendParainage: ({ jwt, values }) => {
    return request
      .post(`${serverUrl}/projects/step/8`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(values);
  },
  getParainage: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/step/8`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  sendSituation: ({ jwt, values }) => {
    return request
      .post(`${serverUrl}/projects/step/3`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(values);
  },
  getSituation: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/step/3`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  sendRevenus: ({ jwt, values }) => {
    return request
      .post(`${serverUrl}/projects/step/4`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(values);
  },
  getRevenus: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/step/4`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  sendMesCharges: ({ jwt, values }) => {
    return request
      .post(`${serverUrl}/projects/step/6`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(values);
  },
  getMesCharges: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/step/6`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  sendCoordonnees: ({ jwt, values }) => {
    return request
      .post(`${serverUrl}/projects/step/2`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(values);
  },
  getProjet: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/step/7`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  sendProjet: ({ jwt, values }) => {
    return request
      .post(`${serverUrl}/projects/step/7`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(values);
  },
  getCoordonnees: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/step/2`)
      .set("Authorization", `Bearer ${jwt}`);
  },
};
