import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import TagManager from 'react-gtm-module'
import { history } from "./history";
// import { SnackbarProvider } from "notistack";
import App from "./App";
// import Snackbar from "./components/Partials/Snackbar/Snackbar";
import * as serviceWorker from "./serviceWorker";
import store from "./app/store";
import "./index.css";
import "moment/locale/fr";

moment.locale("fr");

const tagManagerArgs = {
  gtmId: 'GTM-WD42MXP'
}
TagManager.initialize(tagManagerArgs);


ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      locale="fr"
      libInstance={moment}
    >
      <Router history={history}>
        <App />
      </Router>
      <ToastContainer
        hideProgressBar
        limit={1}
        autoClose={5000}
        position="top-center"
      />
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
