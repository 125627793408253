/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from "react";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Check from "@material-ui/icons/Check";
import request from "superagent";
import { toast } from "react-toastify";
import DeleteIcon from "@material-ui/icons/Delete";
import { cardIcons } from "../Card/CardHelper";
// import redTrashIcon from "../../../images/red-trash.svg";
// import clipIcon from "../../../images/clip.svg";
// import documentWhiteIcon from "../../../images/document-white.svg";
import "./LargeCard.scss";
import { uploadDocument, removeDocument } from "../../Dossier/dossierSlice";
import check from "../../../images/check.svg";
import crossIcon from "../../../images/cross-icon.svg";
import { selectAccessToken } from "../../Authentication/authenticationUtils";
import { selectUploadingDocumentId } from "../../Dossier/dossierUtils";

const idToDocumentType = {
  1: "id_card",
  2: "a",
  3: "b",
  4: "c",
};

const mappedDocument = (document) => {
  let status = "SENT";
  status =
    document.files.filter((doc) => doc.status !== "ACCEPTED").length === 0
      ? "ACCEPTED"
      : status;
  status =
    document.files.filter((doc) => doc.status === "REJECTED").length !== 0
      ? "REJECTED"
      : status;
  if (document.files.length === 0) {
    status = "SENT";
  }
  switch (document.typeId) {
    case 1:
      return {
        documentType: idToDocumentType[document.typeId],
        imageName: "user",
        status,
        arrow: "arrowDown",
        caption: "Passeport ou carte nationale d’identité ou carte de résident",
        ...document,
      };
    case 2:
      return {
        imageName: "building",
        // text: "Avis d’impôt N",
        documentType: idToDocumentType[document.typeId],
        status,
        arrow: "arrowDown",
        // caption: "Caption text here",
        ...document,
      };
    case 3:
      return {
        imageName: "money",
        documentType: idToDocumentType[document.typeId],
        status,
        arrow: "arrowDown",
        caption: "Relevés bancaire des 3 derniers mois",
        ...document,
      };
    default:
      return {
        imageName: "folder",
        text: "Autres documents",
        documentType: idToDocumentType[document.typeId],
        status,
        arrow: "arrowDown",
        // caption: "Caption text here",
        ...document,
      };
  }
};
const LargeCard = ({
  imageName,
  arrow,
  // caption,
  hasPanel,
  // documentType,
  // files,
  text,
  document,
  percentage,
  hasPercentage,
}) => {
  const configObject = document ? mappedDocument(document) : {};
  const count = configObject.files ? configObject.files.length : 0;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const panel = classNames(
    {
      block: open,
      hidden: !open || !hasPanel,
    },
    "pt-2"
  );
  const uploadedFileClass = (status) =>
    classNames(
      // {
      //   "border-red-500": status === "REJECTED",
      //   "border-green-600": status === "ACCEPTED",
      // },
      "border-b-2 pl-4 pt-2 inline-block w-full"
    );
  //   const uploadedFileClass = (status) =>
  // classNames(
  //   {
  //     "border-red-500": status === "REJECTED",
  //     "border-green-600": status === "ACCEPTED",
  //   },
  //   "shadow border-l-2 pl-4 pr-4 py-2 inline-block w-full"
  // );
  const icon = classNames(
    {
      "transform rotate-180": open,
    },
    "transition-all ease-in-out duration-200 cursor-pointer"
  );
  // const dropzoneContainer = classNames(
  //   {
  //     "cursor-default": isDisabled,
  //     "cursor-pointer": !isDisabled,
  //   },
  //   "border border-blue-400 border-dashed mt-4 artemis-color-ajouter"
  // );
  // const deleteFile = (fileName) => () => {
  //   // setFile(undefined);
  //   // dispatch(removeDocument(documentType, fileName));
  //   // setFiles((f) => f.filter((file) => file.name !== fileName));
  // };
  // const handleKeyDownDelete = (fileName) => (ev) => {
  //   if (ev.keyCode === 13) {
  //     // setFile(undefined);
  //     // deleteFile(fileName);
  //   }
  // };

  const handleKeyDownDeleteFromServer = (f) => (ev) => {
    if (ev.keyCode === 13) {
      // console.log(`Deleted from server ${fileName}`);
      dispatch(
        removeDocument({ documentId: f.documentId, name: configObject.name })
      );
    }
  };

  const deleteFromServer = (f) => () => {
    // console.log(`Deleted from server ${JSON.stringify(f)}`);
    dispatch(
      removeDocument({ documentId: f.documentId, name: configObject.name })
    );
  };

  const largeCardCustomClass = classNames(
    {
      "border-green-600": configObject.status === "ACCEPTED",
      "border-blue-500": !configObject.status || configObject.status === "SENT",
      "border-red-500": configObject.status === "REJECTED",
    },
    "bg-white p-4 border-b-2  shadow-lg"
  );
  const options = {
    strokeLinecap: "butt",
    textColor: "#4860bd",
    // textColor: "#FFFFFF",
    pathColor: "#4860bd",
    // pathColor: "#F8F8F8",
    trailColor: "#a6b3e2",
    // trailColor: "#A6B3E2",
  };
  return (
    <div className={largeCardCustomClass}>
      <div
        className="large-card select-none pr-3"
        onClick={() => (hasPanel ? setOpen((o) => !o) : {})}
        role="presentation"
      >
        <span className="card-icon">
          {hasPercentage ? (
            <div style={{ width: "46px" }}>
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles(options)}
              />
            </div>
          ) : (
            <img
              height="32px"
              width="32px"
              src={
                hasPanel
                  ? cardIcons[configObject.imageName]
                  : cardIcons[imageName]
              }
              alt="icon"
            />
          )}
        </span>
        <span className="card-text">
          {hasPanel ? configObject.name : text}{" "}
          <span className="counter">{hasPanel ? `(${count})` : ""}</span>
          {(configObject.status === "ACCEPTED" ||
            configObject.status === "REJECTED") && (
            <img
              style={{ width: "30px", height: "30px" }}
              className="inline ml-2"
              src={configObject.status === "ACCEPTED" ? check : crossIcon}
              alt={configObject.status === "ACCEPTED" ? "checked" : "red error"}
            />
          )}
        </span>
        {(configObject.arrow || arrow) && (
          <span className="arrow-icon">
            <img
              className={icon}
              src={hasPanel ? cardIcons[configObject.arrow] : cardIcons[arrow]}
              alt="arrow-right"
            />
          </span>
        )}
      </div>
      <div className={panel}>
        {/* TODO: REPLACE WITH ACTUAL CAPTIONS WHEN AVAILABLE */}
        {configObject.caption ? (
          <span className="text-caption font-qBold text-gray-400 opacity-50">
            {configObject.caption}
          </span>
        ) : null}
        <div className="mt-4">
          {configObject.files &&
            configObject.files.map((f, index) => (
              <div className="mb-4" key={f.documentId}>
                <div className={uploadedFileClass(f.status)}>
                  <FileInCard
                    key={f.name}
                    handleKeyDownDelete={handleKeyDownDeleteFromServer}
                    deleteFile={deleteFromServer}
                    index={index}
                    startingIndex={0}
                    file={f}
                    configObject={configObject}
                  />
                  {f.status === "REJECTED" && (
                    <span className="text-caption text-red block pb-3">
                      Document refusé « {f.rejectedReason} »
                    </span>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const FileInCard = ({
  handleKeyDownDelete,
  deleteFile,
  index,
  startingIndex,
  file,
  handleKeyDownUpload,
  uploadFile,
  configObject,
  // getRootProps,
  // getInputProps,
}) => {
  const uploadingDocumentId = useSelector(selectUploadingDocumentId);
  const isUploadingCurrentDocument = uploadingDocumentId === file.documentId;
  const dispatch = useDispatch();
  const [f, setF] = useState({});
  const onDrop = useCallback(
    (acceptedFiles) => {
      dispatch(
        uploadDocument({
          documentId: f.documentId,
          file: acceptedFiles[0],
          name: configObject.name,
        })
      );
    },
    [f.documentId, configObject.name, dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpg, image/jpeg, image/png, application/pdf",
    // disabled: isDisabled,
    multiple: false,
    disabled: isUploadingCurrentDocument,
  });
  const jwt = useSelector(selectAccessToken);
  const fileName = file.documentName || file.name;
  const handleDownload = (fileToDownload) => async () => {
    try {
      const res = await request
        .get(fileToDownload.downloadLink)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      // const { headers } = res;
      // const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      const url = URL.createObjectURL(blob);
      window.open(url);
    } catch (e) {
      toast.error(e);
    }
  };
  return (
    <div key={file.name} className="flex items-center justify-between relative">
      {isUploadingCurrentDocument && (
        <div className="absolute right-0 mt-2 mr-1">
          <CircularProgress color="primary" />
        </div>
      )}
      {/* <div className="flex-shrink-0 flex items-center">
        <img
          style={{ height: "20px", width: "15px" }}
          src={clipIcon}
          alt="document"
        />
      </div> */}
      <span className="pr-6 font-qRegular text-body inline break-all">
        {fileName}
      </span>
      <RenderFileStatus
        file={file}
        setF={setF}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        deleteFile={deleteFile}
        handleDownload={handleDownload}
        isUploadingCurrentDocument={isUploadingCurrentDocument}
      />
      {/* {file.status === "REQUIRED" ? (
        <div
          {...getRootProps({
            onClick: () => setF(file),
          })}
        >
          <div
            className="cursor-pointer flex items-center flex-shrink-0"
            role="button"
          >
            <input {...getInputProps()} />
            <CloudUploadIcon style={{ fill: "#4860BD" }} />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <Check />
          <Tooltip title="En attente de validation" placement="top">
            <HourglassEmptyIcon />
          </Tooltip>
          <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={deleteFile(file)}>
            <img src={redTrashIcon} alt="delete document" className="mx-auto" />
          </IconButton>
        </div>
      )} */}
    </div>
  );
};

const RenderFileStatus = ({
  file,
  getRootProps,
  getInputProps,
  setF,
  deleteFile,
  handleDownload,
  isUploadingCurrentDocument,
}) => {
  if (file.status === "REQUIRED") {
    return (
      <IconButton disabled={isUploadingCurrentDocument}>
        <div
          {...getRootProps({
            onClick: () => setF(file),
          })}
        >
          <div
            // tabIndex={index + startingIndex}
            // onKeyDown={() => handleKeyDownUpload(file)}
            // onClick={() => uploadFile(file)}
            className="cursor-pointer flex items-center flex-shrink-0"
            role="button"
          >
            <input {...getInputProps()} />
            <CloudUploadIcon style={{ fill: "#4860BD" }} />
          </div>
        </div>
      </IconButton>
    );
  }
  if (file.status === "SENT") {
    return (
      <div className="flex items-center justify-between">
        <Tooltip title="En attente de validation" placement="top">
          <HourglassEmptyIcon className="m-3 text-gray-700" color="inherit" />
        </Tooltip>
        <IconButton onClick={handleDownload(file)}>
          <VisibilityIcon />
        </IconButton>

        <IconButton onClick={deleteFile(file)}>
          <DeleteIcon className="text-red-600" color="inherit" />
        </IconButton>
      </div>
    );
  }
  if (file.status === "ACCEPTED") {
    return (
      <div className="flex items-center justify-between">
        <Check className="m-3 text-green-600" color="inherit" />
        <IconButton onClick={handleDownload(file)}>
          <VisibilityIcon />
        </IconButton>

        <IconButton onClick={deleteFile(file)}>
          <DeleteIcon className="text-red-600" color="inherit" />
        </IconButton>
      </div>
    );
  }
  if (file.status === "REJECTED") {
    return (
      <div className="flex items-center justify-between">
        <IconButton onClick={handleDownload(file)}>
          <VisibilityIcon />
        </IconButton>

        <IconButton onClick={deleteFile(file)}>
          <DeleteIcon className="text-red-600" color="inherit" />
        </IconButton>
      </div>
    );
  }
};

// file.status === required, sent, rejected/accepted
export default LargeCard;
