import React from "react";
import bfarrowRight from "../../../images/bfarrowRight.svg";
import bfarrowLeft from "../../../images/bfarrowLeft.svg";

const CustomArrow = ({ position, className, onClick }) => {
  const style =
    position === "left"
      ? {
          position: "absolute",
          left: "-32px",
          top: "2px",
        }
      : {
          position: "absolute",
          right: "-32px",
          top: "2px",
        };
  return (
    <button
      type="button"
      className={`bg-blue-500 flex items-center ${className}`}
      onClick={onClick}
      style={{ ...style }}
    >
      <img
        className="py-4 px-1"
        src={position === "left" ? bfarrowLeft : bfarrowRight}
        alt="right"
      />
    </button>
  );
};

export default CustomArrow;
