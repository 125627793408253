import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../../Partials/Modal/Modal";

import {
  getDynamicConstants,
  selectConstants,
  selectDynamicConstants,
} from "../../Partials/constantsSlice";
import {
  sendPatrimoine,
  selectPatrimoine,
  getPatrimoine,
} from "../../Dossier/modalsSlice";
import PatrimoineForm from "./PatrimoineForm";
import BigCardInModal from "../BigCardInModal/BigCardInModal";

export default function Patrimoine({
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
}) {
  const constants = useSelector(selectConstants);
  const dynamicConstants = useSelector(selectDynamicConstants);
  const dispatch = useDispatch();
  const formData = useSelector(selectPatrimoine);
  const [openIndex, setOpenIndex] = useState(-1);
  useEffect(() => {
    dispatch(getDynamicConstants());
    dispatch(getPatrimoine());
  }, [dispatch]);
  const handleSubmit = async (values) => {
    const realValues = values.reduce(
      (pv, cv, index) => [
        ...pv,
        {
          ...formData[index],
          ...cv,
        },
      ],
      []
    );
    await dispatch(sendPatrimoine(realValues));
    onNextClick();
  };
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={[...formData]}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Modal
                title="Mon Patrimoine"
                hideModal={hideModal}
                submitForm={handleSubmit}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                hasNext={hasNext}
                hasPrevious={hasPrevious}
              >
                <PatrimoineForm
                  constants={constants}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  dynamicConstants={dynamicConstants}
                  index={0}
                  coEmpNumber={values.length}
                  includePropertiesDetails
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
                {false && values.length > 1 && (
                  <div className="mt-5 border-t-2 border-white border-solid">
                    <div className="pt-5 font-qSCBold text-modalHeader">
                      Mes co-emprunteurs
                    </div>
                    <div className="flex mt-6 flex-wrap">
                      {values.slice(1).map((co, index) => (
                        <div key={`co-${index}`}>
                          <BigCardInModal
                            onClick={() => setOpenIndex(index + 1)}
                            selected={openIndex === index + 1}
                            text={`Co-emprunteur #${index + 1}`}
                            noIcon
                          />
                        </div>
                      ))}
                    </div>
                    {false && openIndex !== -1 && (
                      <PatrimoineForm
                        constants={constants}
                        handleChange={handleChange}
                        index={openIndex}
                        handleBlur={handleBlur}
                        dynamicConstants={dynamicConstants}
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                  </div>
                )}
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
