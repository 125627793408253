import React, { useState, useEffect } from "react";
import { Formik, FieldArray, Form } from "formik";
import classNames from "classnames";
import "./Revenus.scss";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../../Partials/Slider/ArtemisSlider";
import Modal from "../../Partials/Modal/Modal";
import RevenusForm from "./RevenusForm";
import "slick-carousel/slick/slick.css";
import {
  sendRevenus,
  getRevenus,
  selectMesRevenus,
} from "../../Dossier/modalsSlice";
import { selectConstants } from "../../Partials/constantsSlice";

const Revenus = ({
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
}) => {
  const [open, setOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const dispatch = useDispatch();
  const constants = useSelector(selectConstants);

  const handleOpenIndex = (index) => {
    if (index + 1 === openIndex) {
      setOpen(false);
      setOpenIndex(-1);
    } else {
      setOpen(true);
      setOpenIndex(index + 1);
    }
  };
  const panel = classNames(
    {
      "block border-b-2 border-blue-500": open,
      hidden: !open,
    },
    "pt-8 bg-white p-4 shadow-lg"
  );
  const handleSubmit = async (values) => {
    await dispatch(sendRevenus(values.revenus));
    onNextClick();
  };
  useEffect(() => {
    dispatch(getRevenus());
  }, [dispatch]);
  const formData = useSelector(selectMesRevenus);
  return (
    <Formik enableReinitialize onSubmit={handleSubmit} initialValues={formData}>
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        return (
          <Form>
            <Modal
              title="Mes revenus"
              hideModal={hideModal}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
            >
              <div className="mt-10 relative flex mx-auto">
                <FieldArray
                  name="revenus"
                  render={(arrayHelpers) => (
                    <div className="w-full">
                      <RevenusForm
                        index={0}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors.revenus ? errors.revenus[0] : {}}
                        touched={touched.revenus ? touched.revenus[0] : {}}
                        values={values.revenus ? values.revenus[0] : {}}
                        setFieldValue={setFieldValue}
                        constants={constants}
                      />
                      <div className="sm:mx-0 mx-4">
                        {values.revenus.length > 1 && (
                          <Slider
                            setOpenIndex={setOpenIndex}
                            setOpen={setOpen}
                            hideAddButton
                            handleOpenIndex={handleOpenIndex}
                            arrayObject={{
                              employmentType: "",
                              employerName: "",
                              startDate: null,
                              companyName: "",
                              retirementDate: null,
                              netMonthlyIncome: "",
                              monthlyLandIncome: "",
                              pension: "",
                              dividends: "",
                              taxIncomeN2: "",
                            }}
                            arrayHelpers={arrayHelpers}
                            openIndex={openIndex}
                            arrayForCards={values.revenus.slice(1)}
                            array={values.revenus}
                            label="Ajouter un co-emprunteur"
                            cardText="Co-emprunteur"
                          />
                        )}
                        <div className={panel}>
                          <RevenusForm
                            handleChange={handleChange}
                            handleRemove={() => {
                              setOpen(false);
                              setOpenIndex(-1);
                              arrayHelpers.remove(openIndex);
                            }}
                            handleBlur={handleBlur}
                            index={openIndex}
                            errors={
                              openIndex !== -1
                                ? errors.revenus && errors.revenus[openIndex]
                                : false
                            }
                            touched={
                              openIndex !== -1
                                ? touched.revenus && touched.revenus[openIndex]
                                : false
                            }
                            values={
                              openIndex !== -1
                                ? values.revenus && values.revenus[openIndex]
                                : false
                            }
                            constants={constants}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Revenus;
