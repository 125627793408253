import { createSlice } from "@reduxjs/toolkit";
// import { saveAs } from "file-saver";
import { handleError } from "../../utils";
import { selectAccessToken } from "../Authentication/authenticationSlice";
import { documentsAPI } from "./documentUtils";

export { selectDocuments } from "./documentUtils";

export const slice = createSlice({
  name: "documents",
  initialState: {
    documents: [],
  },
  reducers: {
    getDocumentsSuccess: (state, { payload }) => {
      state.documents = payload;
      // state.documents = [
      //   {
      //     id: 16,
      //     userId: 4,
      //     signingLink:
      //       "https://staging-app.yousign.com/procedure/sign?members=/members/db28b300-b1a5-446c-9d6f-2a2a696ac3c4",
      //     downloadLink:
      //       "/documents/6dd56bb6-b363-4a20-b4bd-687729f266ac/download",
      //     status: "active",
      //     signingDate: null,
      //     documentName: "document2.pdf",
      //   },
      //   {
      //     id: 17,
      //     userId: 4,
      //     signingLink:
      //       "https://staging-app.yousign.com/procedure/sign?members=/members/025a6d91-5380-4e5b-a71e-22036a0e2086",
      //     downloadLink:
      //       "/documents/2cf40ac3-171b-401a-9da7-9f4adb20f33d/download",
      //     status: "active",
      //     signingDate: null,
      //     documentName: "document1.pdf",
      //   },
      //   {
      //     id: 3,
      //     userId: 4,
      //     signingLink: null,
      //     downloadLink:
      //       "/documents/bcdb3ce8-f08f-4cdb-8583-c41eb668f252/download",
      //     status: "active",
      //     signingDate: null,
      //     documentName: "document3.pdf",
      //   },
      // ];
    },
  },
});

const { getDocumentsSuccess } = slice.actions;

export const getDocuments = () => async (dispatch, getState) => {
  try {
    const jwt = selectAccessToken(getState());
    const res = await documentsAPI.getDocuments({ jwt });
    dispatch(getDocumentsSuccess(res.body));
  } catch (err) {
    if (err.response.body) {
      return err.response.body;
    }
    handleError(err);
  }
};

export const getMockDocuments = () => async (dispatch, getState) => {
  try {
    const jwt = selectAccessToken(getState());
    const res = await documentsAPI.getMockDocuments({ jwt });
    dispatch(getDocumentsSuccess(res.body));
  } catch (err) {
    if (err.response.body) {
      return err.response.body;
    }
    handleError(err);
  }
};

export const downloadDocument = (downloadLink) => async (
  dispatch,
  getState
) => {
  try {
    const jwt = selectAccessToken(getState());
    const res = await documentsAPI.downloadDocument({ jwt, downloadLink });
    const u = URL.createObjectURL(res.body);
    window.open(u);
  } catch (err) {
    if (err.response.body) {
      return err.response.body;
    }
    handleError(err);
  }
};

export default slice.reducer;
