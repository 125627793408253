import React from "react";
import "./Background.scss";
import background from "./background.svg";

export default function Background(props) {
  const { children, noImage } = props;
  return (
    <div
      style={{
        backgroundImage: noImage ? `` : `url(${background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100%",
      }}
      className="bg-color"
    >
      {children}
    </div>
  );
}
