import React from "react";
import GuestBesoin from "./GuestBesoin";
import GuestCoordonees from "./GuestCoordonees";
import GuestAdditionalInfo from "./GuestAdditionalInfo";
import CalendlyModal from "./Calendly/CalendlyModal";
import CalendlyComplete from "./Calendly/CalendlyComplete";
import Dispatch from "./Dispatch";

import Taxes from "./Taxes/Taxes";

const GuestModals = ({
  showModal,
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
  isGuest,
}) => {
  if (!showModal) {
    return null;
  }
  switch (showModal) {
    case "taxes":
      return (
        <Taxes
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "additionalInfo":
      return (
        <GuestAdditionalInfo
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "calendlyComplete":
      return (
        <CalendlyComplete
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "calendly":
      return (
        <CalendlyModal
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "besoin":
      return (
        <GuestBesoin
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "coordonees":
      return (
        <GuestCoordonees
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "dispatch":
      return (
        <Dispatch
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    default:
      return null;
  }
};

export default GuestModals;
