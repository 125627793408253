import React from "react";
import Login from "./Login/Login";
// import Register from "./Register/Register";
import BackgroundImage from "../Partials/Background/BackgroundImage";
import "./Authentication.scss";
import background from "../Partials/Background/background.svg";
import yoga from "../../images/yoga.png";
// import man from "../../images/man.png";

const Authentication = () => {
  return (
    <BackgroundImage>
      <div className="relative">
        {/* <div className="row-auth flex flex-wrap full-height absolute inset-0 z-10">
          <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 color-grey flex pr-0 sm:pr-20" />
          <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 color-blue" />
        </div> */}
        <div className="row-auth flex flex-wrap full-height relative z-30 color-grey">
          <div className="w-full flex items-center justify-center pr-0 mobile-grey">
            <Login />
          </div>
          {/* <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 mobile-blue">
            <Register />
          </div> */}
          <div className="justify-center w-full md:flex hidden">
            <div>
              <img src={yoga} alt="yoga" className="" />
            </div>
          </div>
          {/* <div className="mt-28 hidden xl:block">
            <img
              src={yoga}
              alt="yoga"
              className="inline fixed left-0 bottom-0"
            />
            <img
              src={man}
              alt="man on a ladder"
              className="inline fixed bottom-0 right-28"
            />
          </div> */}
        </div>
      </div>
      <div>
        <div
          style={{
            backgroundImage: `url(${background})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            opacity: 0.29,
          }}
          className="z-20 absolute inset-0 sm:block hidden"
        />
      </div>
    </BackgroundImage>
  );
};

export default Authentication;
