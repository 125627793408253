import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleError } from "../../utils";
import { history } from "../../history";
import { getConstants } from "../Partials/constantsSlice";
import {
  selectRefreshToken,
  selectAccessToken,
  selectReturnUrl,
  authenticationAPI,
} from "./authenticationUtils";

export {
  selectRefreshToken,
  selectAccessToken,
  selectUser,
  selectIsLoggedIn,
  selectReturnUrl,
  selectDossierCompletion,
  selectBroker,
} from "./authenticationUtils";

let refreshInterval;
const accessTokenExpirationSeconds = 3600;
// const accessTokenExpirationSeconds = 50;

export const updatePrivacyAccepted = createAsyncThunk(
  "auth/update-privacy",
  async (values, thunkAPI) => {
    const accessToken = selectAccessToken(thunkAPI.getState());
    try {
      const res = await authenticationAPI.updatePrivacy({ accessToken });
      return res.body;
    } catch (e) {
      console.log(e);
      return handleError(e);
    }
  }
);

export const slice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    accessToken: localStorage.getItem("artemisAccessToken"),
    refreshToken: localStorage.getItem("artemisRefreshToken"),
    isLoggedIn: false,
    returnUrl: { pathname: "/accueil" },
    dossierCompletion: {
      myInformation: 0,
      mySupportingDocuments: 0,
      mySigningDocuments: 0,
    },
    broker: {},
  },
  reducers: {
    loginRequest: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
    loginSuccess: (state, { payload }) => {
      state.user = payload.user;
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.isLoggedIn = true;
    },
    logoutSuccess: (state) => {
      state.user = {};
      state.accessToken = null;
      state.refreshToken = null;
      state.isLoggedIn = false;
    },
    refreshTokenSuccess: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.user = payload.user;
      state.isLoggedIn = true;
    },
    setReturnUrl: (state, { payload }) => {
      state.returnUrl = payload;
    },
    setUserData: (state, { payload }) => {
      state.user = payload.user;
    },
    dossierCompletionSuccess: (state, { payload }) => {
      state.dossierCompletion = payload;
    },
    brokerSuccess: (state, { payload }) => {
      state.broker = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePrivacyAccepted.fulfilled, (state, { payload }) => {
      state.user = payload.user;
    });
  },
});

export const {
  setReturnUrl,
  loginSuccess,
  logoutSuccess,
  refreshTokenSuccess,
  setUserData,
  dossierCompletionSuccess,
  brokerSuccess,
  loginRequest,
} = slice.actions;

// export const selectReturnUrl = (state) => state.auth.returnUrl;

export const getBroker = () => async (dispatch, getState) => {
  const accessToken = selectAccessToken(getState());
  try {
    const res = await authenticationAPI.brokerGet({ accessToken });
    dispatch(brokerSuccess(res.body));
  } catch (err) {
    handleError(err);
  }
};

export const getDossierCompletionInformation = () => async (
  dispatch,
  getState
) => {
  const accessToken = selectAccessToken(getState());
  try {
    const res = await await authenticationAPI.getDossierCompletionInformation({
      accessToken,
    });
    dispatch(dossierCompletionSuccess(res.body));
    // history.push("/authentication");
  } catch (err) {
    handleError(err);
  }
};

export const getUserInformation = () => async (dispatch, getState) => {
  const accessToken = selectAccessToken(getState());
  try {
    const res = await authenticationAPI.getUserInformation({
      accessToken,
    });
    dispatch(setUserData(res.body));
  } catch (err) {
    handleError(err);
  }
};

export const register = (registerFormValues) => async () => {
  try {
    await authenticationAPI.register(registerFormValues);
    toast.success(
      `Nous venons de vous envoyer un lien de connexion à ${registerFormValues.email}`
    );
    return true;
  } catch (err) {
    if (err.response && err.response.body) {
      return err.response.body;
    }
    return handleError(err);
  }
};

export const login = (email, token) => async (dispatch) => {
  dispatch(loginRequest());
  localStorage.removeItem("artemisAccessToken");
  localStorage.removeItem("artemisRefreshToken");
  try {
    const res = await authenticationAPI.login({ email, token });
    dispatch(
      loginSuccess({
        user: res.body.accessTokenPayload.user,
        accessToken: res.body.accessToken,
        refreshToken: res.body.refreshToken,
      })
    );
    localStorage.setItem("artemisAccessToken", res.body.accessToken);
    localStorage.setItem("artemisRefreshToken", res.body.refreshToken);
    const { refreshToken } = res.body;
    refreshInterval = setInterval(async () => {
      try {
        const rr = await authenticationAPI.refreshToken({
          refreshToken,
        });
        dispatch(
          refreshTokenSuccess({
            accessToken: rr.body.accessToken,
            user: rr.body.accessTokenPayload.user,
          })
        );
        localStorage.setItem("artemisAccessToken", rr.body.accessToken);
      } catch (e) {
        // console.log(e);
      }
    }, accessTokenExpirationSeconds * 1000);

    history.push("/accueil");
    dispatch(getConstants());
    dispatch(getUserInformation());
  } catch (err) {
    handleError(err);
    history.push("/authentication");
  }
};

export const sendMagicLink = (email) => async (dispatch) => {
  try {
    await authenticationAPI.sendMagicLink({ email });
    toast.success(
      `Nous venons de vous envoyer un lien de connexion à ${email}`
    );
  } catch (err) {
    handleError(err);
  }
};

export const refreshLogin = () => async (dispatch, getState) => {
  const refreshToken = selectRefreshToken(getState());
  // const accessToken = selectAccessToken(getState());
  if (refreshToken) {
    // if (refreshToken && accessToken) {
    history.push("/login-hook");
    try {
      const res = await authenticationAPI.refreshToken({
        refreshToken,
      });
      dispatch(
        refreshTokenSuccess({
          accessToken: res.body.accessToken,
          user: res.body.accessTokenPayload.user,
        })
      );
      localStorage.setItem("artemisAccessToken", res.body.accessToken);
      refreshInterval = setInterval(async () => {
        try {
          const rr = await authenticationAPI.refreshToken({
            refreshToken,
          });
          dispatch(
            refreshTokenSuccess({
              accessToken: rr.body.accessToken,
              user: rr.body.accessTokenPayload.user,
            })
          );
          localStorage.setItem("artemisAccessToken", rr.body.accessToken);
        } catch (e) {
          // console.log(e);
        }
      }, accessTokenExpirationSeconds * 1000);
      const returnUrl = selectReturnUrl(getState());
      history.push(returnUrl.pathname);
      dispatch(getConstants());
      dispatch(getUserInformation());
    } catch (err) {
      handleError(err);
      localStorage.removeItem("artemisAccessToken");
      localStorage.removeItem("artemisRefreshToken");
      history.push("/authentication");
    }
  }
};

export const logout = () => async (dispatch, getState) => {
  const refreshToken = selectRefreshToken(getState());
  const accessToken = selectAccessToken(getState());
  // const user = selectUser(getState());
  try {
    await authenticationAPI.logout({ accessToken, refreshToken });
    localStorage.removeItem("artemisAccessToken");
    localStorage.removeItem("artemisRefreshToken");
    dispatch(logoutSuccess());
    clearInterval(refreshInterval);
    history.push("/authentication");
  } catch (err) {
    handleError(err);
  }
};

export const editUserInformation = (values) => async (dispatch, getState) => {
  const accessToken = selectAccessToken(getState());
  try {
    await authenticationAPI.editUserInformation({ accessToken, values });
    dispatch(getUserInformation());
  } catch (err) {
    handleError(err);
  }
};

export const deleteUser = () => async (dispatch, getState) => {
  const accessToken = selectAccessToken(getState());
  try {
    await authenticationAPI.deleteUser({ accessToken });
    dispatch(logout());
  } catch (err) {
    handleError(err);
  }
};

export default slice.reducer;
