import React, { useEffect } from "react";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { sendBesoin, getBesoin, selectBesoin } from "../../Dossier/modalsSlice";
import Modal from "../../Partials/Modal/Modal";
import Input from "../../Partials/Input/Input";
import RadioSelect from "../../Partials/RadioSelect/RadioSelect";
import "./Besoin.scss";
import { selectConstants } from "../../Partials/constantsSlice";
import { mapProjectTypeToHumanReadable } from "../../../utils";

const Besoin = ({ hideModal, onNextClick, onPreviousClick, hasNext }) => {
  const dispatch = useDispatch();
  const constants = useSelector(selectConstants);
  const formData = useSelector(selectBesoin);
  useEffect(() => {
    dispatch(getBesoin());
  }, [dispatch]);
  const besoinConstants = {
    goodType: "Type de bien",
    goodUsage: "Usage du bien",
    projectProgress: "Avancement du projet",
    projectType: "Nature de votre projet",
  };
  const radioOptions = Object.keys(besoinConstants).map((k) => ({
    heading: besoinConstants[k],
    name: k,
    radios: Object.keys(constants[k]).map((c) => ({
      label: constants[k][c],
      value: c,
      icon: c,
    })),
  }));
  const { projectTypeAmountFields } = constants;
  const handleSubmitForm = async (values) => {
    await dispatch(sendBesoin(values));
    onNextClick();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        projectType: "CREDIT_REPURCHASE",
        goodType: "",
        goodUsage: "",
        projectProgress: "",
        purchaseAmount: "",
        ...formData,
      }}
      validationSchema={yup.object().shape({
        purchaseAmount: yup
          .number()
          // eslint-disable-next-line no-restricted-globals
          .transform((value) => (isNaN(value) ? undefined : value))
          .min(0, "Too low"),
      })}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => {
        let filteredRadioOptions = [];
        if (values.projectType === "CREDIT_REPURCHASE") {
          filteredRadioOptions = radioOptions.filter(
            (opt) => opt.name !== "projectProgress"
          );
        } else {
          filteredRadioOptions = radioOptions;
        }
        return (
          <Form>
            <Modal
              title="Mon Projet"
              hideModal={hideModal}
              submitForm={handleSubmit}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
              hasNext={hasNext}
            >
              <div className="mt-10">
                {filteredRadioOptions.map((opt, index) => (
                  <div
                    key={index}
                    className="border-b-2 border-white border-solid py-4"
                  >
                    <RadioSelect
                      hasDropdown={opt.name === "projectType"}
                      key={index}
                      handleChange={handleChange}
                      onChange={setFieldValue}
                      value={values[opt.name]}
                      name={opt.name}
                      options={opt.radios}
                      heading={opt.heading}
                    />
                  </div>
                ))}
                {projectTypeAmountFields &&
                  projectTypeAmountFields[values.projectType] &&
                  projectTypeAmountFields[values.projectType].map(
                    (fieldName) => (
                      <div
                        className="flex flex-col md:flex-row xs:items-left md:items-center py-4"
                        key={fieldName}
                      >
                        <div className="font-qBold text-headline md:w-2/12 lg:w-3/12">
                          {
                            mapProjectTypeToHumanReadable[values.projectType][
                              fieldName
                            ]
                          }
                        </div>
                        <div className="flex flex-col md:flex-row xs:items-left md:items-center sm:ml-12 md:ml-10 lg:ml-24 mr-0 xs:w-full sm:w-10/12  lg:w-9/12">
                          <Input
                            type="number"
                            noFullWidth
                            value={values[fieldName]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors[fieldName]}
                            touched={touched[fieldName]}
                            name={fieldName}
                            placeholder={
                              mapProjectTypeToHumanReadable[values.projectType][
                                fieldName
                              ]
                            }
                            trailIconName="euro"
                          />
                        </div>
                      </div>
                    )
                  )}
                {/* <div className="flex flex-col md:flex-row xs:items-left md:items-center pt-2 pb-4">
                  <div className="font-qBold text-red text-body">
                    {errors.purchaseAmount || <span>&nbsp;</span>}
                  </div>
                </div> */}
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Besoin;
