import React from "react";
import { FieldArray } from "formik";
import Input from "../../Partials/Input/Input";
import RadioSelect from "../../Partials/BooleanRadioSelect/BooleanRadioSelect";
import redTrashIcon from "../../../images/red-trash.svg";
import Select from "../../Partials/Select/Select";
import plusIcon from "../../../images/plus-white.svg";

export default function PatrimoineForm({
  values,
  handleChange,
  handleBlur,
  constants,
  setFieldValue,
  index,
  errors,
  touched,
  includePropertiesDetails,
  coEmpNumber,
  dynamicConstants,
}) {
  //   const safeErrors = errors || {};
  //   const safeTouched = touched || {};
  //   const values = values
  //     ? {
  //   plannedContribution: "",
  //   propertyOwner: "",
  //   propertiesDetails: [
  //     {
  //       propertyValue: "",
  //       propertyUse: "",
  //       capitalOwedOnThisProperty: "",
  //     },
  //   ],
  //   savingsAvailableAfterTransaction: "",
  //         ...values,
  //       }
  //     : {
  //         plannedContribution: "",
  //         propertyOwner: "",
  //         propertiesDetails: [
  //           {
  //             propertyValue: "",
  //             propertyUse: "",
  //             capitalOwedOnThisProperty: "",
  //           },
  //         ],
  //         savingsAvailableAfterTransaction: "",
  //       };
  return (
    <div>
      <div className="grid-cols-6 grid col-gap-8 mt-10 mr-6">
        <div className="col-span-6 sm:col-span-2">
          <Input
            name={`${index}.plannedContribution`}
            type="number"
            value={values[index].plannedContribution}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Apport envisagé"
            trailIconName="euro"
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            value={values[index].savingsAvailableAfterTransaction}
            label="Épargne disponible après opération"
            name={`${index}.savingsAvailableAfterTransaction`}
            trailIconName="euro"
          />
        </div>
        <div className="col-span-6 flex items-center border-t-2 border-white border-solid mb-6" />
        <div className="sm:col-span-6" />
        {includePropertiesDetails && (
          <div className="col-span-6 sm:col-span-6 mb-8">
            <div className="grid-cols-6 grid col-gap-8 mt-2">
              <RadioSelect
                index={index}
                name={`${index}.propertyOwner`}
                label="Êtes-vous propriétaire d'un bien ?"
                value={values[index].propertyOwner}
                onChange={setFieldValue}
              />
            </div>
          </div>
        )}
        {values[index].propertyOwner === "true" && includePropertiesDetails && (
          <FieldArray
            name={`${index}.propertiesDetails`}
            render={(arrayHelpers) => (
              <>
                {values[index].propertiesDetails.map((property, i) => (
                  <div className="relative col-span-6" key={`${index}-${i}`}>
                    <div className="grid grid-cols-12 col-gap-8">
                      <div className="col-span-12 sm:col-span-3">
                        <Select
                          label="Propriétaire du bien"
                          onChange={handleChange}
                          name={`${index}.propertiesDetails.${i}.assetHolder`}
                          value={property.assetHolder}
                          trailIconName="arrowUpDown"
                          options={
                            dynamicConstants.assetHolders
                              ? dynamicConstants.assetHolders.map((o) => ({
                                  value: o.id,
                                  label: o.label,
                                }))
                              : [
                                  {
                                    label: "Loading",
                                    value: "loading",
                                  },
                                ]
                          }
                        />
                      </div>
                      <div className="col-span-12 sm:col-span-3">
                        <Input
                          label="Valeur du bien"
                          type="number"
                          name={`${index}.propertiesDetails.${i}.propertyValue`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={property.propertyValue}
                          trailIconName="euro"
                        />
                      </div>
                      <div className="col-span-12 sm:col-span-3">
                        <Select
                          label="Usage de ce bien"
                          onChange={handleChange}
                          name={`${index}.propertiesDetails.${i}.propertyUse`}
                          value={property.propertyUse}
                          trailIconName="arrowUpDown"
                          options={Object.keys(constants.propertyUse).map(
                            (key) => ({
                              value: key,
                              label: constants.propertyUse[key],
                            })
                          )}
                        />
                      </div>
                      <div className="col-span-12 sm:col-span-3">
                        <Input
                          label="Capital restant dû sur ce bien"
                          type="number"
                          trailIconName="euro"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={`${index}.propertiesDetails.${i}.capitalOwedOnThisProperty`}
                          value={property.capitalOwedOnThisProperty}
                        />
                      </div>
                      <div
                        role="presentation"
                        className="cursor-pointer absolute select-none"
                        style={{ right: "-22px", top: "30px" }}
                        onClick={() => {
                          arrayHelpers.remove(i);
                        }}
                      >
                        <img src={redTrashIcon} alt="delete" />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="sm:col-span-2 col-span-4">
                  <div
                    role="presentation"
                    className="cursor-pointer select-none py-2 px-1"
                    style={{ display: "flex" }}
                    onClick={() => {
                      if (
                        values[index].propertiesDetails.length <
                        5 * coEmpNumber
                      ) {
                        arrayHelpers.push({
                          propertyValue: "",
                          propertyUse: "MAIN_HOME",
                          capitalOwedOnThisProperty: "",
                          assetHolder: "",
                        });
                      }
                    }}
                  >
                    <div className="flex items-center">
                      <div
                        className="rounded-full bg-blue-500 inline-flex items-center justify-center"
                        style={{ height: "32px", width: "32px" }}
                      >
                        <img src={plusIcon} alt="add co-owner" />
                      </div>
                      <span className="ml-4 font-qSCBold text-blue-500 text-body underline">
                        Ajouter un bien
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          />
        )}
      </div>
    </div>
  );
}
