import React, { useState } from "react";
import { Formik, FieldArray, Form } from "formik";
import classNames from "classnames";
import Slider from "react-slick";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "../Partials/Modal/Modal";
import CoordoneesForm from "../Modals/Coordonees/CoordoneesForm";
import CardInModal from "../Modals/CardInModal/CardInModal";
import CustomArrow from "../Partials/CustomArrow/CustomArrow";
import "slick-carousel/slick/slick.css";
import {
  setGuestCoordonees,
  selectGuestCoordonnees,
  getTaxes,
} from "./guestSlice";
import Consent from "../Partials/Consent/Consent";
import crossIcon from "../../images/cross-icon.svg";
import OnSubmitValidationError from "../Partials/OnSubmitValidationError";

const GuestCoordonees = ({
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
}) => {
  const [open, setOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const dispatch = useDispatch();
  const formData = useSelector(selectGuestCoordonnees);
  const handleOpenIndex = (index) => {
    if (index + 1 === openIndex) {
      setOpen(false);
      setOpenIndex(-1);
    } else {
      setOpen(true);
      setOpenIndex(index + 1);
    }
  };
  const panel = classNames(
    {
      "block border-b-2 border-blue-500": open,
      hidden: !open,
    },
    "pt-8 bg-white px-4 shadow-lg -mt-1"
  );
  // const renderError = (errors, touched) => {
  //   let message = "";
  //   if (touched && errors) {
  //     Object.keys(errors).forEach((text) => {
  //       if (!!errors[text] && !!touched[text]) {
  //         message = errors[text];
  //       }
  //     });
  //   }
  //   return message;
  // };
  const handleSubmit = async (values) => {
    dispatch(setGuestCoordonees(values));
    const res = await dispatch(getTaxes());
    if (!res.error) {
      onNextClick();
    }
  };
  const handleSubmitValidationError = ({ errors }) => {
    if (errors.owners && errors.owners.slice(1).some((x) => !!x)) {
      toast.error(
        "Merci de remplir les informations sur le ou les co-emprunteur(s)"
      );
    }
  };
  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={yup.object().shape({
        owners: yup.array().of(
          yup.object().shape({
            title: yup.string().required("Veuillez remplir ce champ"),
            postalCode: yup
              .string()
              .required("Veuillez remplir ce champ")
              .length(5, "Veuillez entrer un code postal valide"),
            firstName: yup.string().required("Veuillez remplir ce champ"),
            lastName: yup.string().required("Veuillez remplir ce champ"),
            address: yup.string().required("Veuillez remplir ce champ"),
            city: yup.string().required("Veuillez remplir ce champ"),
            email: yup
              .string()
              .required("Veuillez remplir ce champ")
              .email("Veuillez entrer un email valide"),
            phoneNumber: yup
              .string()
              .required("Veuillez remplir ce champ")
              .matches(
                /^(0)([0-9]{9})$/,
                "Veuillez entrez un numéro de téléphone valide"
              ),
            monthlyIncome: yup.number().required("Veuillez remplir ce champ"),
          })
        ),
        privacyPolicy: yup
          .bool()
          .oneOf(
            [true],
            "Merci d’accepter notre Politique de traitement des données personnelles avant de poursuivre"
          ),
      })}
      initialValues={{
        // owners: [
        //   {
        //     firstName: "",
        //     title: "",
        //     lastName: "",
        //     address: "",
        //     city: "",
        //     postalCode: "",
        //     email: "",
        //     phoneNumber: "",
        //     monthlyIncome: "",
        //   },
        // ],
        privacyPolicy: false,
        // noCommercialEmail: false,
        ...formData,
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        // const errorMessage = renderError(errors, touched);
        // console.log(errors);
        // console.log(touched);
        // console.log(values);
        return (
          <Form>
            <OnSubmitValidationError callback={handleSubmitValidationError} />
            <Modal
              title="Mes coordonnées"
              hideModal={hideModal}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              isGuest
            >
              <div className="relative flex mx-auto mt-10">
                <div className="w-full">
                  <FieldArray
                    name="owners"
                    render={(arrayHelpers) => (
                      <>
                        <CoordoneesForm
                          isGuest
                          index={0}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors.owners ? errors.owners[0] : {}}
                          touched={touched.owners ? touched.owners[0] : {}}
                          values={values.owners ? values.owners[0] : {}}
                        />
                        <div className="mx-4 sm:mx-0">
                          <Slider
                            slidesToShow={3}
                            centerMode={false}
                            infinite={false}
                            nextArrow={<CustomArrow position="right" />}
                            prevArrow={<CustomArrow position="left" />}
                            beforeChange={() => {
                              setOpenIndex(-1);
                              setOpen(false);
                            }}
                            responsive={[
                              {
                                breakpoint: 1024,
                                settings: {
                                  slidesToShow: 3,
                                },
                              },
                              {
                                breakpoint: 600,
                                settings: {
                                  slidesToShow: 2,
                                },
                              },
                              {
                                breakpoint: 480,
                                settings: {
                                  slidesToShow: 1,
                                },
                              },
                            ]}
                          >
                            {values.owners.slice(1).map((owner, index) => (
                              <CardInModal
                                key={index}
                                setOpen={() => handleOpenIndex(index)}
                                open={openIndex === index + 1}
                                text={`Co-emprunteur #${index + 1}`}
                              />
                            ))}
                          </Slider>
                        </div>
                        <div className={panel}>
                          <CoordoneesForm
                            showBorder
                            isGuest
                            mainValues={values.owners[0]}
                            showSameAddressButton
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            handleRemove={() => {
                              setOpen(false);
                              setOpenIndex(-1);
                              arrayHelpers.remove(openIndex);
                            }}
                            handleBlur={handleBlur}
                            index={openIndex}
                            errors={
                              openIndex !== -1
                                ? errors.owners && errors.owners[openIndex]
                                : false
                            }
                            touched={
                              openIndex !== -1
                                ? touched.owners && touched.owners[openIndex]
                                : false
                            }
                            values={
                              openIndex !== -1
                                ? values.owners && values.owners[openIndex]
                                : false
                            }
                          />
                        </div>
                      </>
                    )}
                  />
                  <div className="mt-4">
                    <div>
                      <p className="auth-subheading">* Champs obligatoires</p>
                    </div>
                    <span className="text-gray-400 opacity-50 font-qBold text-small">
                      Un emprunt vous engage et doit être remboursé.
                      <br />
                      Vérifiez vos capacités de remboursement avant de vous
                      engager.
                    </span>
                    <Consent
                      name="privacyPolicy"
                      location="guest"
                      checked={values.privacyPolicy}
                      onChange={() =>
                        setFieldValue("privacyPolicy", !values.privacyPolicy)
                      }
                    />
                    <div>
                      {!!errors.privacyPolicy && !!touched.privacyPolicy && (
                        <div className="flex flex-col pt-2 pb-4 md:flex-row xs:items-left md:items-center">
                          <img src={crossIcon} alt="cross-icon" />
                          <div className="text-red-600 font-qBold text-small">
                            {errors.privacyPolicy}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <Consent
                      name="noCommercialEmail"
                      location="signup2"
                      textBefore
                      checked={values.noCommercialEmail}
                      onChange={() =>
                        setFieldValue(
                          "noCommercialEmail",
                          !values.noCommercialEmail
                        )
                      }
                    /> */}
                  </div>
                </div>
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GuestCoordonees;
