import request from "superagent";
import { serverUrl } from "../../utils";

export const selectDocuments = (state) => state.documents.documents;
export const documentsAPI = {
  getDocuments: ({ jwt }) => {
    return request
      .get(`${serverUrl}/documents/broker`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  getMockDocuments: ({ jwt }) => {
    return request
      .get(`${serverUrl}/documents/mock`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  downloadDocument: ({ jwt, downloadLink }) => {
    return request
      .get(`${downloadLink}`)
      .set("Authorization", `Bearer ${jwt}`)
      .responseType("blob");
  },
};
