import React from "react";
import classNames from "classnames";
import { cardIcons } from "../../Partials/Card/CardHelper";
import userIcon from "../../../images/man.svg";
// import userIcon from "../../../images/user.svg";
import "./CardInModal.scss";

export default function CardInModal({
  text,
  children,
  setOpen,
  open,
  cardIcon,
}) {
  const icon = classNames(
    {
      "transform rotate-180": open,
    },
    "transition-all ease-in-out duration-200"
  );
  const largeCardCustomClass = classNames(
    {
      "border-b-2 border-blue-500 shadow-lg": !open,
    },
    "bg-white p-2 cursor-pointer"
  );
  return (
    <div className="sm:mr-3">
      <div className={largeCardCustomClass}>
        <div
          className="large-card select-none"
          onClick={setOpen}
          role="presentation"
        >
          <span className="mr-2">
            <img
              height="32px"
              width="32px"
              src={cardIcon || userIcon}
              alt="icon"
            />
          </span>
          <span className="card-text mr-4 text-blue-500">{text}</span>
          <span className="arrow-icon">
            <img className={icon} src={cardIcons.arrowDown} alt="arrow-right" />
          </span>
        </div>
      </div>
      {children}
    </div>
  );
}
