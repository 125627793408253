import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import * as yup from "yup";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chat from "../Chat/Chat";
import Background from "../Partials/Background/Background";

import {
  selectUser,
  editUserInformation,
  deleteUser,
} from "../Authentication/authenticationSlice";
import Input from "../Partials/Input/Input";
import Button from "../Partials/Button/Button";

const MonCompte = (props) => {
  const [open, setOpen] = React.useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [isEditFormVisible, setEditFormVisible] = useState(false);

  const handleEditUser = () => {
    setEditFormVisible(true);
  };

  const handleSubmit = async (values) => {
    await dispatch(editUserInformation(values));
    setEditFormVisible(false);
  };

  const handleDeleteAccount = async () => {
    await dispatch(deleteUser());
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Background>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Supprimer mon compte</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Toutes vos données seront supprimées, êtes-vous sûr(e) ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="font-qSCBold uppercase shadow-sm py-4 px-4 sm:py-2 sm:px-6 sm:text-sm sm:leading-5 bg-white text-blue-500 border-solid border-2 border-blue-500"
            variant="next"
            onClick={handleClose}
          >
            <span>Non</span>
          </Button>
          <Button
            className="font-qSCBold uppercase text-white shadow-sm 0 py-4 px-4 sm:py-2 sm:px-6 sm:text-sm sm:leading-5 bg-blue-500 border-2 border-solid border-blue-500"
            variant="next"
            onClick={handleDeleteAccount}
          >
            <span>Oui</span>
          </Button>
        </DialogActions>
      </Dialog>
      <div className="z-50 mx-auto max-w-screen-lg h-full p-4 lg:p-0">
        <div className="pt-10">
          <h1 className="font-qSCBold text-header text-red-500">Mon compte</h1>
          <div className="w-full sm:w-1/2 md:w-8/12">
            <p className="font-qSCBold text-modalHeader mt-8">
              Détails de mon compte
            </p>

            {!isEditFormVisible && (
              <div className="w-full sm:w-1/2 mt-8">
                <div className="w-3/4 pb-6">
                  <p className="font-qBold text-accountLabel text-gray-800">
                    Nom
                  </p>
                  <p className="font-qBold text-blue-500 capitalize pt-2">
                    {user.firstName}
                  </p>
                </div>
                <div className="w-3/4 pb-6">
                  <p className="font-qBold text-accountLabel text-gray-800">
                    Prénom
                  </p>
                  <p className="font-qBold text-blue-500 capitalize pt-2">
                    {user.lastName}
                  </p>
                </div>
                <div className="w-3/4 pb-6">
                  <p className="font-qBold text-accountLabel text-gray-800">
                    Email
                  </p>
                  <p className="font-qBold text-blue-500 truncate pt-2">
                    {user.email}
                  </p>
                </div>
                <div className="w-3/4 pb-6">
                  <p className="font-qBold text-accountLabel text-gray-800">
                    Téléphone
                  </p>
                  <p className="font-qBold text-blue-500 capitalize pt-2">
                    {user.phoneNumber}
                  </p>
                </div>
                <div className="">
                  <Button
                    className="font-qSCBold uppercase bg-blue-500 text-white shadow-sm hover:bg-blue-500 py-4 px-4 sm:py-2 sm:px-6 sm:text-sm sm:leading-5 mt-4"
                    variant="next"
                    type="button"
                    onClick={handleEditUser}
                  >
                    <span>ÉDITER</span>
                  </Button>
                </div>
              </div>
            )}
            {isEditFormVisible && (
              <div className="w-full sm:w-1/2 md:w-64 mt-8">
                <Formik
                  onSubmit={handleSubmit}
                  validationSchema={yup.object().shape({
                    firstName: yup
                      .string()
                      .required("Veuillez remplir ce champ"),
                    lastName: yup
                      .string()
                      .required("Veuillez remplir ce champ"),
                    email: yup
                      .string()
                      .required("Veuillez remplir ce champ")
                      .email("Veuillez entrer un email valide"),
                    emailConfirmation: yup
                      .string()
                      .oneOf(
                        [yup.ref("email"), null],
                        "Veuillez entrer une adresse email identique à celle du champ Email"
                      )
                      .required("Veuillez remplir ce champ")
                      .email("Veuillez entrer un email valide"),
                    phoneNumber: yup
                      .string()
                      .required("Veuillez remplir ce champ")
                      .matches(
                        /^(0)([0-9]{9})$/,
                        "Veuillez entrez un numéro de téléphone valide"
                      ),
                  })}
                  initialValues={{
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    emailConfirmation: user.email,
                    phoneNumber: user.phoneNumber,
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur }) => {
                    return (
                      <Form>
                        <Input
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.firstName}
                          touched={touched.firstName}
                          name="firstName"
                          label="Nom"
                          placeholder="Nom"
                          leadingIconName="user"
                        />
                        <Input
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.lastName}
                          touched={touched.lastName}
                          name="lastName"
                          label="Prénom"
                          placeholder="Prénom"
                          leadingIconName="user"
                        />
                        <Input
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.email}
                          touched={touched.email}
                          name="email"
                          label="Email"
                          placeholder="Email"
                          leadingIconName="airplane"
                        />
                        <Input
                          name="emailConfirmation"
                          label="Confirmez votre email"
                          placeholder="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.emailConfirmation}
                          leadingIconName="airplane"
                          error={errors.emailConfirmation}
                          touched={touched.emailConfirmation}
                        />
                        <Input
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.phoneNumber}
                          touched={touched.phoneNumber}
                          name="phoneNumber"
                          label="Téléphone"
                          placeholder="Téléphone"
                          leadingIconName="mobile"
                        />
                        <Button
                          className="font-qSCBold uppercase bg-blue-500 text-white shadow-sm hover:bg-blue-500 py-4 px-4 sm:py-2 sm:px-6 sm:text-sm sm:leading-5 mt-4"
                          variant="next"
                          type="submit"
                        >
                          <span>SAUVEGARDER</span>
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            )}
          </div>
          <div className="w-full sm:w-1/2 md:w-8/12 pt-8">
            {/* <p className="font-qSCBold text-modalHeader mt-8 mb-8">
              Mes données personnelles
            </p>
            <p className="font-qSCBold text-blue-500 text-headline">
              Mention 1
            </p>
            <p className="mt-8 mb-8 font-qRegular text-body">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              mollis congue quam quis tincidunt. Ut vitae tempor risus. Interdum
              et malesuada fames ac ante ipsum primis in faucibus. Cras blandit
              augue ut auctor aliquet. Phasellus pellentesque, risus vitae
              mattis feugiat, tellus mauris commodo nibh, vehicula pharetra
              tortor neque quis nisl
            </p>
            <p className="font-qSCBold text-blue-500 text-headline">
              Mention 2
            </p>
            <p className="mt-8 mb-8 font-qRegular text-body">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              mollis congue quam quis tincidunt. Ut vitae tempor risus. Interdum
              et malesuada fames ac ante ipsum primis in faucibus. Cras blandit
              augue ut auctor aliquet. Phasellus pellentesque, risus vitae
              mattis feugiat, tellus mauris commodo nibh, vehicula pharetra
              tortor neque quis nisl
            </p> */}
          </div>
          <div className="w-full sm:w-1/2 md:w-8/12">
            <div className="flex flex-row justify-between">
              <a
                href="https://www.artemiscourtage.com/conditions-generales-dutilisation-cgu/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-qSCBold text-blue-500 text-accountLink underline"
              >
                Voir les CGU
              </a>
              <a
                href="https://www.artemiscourtage.com/mentions-legales/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-qSCBold text-blue-500 text-accountLink underline"
              >
                Voir les mentions légales
              </a>
              <a
                href="mailto:confidentialite@artemisambitions.com"
                target="_blank"
                rel="noopener noreferrer"
                className="font-qSCBold text-blue-500 text-accountLink underline"
              >
                Contact des données personnelles
              </a>
            </div>
            <div className="block w-full mt-2">
              <a
                href="https://www.artemiscourtage.com/confidentialite/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-qSCBold text-blue-500 text-accountLink underline"
              >
                Voir la POLITIQUE DE TRAITEMENT DES DONNÉES PERSONNELLES
              </a>
            </div>
            <div className="block w-full mt-2">
              <a
                href="https://www.artemiscourtage.com/confidentialite/#charte-cookies"
                target="_blank"
                rel="noopener noreferrer"
                className="font-qSCBold text-blue-500 text-accountLink underline"
              >
                Cookies
              </a>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-8/12 mt-12">
            <div
              className="flex flex-row items-left cursor-pointer"
              role="presentation"
              onClick={handleOpen}
            >
              <DeleteIcon className="icon-red" size="small" />
              <div className="font-qBold text-red-500">
                Demander la suppression de mon compte
              </div>
            </div>
          </div>
        </div>
        <Chat />
      </div>
    </Background>
  );
};

export default MonCompte;
