import userIcon from "../../../images/user.svg";
import homeIcon from "../../../images/home.svg";
import pigIcon from "../../../images/pig.svg";
import envelopeIcon from "../../../images/envelope.svg";
import moneyIcon from "../../../images/money.svg";
import buildingIcon from "../../../images/building.svg";
import barIcon from "../../../images/bar-chart.svg";
import pieIcon from "../../../images/pie-chart.svg";
import folderIcon from "../../../images/folder.svg";
import fileIcon from "../../../images/file.svg";
import clipIcon from "../../../images/clip.svg";
import manIcon from "../../../images/man.svg";
import arrowRightIcon from "../../../images/arrow-right.svg";
import arrowRightWhiteIcon from "../../../images/arrow-right-white.svg";
import arrowDownIcon from "../../../images/arrow-down.svg";
import crayonIcon from "../../../images/crayon.svg";
import chevronUpIcon from "../../../images/chevron-up.svg";
import chevronRightIcon from "../../../images/chevron-right.svg";
import arrowLeftOrangeIcon from "../../../images/arrow-left-orange.svg";
import arrowLeftBlueIcon from "../../../images/arrow-left-blue.svg";

export const cardIcons = {
  user: userIcon,
  home: homeIcon,
  envelope: envelopeIcon,
  pig: pigIcon,
  money: moneyIcon,
  building: buildingIcon,
  bar: barIcon,
  pie: pieIcon,
  folder: folderIcon,
  file: fileIcon,
  arrowDown: arrowDownIcon,
  man: manIcon,
  clip: clipIcon,
  arrowRight: arrowRightIcon,
  crayon: crayonIcon,
  chevronUp: chevronUpIcon,
  chevronRight: chevronRightIcon,
  arrowRightWhite: arrowRightWhiteIcon,
  arrowLeftOrange: arrowLeftOrangeIcon,
  arrowLeftBlue: arrowLeftBlueIcon,
};
