import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
// import Button from "../../Partials/Button/Button";
// import folder from "../../../images/folder-white.svg";
import { createAccount } from "../guestSlice";

export default function CalendlyComplete() {
  // const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createAccount({ hideToast: true }));
  }, [dispatch]);
  // const navigateTo = async () => {
  //   // await dispatch(createAccount());
  //   history.push("/authentication");
  // };
  return (
    <div className="mx-auto max-w-screen-lg pt-16">
      <div className="max-w-screen-sm mx-auto">
        <Paper>
          <div className="p-8">
            <div className="font-qSCBold text-header text-red-500 text-center">
              C’est confirmé !
            </div>
            <div className="font-qRegular text-body pt-12 max-w-lg mx-auto text-center text-gray-400">
              Vous recevrez un e-mail de confirmation et une notification de
              rappel.
            </div>
            {/* <div className="mx-auto max-w-xs mt-14">
              <Button variant="primary" fullWidth withIcon onClick={navigateTo}>
                Créer mon dossier |
                <img
                  style={{ width: "17px", height: "18px" }}
                  className="ml-1"
                  src={folder}
                  alt="button"
                />
              </Button>
            </div> */}
          </div>
        </Paper>
      </div>
    </div>
  );
}
