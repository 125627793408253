import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import Modal from "../../Partials/Modal/Modal";
import Select from "../../Partials/Select/Select";
import { selectConstants } from "../../Partials/constantsSlice";
import Input from "../../Partials/Input/Input";
import {
  sendParainage,
  getParainage,
  selectParainage,
} from "../../Dossier/modalsSlice";

export default function Parrainage({
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
}) {
  const constants = useSelector(selectConstants);
  const { heardAboutUs } = constants;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getParainage());
  }, [dispatch]);
  const formData = useSelector(selectParainage);
  const handleSubmit = async (values) => {
    await dispatch(sendParainage(values));
    hideModal(false);
  };
  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{
          heardAboutUs: "",
          ALREADY_A_CLIENT: {
            artemisAdvisor: "",
          },
          ARTEMISCOURTAGE_COM: {},
          BROKER_CONNECTION: { link: "" },
          DEVISPROX: {},
          FORMER_CLIENT: {
            lastName: "",
            firstName: "",
            artemisAdvisor: "",
          },
          MASS_MEDIA: { name: "" },
          NOTARY_RECOMMENDATION: {
            studyName: "",
            master: "",
          },
          REAL_ESTATE_PROFESSIONAL_RECOMMENDATION: {
            name: "",
            company: "",
          },
          WORD_OF_MOUTH: {
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            postalCode: "",
            email: "",
            phoneNumber: "",
          },
          WORK_COUNCIL: { name: "" },
          ...formData,
        }}
        validationSchema={yup.object().shape({
          WORD_OF_MOUTH: yup.object().shape({
            firstName: yup.string(),
            // .required("Veuillez remplir ce champ")
            lastName: yup.string(),
            // .required("Veuillez remplir ce champ")
            email: yup
              .string()
              // .required("Veuillez remplir ce champ")
              .email("Veuillez entrer un email valide"),
            postalCode: yup
              .string()
              // .required("Veuillez remplir ce champ")
              .length(5, "Veuillez entrer un code postal valide"),
            phoneNumber: yup
              .string()
              // .required("Veuillez remplir ce champ")
              .matches(
                /^(0)([0-9]{9})$/,
                "Veuillez entrez un numéro de téléphone valide"
              ),
          }),
        })}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Modal
                title="Parrainage"
                hideModal={hideModal}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                hasNext={hasNext}
                hasPrevious={hasPrevious}
                submitForm={handleSubmit}
              >
                <div className="grid grid-cols-6 col-gap-8 row-gap-2 mt-10">
                  <div className="col-span-6 sm:col-span-3">
                    <Select
                      label="Comment avez-vous connu Artémis courtage ?"
                      leadingIconName="user"
                      trailIconName="arrowUpDown"
                      name="heardAboutUs"
                      value={values.heardAboutUs}
                      onChange={handleChange}
                      options={Object.keys(heardAboutUs).map((key) => ({
                        label: heardAboutUs[key],
                        value: key,
                      }))}
                    />
                  </div>
                  <div className="col-span-3" />
                  <SwitchForm
                    option={values.heardAboutUs}
                    values={values}
                    handleChange={handleChange}
                    errors={errors ? errors[values.heardAboutUs] : {}}
                    touched={touched ? touched[values.heardAboutUs] : {}}
                    handleBlur={handleBlur}
                  />
                </div>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
/*
ALREADY_A_CLIENT: "Vous êtes déjà client(s) Artémis courtage"
ARTEMISCOURTAGE_COM: "Notre site internet Artemiscourtage.com, demande en ligne"
BROKER_CONNECTION: "Lien direct avec le conseiller d’Artémis courtage"
DEVISPROX: "Notre partenaire Devisprox"
FORMER_CLIENT: "Un de nos anciens clients"
MASS_MEDIA: "Les médias (Facebook, Twitter, Linkedin, Presse, TV, article, publicité…)"
NOTARY_RECOMMENDATION: "La recommandation d’un notaire"
REAL_ESTATE_PROFESSIONAL_RECOMMENDATION: "La recommandation d’un professionnel de l’immobilier"
WORD_OF_MOUTH: "Bouche à oreille"
WORK_COUNCIL: "Votre comité d’entreprise ou association"
*/

export function SwitchForm({
  option,
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
}) {
  const definedErrors = errors || {};
  const definedTouched = touched || {};
  switch (option) {
    case "REAL_ESTATE_PROFESSIONAL_RECOMMENDATION":
      return (
        <>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Son nom"
              placeholder="Son nom"
              name={`${option}.name`}
              key={`${option}.name`}
              value={values[option].name}
              onChange={handleChange}
              error={definedErrors.name}
              touched={definedTouched.name}
              onBlur={handleBlur}
              leadingIconName="user"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Son enseigne"
              placeholder="Son enseigne"
              name={`${option}.company`}
              key={`${option}.company`}
              value={values[option].company}
              onChange={handleChange}
              error={definedErrors.company}
              touched={definedTouched.company}
              onBlur={handleBlur}
              leadingIconName="user"
            />
          </div>
        </>
      );
    case "NOTARY_RECOMMENDATION":
      return (
        <>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Nom de l'étude"
              placeholder="Nom de l'étude"
              name={`${option}.studyName`}
              key={`${option}.studyName`}
              value={values[option].studyName}
              onChange={handleChange}
              error={definedErrors.studyName}
              touched={definedTouched.studyName}
              onBlur={handleBlur}
              leadingIconName="user"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Maître"
              placeholder="Maître"
              name={`${option}.master`}
              key={`${option}.master`}
              value={values[option].master}
              onChange={handleChange}
              error={definedErrors.master}
              touched={definedTouched.master}
              onBlur={handleBlur}
              leadingIconName="user"
            />
          </div>
        </>
      );
    case "FORMER_CLIENT":
      return (
        <>
          <div className="col-span-2 sm:col-span-2">
            <Input
              label="Nom"
              placeholder="Nom"
              name={`${option}.lastName`}
              key={`${option}.lastName`}
              value={values[option].lastName}
              onChange={handleChange}
              error={definedErrors.lastName}
              touched={definedTouched.lastName}
              onBlur={handleBlur}
              leadingIconName="user"
            />
          </div>
          <div className="col-span-2 sm:col-span-2">
            <Input
              label="Prénom"
              placeholder="Prénom"
              name={`${option}.firstName`}
              key={`${option}.firstName`}
              value={values[option].firstName}
              onChange={handleChange}
              error={definedErrors.firstName}
              touched={definedTouched.firstName}
              onBlur={handleBlur}
              leadingIconName="user"
            />
          </div>
          <div className="hidden sm:block sm:col-span-2"> </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Conseiller Artémis courtage recommandé"
              placeholder="Conseiller Artémis courtage recommandé"
              name={`${option}.artemisAdvisor`}
              key={`${option}.artemisAdvisor`}
              value={values[option].artemisAdvisor}
              onChange={handleChange}
              error={definedErrors.artemisAdvisor}
              touched={definedTouched.artemisAdvisor}
              onBlur={handleBlur}
              leadingIconName="user"
            />
          </div>
        </>
      );
    case "WORD_OF_MOUTH":
      return (
        <>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Nom"
              placeholder="Nom"
              leadingIconName="user"
              name={`${option}.lastName`}
              key={`${option}.lastName`}
              value={values[option].lastName}
              onChange={handleChange}
              error={definedErrors.lastName}
              touched={definedTouched.lastName}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Prénom"
              placeholder="Prénom"
              leadingIconName="user"
              name={`${option}.firstName`}
              key={`${option}.firstName`}
              value={values[option].firstName}
              onChange={handleChange}
              error={definedErrors.firstName}
              touched={definedTouched.firstName}
              onBlur={handleBlur}
            />
          </div>
          <div className="hidden sm:block sm:col-span-2" />
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Adresse"
              placeholder="Adresse"
              leadingIconName="home"
              name={`${option}.address`}
              key={`${option}.address`}
              value={values[option].address}
              onChange={handleChange}
              error={definedErrors.address}
              touched={definedTouched.address}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Ville"
              placeholder="Ville"
              leadingIconName="home"
              name={`${option}.city`}
              key={`${option}.city`}
              value={values[option].city}
              onChange={handleChange}
              error={definedErrors.city}
              touched={definedTouched.city}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Code postal"
              placeholder="Code postal"
              leadingIconName="home"
              name={`${option}.postalCode`}
              key={`${option}.postalCode`}
              value={values[option].postalCode}
              onChange={handleChange}
              error={definedErrors.postalCode}
              touched={definedTouched.postalCode}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Email"
              placeholder="Email"
              leadingIconName="airplane"
              name={`${option}.email`}
              key={`${option}.email`}
              value={values[option].email}
              onChange={handleChange}
              error={definedErrors.email}
              touched={definedTouched.email}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              type="phoneNumber"
              label="Téléphone"
              placeholder="Téléphone"
              leadingIconName="mobile"
              name={`${option}.phoneNumber`}
              key={`${option}.phoneNumber`}
              value={values[option].phoneNumber}
              onChange={handleChange}
              error={definedErrors.phoneNumber}
              touched={definedTouched.phoneNumber}
              onBlur={handleBlur}
            />
          </div>
        </>
      );
    case "ALREADY_A_CLIENT":
      return (
        <>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Conseiller Artémis courtage qui s’est occupé de vous"
              placeholder="Conseiller Artémis courtage qui s’est occupé de vous"
              name={`${option}.artemisAdvisor`}
              key={`${option}.artemisAdvisor`}
              value={values[option].artemisAdvisor}
              onChange={handleChange}
              error={definedErrors.artemisAdvisor}
              touched={definedTouched.artemisAdvisor}
              onBlur={handleBlur}
              leadingIconName="user"
            />
          </div>
        </>
      );
    case "BROKER_CONNECTION":
      return (
        <>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Merci de nous préciser votre lien"
              placeholder="Merci de nous préciser votre lien"
              name={`${option}.link`}
              key={`${option}.link`}
              value={values[option].link}
              onChange={handleChange}
              error={definedErrors.link}
              touched={definedTouched.link}
              onBlur={handleBlur}
              leadingIconName="user"
            />
          </div>
        </>
      );
    case "MASS_MEDIA":
      return (
        <>
          <div className="col-span-6 sm:col-span-3">
            <Select
              label="Merci de nous préciser le nom du média"
              value={values[option].name}
              name={`${option}.name`}
              onChange={handleChange}
              trailIconName="arrowUpDown"
              options={[
                { value: "Facebook", label: "Facebook" },
                { value: "Linkedin", label: "Linkedin" },
                { value: "Twitter", label: "Twitter" },
                { value: "Instagram", label: "Instagram" },
                { value: "Google", label: "Google" },
                { value: "Presse", label: "Presse" },
                { value: "TV", label: "TV" },
                { value: "Radio", label: "Radio" },
                { value: "Pub métro Paris", label: "Pub métro Paris" },
              ]}
            />
          </div>
        </>
      );
    case "WORK_COUNCIL":
      return (
        <>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Merci de nous préciser son nom"
              placeholder="Merci de nous préciser son nom"
              name={`${option}.name`}
              key={`${option}.name`}
              value={values[option].name}
              onChange={handleChange}
              error={definedErrors.name}
              touched={definedTouched.name}
              onBlur={handleBlur}
              leadingIconName="user"
            />
          </div>
        </>
      );
    case "ARTEMISCOURTAGE_COM":
    case "DEVISPROX":
    default:
      return null;
  }
}
