import React from "react";
import { Box } from "@material-ui/core";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useDispatch } from "react-redux";
// import classNames from "classnames";
import { toast } from "react-toastify";
import bigMan from "../../../images/man@2x.png";
import retour from "../../../images/chevron-retour.svg";
import Background from "../../Partials/Background/Background";
import BackgroundImage from "../../Partials/Background/BackgroundImage";
import "./RegisterForm.scss";
import Input from "../../Partials/Input/Input";
import Button from "../../Partials/Button/Button";
import { register } from "../authenticationSlice";
import Consent from "../../Partials/Consent/Consent";
import * as utils from "../../../utils";

export default function RegisterForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setErrors, setFieldError }) => {
    const res = await dispatch(register(values));
    if (res.errors.includes("INVALID_POSTAL_CODE")) {
      setFieldError("postalCode", "Veuillez entrer un code postal valide");
    } else if (res.errors.includes("PRIVACY_POLICY_REQUIRED")) {
      toast.error(
        "Merci d’accepter notre Politique de traitement des données personnelles avant de poursuivre"
      );
    } else {
      toast.error(utils.errors[res.errors[0]]);
    }
  };

  return (
    <Background>
      <BackgroundImage>
        <div className="h-full">
          <div className="relative flex mx-auto my-0 max-w-screen-lg">
            {/* {showSuccessAlert && (
            <div className="success-register-alert">
              <p>Nous venons de vous envoyer un lien de connexion à {email}</p>
            </div>
          )} */}
            <div className="w-full xs:w-full lg:w-7/12 px-4 lg:px-0">
              <div className="history">
                <Button
                  variant="transparentButton"
                  onClick={() => {
                    history.push("/authentication");
                  }}
                >
                  <img
                    src={retour}
                    style={{ marginRight: "1rem" }}
                    alt="left-arrow"
                  />
                  Retour
                </Button>
              </div>
              <div className="auth-form">
                <h1 className="auth-heading">
                  Décrocher le meilleur prêt immobilier n’a jamais été aussi
                  simple !
                </h1>
                <p className="auth-subheading">
                  L’équipe Artémis Courtage vous guidera pas à pas dans la
                  définition de votre projet immobilier.
                </p>
                <Formik
                  validationSchema={yup.object().shape({
                    firstName: yup
                      .string()
                      .required("Veuillez remplir ce champ"),
                    lastName: yup
                      .string()
                      .required("Veuillez remplir ce champ"),
                    email: yup
                      .string()
                      .required("Veuillez remplir ce champ")
                      .email("Veuillez entrer un email valide"),
                    emailConfirmation: yup
                      .string()
                      .oneOf(
                        [yup.ref("email"), null],
                        "Veuillez entrer une adresse email identique à celle du champ Email"
                      )
                      .required("Veuillez remplir ce champ")
                      .email("Veuillez entrer un email valide"),
                    postalCode: yup
                      .string()
                      .required("Veuillez remplir ce champ")
                      .length(5, "Veuillez entrer un code postal valide"),
                    phoneNumber: yup
                      .string()
                      .required("Veuillez remplir ce champ")
                      .matches(
                        /^(0)([0-9]{9})$/,
                        "Veuillez entrez un numéro de téléphone valide"
                      ),
                  })}
                  initialValues={{
                    lastName: "",
                    firstName: "",
                    email: "",
                    emailConfirmation: "",
                    postalCode: "",
                    phoneNumber: "",
                    privacyPolicy: false,
                    noCommercialEmail: false,
                  }}
                  onSubmit={handleSubmit}
                >
                  {({
                    handleChange,
                    values,
                    errors,
                    touched,
                    handleBlur,
                    setFieldValue,
                  }) => (
                    <Form>
                      <div className="grid grid-cols-1 row-gap-5 col-gap-6 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <Input
                            name="lastName"
                            label="Nom"
                            placeholder="Nom"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            leadingIconName="user"
                            error={errors.lastName}
                            touched={!!touched.lastName}
                            isRequired
                          />
                        </div>
                        <div className="sm:col-span-3">
                          <Input
                            name="firstName"
                            label="Prénom"
                            placeholder="Prénom"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            leadingIconName="user"
                            error={errors.firstName}
                            touched={!!touched.firstName}
                            isRequired
                          />
                        </div>
                        <div className="sm:col-span-3">
                          <Input
                            name="postalCode"
                            label="Code postal"
                            placeholder="Code postal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.postalCode}
                            leadingIconName="home"
                            error={errors.postalCode}
                            touched={!!touched.postalCode}
                            isRequired
                          />
                        </div>
                        <div className="sm:col-span-3">
                          <Input
                            name="phoneNumber"
                            label="Téléphone"
                            placeholder="Téléphone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            leadingIconName="mobile"
                            error={errors.phoneNumber}
                            touched={!!touched.phoneNumber}
                            isRequired
                          />
                        </div>
                        <div className="sm:col-span-3">
                          <Input
                            name="email"
                            label="Email"
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            leadingIconName="airplane"
                            error={errors.email}
                            touched={!!touched.email}
                            isRequired
                          />
                        </div>
                        <div className="sm:col-span-3">
                          <Input
                            name="emailConfirmation"
                            label="Confirmez votre email"
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.emailConfirmation}
                            leadingIconName="airplane"
                            error={errors.emailConfirmation}
                            touched={!!touched.emailConfirmation}
                            isRequired
                          />
                        </div>
                      </div>
                      <div>
                        <p className="auth-subheading">* Champs obligatoires</p>
                      </div>
                      <div>
                        <span className="font-qBold text-gray-400 text-small opacity-50 leading-none">
                          Un emprunt vous engage et doit être remboursé.
                          <br />
                          Vérifiez vos capacités de remboursement avant de vous
                          engager.
                        </span>
                      </div>
                      <Consent
                        name="privacyPolicy"
                        location="signup"
                        checked={values.privacyPolicy}
                        onChange={() =>
                          setFieldValue("privacyPolicy", !values.privacyPolicy)
                        }
                      />
                      <Consent
                        name="noCommercialEmail"
                        location="signup2"
                        textBefore
                        checked={values.noCommercialEmail}
                        onChange={() =>
                          setFieldValue(
                            "noCommercialEmail",
                            !values.noCommercialEmail
                          )
                        }
                      />
                      <div className="mt-8 h-48">
                        <Button variant="primary" type="submit">
                          Accéder à mon espace
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <Box height="100px" />
            </div>
            <div className="w-0 xs:w-full lg:w-5/12 auth-image">
              <img src={bigMan} alt="man-ladder-big" />
            </div>
          </div>
        </div>
      </BackgroundImage>
    </Background>
  );
}
