import flatten from "flat";
import { useFormikContext } from "formik";
import { useEffect } from "react";

const FormikPatchTouched = () => {
  const {
    errors,
    setFieldTouched,
    isSubmitting,
    isValidating,
  } = useFormikContext();
  useEffect(() => {
    if (isSubmitting && !isValidating) {
      Object.keys(flatten(errors)).forEach((path) => {
        setFieldTouched(path, true, false);
      });
    }
  }, [errors, isSubmitting, isValidating, setFieldTouched]);
  return null;
};

export default FormikPatchTouched;
