import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@material-ui/core";
import HeaderNew from "./components/Header/HeaderNew";
import Authentication from "./components/Authentication/Authentication";
import RegisterForm from "./components/Authentication/Register/RegisterForm";
import Dashboard from "./components/Dashboard/Dashboard";
import Dossier from "./components/Dossier/Dossier";
import Documents from "./components/Documents/Documents";
import GuestHome from "./components/Guest/GuestHome";
import MonCompte from "./components/MonCompte/MonCompte";
import DocumentsGeneration from "./components/Partials/DocumentsGeneration/DocumentsGeneration";
import "./App.scss";
import "./tailwind.generated.css";
import "react-toastify/dist/ReactToastify.css";
import LoginHook from "./components/Authentication/LoginHook/LoginHook";
import {
  login,
  refreshLogin,
  selectIsLoggedIn,
  selectUser,
  updatePrivacyAccepted,
} from "./components/Authentication/authenticationSlice";
import AuthRoute from "./components/Authentication/AuthRoute/AuthRoute";
import CalendlyModal from "./components/Guest/Calendly/CalendlyModal";
import Button from "./components/Partials/Button/Button";
import { setPartnership } from "./components/Guest/guestSlice";

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  if (location.search) {
    const sp = new URLSearchParams(location.search);
    const email = sp.get("e");
    const token = sp.get("t");
    const partnership = sp.get("partnership");
    if (email && token) {
      history.push("/login-hook");
      dispatch(login(email, token));
    }
    if (partnership) {
      dispatch(setPartnership(partnership));
    }
  }

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const profile = useSelector(selectUser);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn && profile.privacyTermsAccepted === 0) {
      setIsPrivacyModalOpen(true);
    }
    if (isLoggedIn && profile.privacyTermsAccepted === 1) {
      setIsPrivacyModalOpen(false);
    }
  }, [dispatch, isLoggedIn, profile.privacyTermsAccepted]);
  useEffect(() => {
    async function checkLogin() {
      await dispatch(refreshLogin());
    }
    if (!isLoggedIn) {
      checkLogin();
    }
  }, [dispatch, isLoggedIn, history]);
  return (
    <div className="main-container">
      <Modal open={isPrivacyModalOpen}>
        <div className="flex items-center justify-center w-screen h-screen">
          <div className="w-full max-w-screen-sm px-8 py-4 text-center bg-white rounded-lg shadow-lg">
            <div className="mb-4 text-2xl font-qBold">
              Le respect de votre vie privée est notre priorité
            </div>
            <div className="mb-4 text-lg font-qRegular">
              En cochant cette case, vous acceptez qu’Artémis Courtage collecte
              vos données pour gérer et suivre l’évolution de vos demandes de
              crédit immobilier en créant votre dossier en ligne.
            </div>
            <div className="flex flex-col w-40 mx-auto">
              <Button
                variant="primary"
                onClick={() => dispatch(updatePrivacyAccepted())}
              >
                J&apos;accepte
              </Button>
              <Button
                noPadding
                variant="transparentButton"
                className="justify-center mt-6 text-xs text-gray-700"
                onClick={() => {
                  window.open(
                    "https://www.artemiscourtage.com/confidentialite/",
                    "_blank",
                    "noopener norefferer"
                  );
                }}
              >
                En savoir plus
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <HeaderNew />
      <Switch>
        <AuthRoute path="/accueil">
          <Dashboard />
        </AuthRoute>
        <AuthRoute path="/calendly">
          <CalendlyModal />
        </AuthRoute>
        <Route exact path="/login-hook">
          <LoginHook />
        </Route>
        <Route path="/authentication">
          <Authentication />
        </Route>
        <Route exact path="/">
          <Authentication />
        </Route>
        <Route path="/register">
          <RegisterForm />
        </Route>
        <Route path="/login">
          <LoginHook />
        </Route>
        <AuthRoute path="/mon-dossier">
          <Dossier />
        </AuthRoute>
        <AuthRoute path="/mes-documents">
          <Documents />
        </AuthRoute>
        <AuthRoute path="/generate-documents">
          <DocumentsGeneration />
        </AuthRoute>
        <AuthRoute path="/mon-compte">
          <MonCompte />
        </AuthRoute>
        <Route path="/guest">
          <GuestHome />
        </Route>
      </Switch>
    </div>
  );
};

export default App;
