import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from "../../utils";
import { selectAccessToken } from "../Authentication/authenticationSlice";
import {
  modalsAPI,
  // selectMesCharges,
  // selectMesRevenus,
  // selectBesoin,
  // selectCoordonnees,
  // selectSituation,
  // selectPatrimoine,
  // selectProjet,
  // selectParainage,
} from "./modalsUtils";
import { getDocuments, getPopupStatus } from "./dossierSlice";

export {
  selectMesCharges,
  selectMesRevenus,
  selectBesoin,
  selectCoordonnees,
  selectSituation,
  selectPatrimoine,
  selectProjet,
  selectParainage,
} from "./modalsUtils";

export const sendBesoin = createAsyncThunk(
  "modals/sendBesoin",
  async (values, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.sendBesoin({ jwt, values });
      thunkAPI.dispatch(getPopupStatus());
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const getBesoin = createAsyncThunk(
  "modals/getBesoin",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.getBesoin({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const sendPatrimoine = createAsyncThunk(
  "modals/sendPatrimoine",
  async (values, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.sendPatrimoine({
        jwt,
        values: values.map((cv) => ({
          ...cv,
          propertiesDetails:
            cv.propertyOwner === "false" ? [] : cv.propertiesDetails,
        })),
      });
      thunkAPI.dispatch(getPopupStatus());
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const getPatrimoine = createAsyncThunk(
  "modals/getPatrimoine",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.getPatrimoine({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const sendParainage = createAsyncThunk(
  "modals/sendParainage",
  async (values, thunkAPI) => {
    const objToSend = {
      heardAboutUs: values.heardAboutUs,
      [values.heardAboutUs]: { ...values[values.heardAboutUs] },
    };
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.sendParainage({ jwt, values: objToSend });
      thunkAPI.dispatch(getPopupStatus());
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const getParainage = createAsyncThunk(
  "modals/getParainage",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.getParainage({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const sendSituation = createAsyncThunk(
  "modals/sendSituation",
  async (values, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.sendSituation({
        jwt,
        values,
        // values: values.map((v) => ({
        //   ...v,
        //   dateOfBirth: moment(v.dateOfBirth).format(),
        // })),
      });
      thunkAPI.dispatch(getPopupStatus());
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const getSituation = createAsyncThunk(
  "modals/getSituation",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.getSituation({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const getRevenus = createAsyncThunk(
  "modals/getRevenus",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.getRevenus({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const sendRevenus = createAsyncThunk(
  "modals/sendRevenus",
  async (values, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.sendRevenus({ jwt, values });
      thunkAPI.dispatch(getPopupStatus());
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const sendMesCharges = createAsyncThunk(
  "modals/sendMesCharges",
  async (values, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.sendMesCharges({ jwt, values });
      thunkAPI.dispatch(getPopupStatus());
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const getMesCharges = createAsyncThunk(
  "modals/getMesCharges",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.getMesCharges({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const sendCoordonnees = createAsyncThunk(
  "modals/sendCoordonnees",
  async (values, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.sendCoordonnees({
        jwt,
        values: values.map((cv) => ({ ...cv, sameAddress: undefined })),
      });
      thunkAPI.dispatch(getPopupStatus());
      thunkAPI.dispatch(getDocuments());
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const getCoordonnees = createAsyncThunk(
  "modals/getCoordonnees",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.getCoordonnees({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const sendProjet = createAsyncThunk(
  "modals/sendProjet",
  async (values, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.sendProjet({ jwt, values });
      thunkAPI.dispatch(getPopupStatus());
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const getProjet = createAsyncThunk(
  "modals/getProjet",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await modalsAPI.getProjet({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const slice = createSlice({
  name: "modals",
  initialState: {
    // modals: [],
    parainage: {},
    besoin: {
      projectType: "",
      goodType: "",
      goodUsage: "",
      projectProgress: "",
      purchaseAmount: 0,
    },
    situation: [
      {
        familySituation: "",
        dateOfBirth: null,
        placeOfBirth: "",
        numberOfDependents: 0,
        currentAccommodation: "",
        amount: 0,
        entryDate: null,
        isFirstTimeBuyer: "",
      },
    ],
    revenus: {
      revenus: [
        {
          employmentType: "",
          employerName: "",
          startDate: null,
          companyName: "",
          retirementDate: null,
          netMonthlyIncome: "",
          monthlyLandIncome: "",
          pension: "",
          dividends: "",
          taxIncomeN2: "",
        },
      ],
    },
    coordonnees: {
      owners: [
        {
          title: "",
          firstName: "",
          lastName: "",
          address: "",
          city: "",
          postalCode: "",
          email: "",
          phoneNumber: "",
          monthlyIncome: "",
        },
      ],
    },
    mesCharges: [
      {
        alimony: "",
        hasCredits: "",
        credits: [
          // {
          //   creditType: "",
          //   remainingCapital: "",
          //   monthlyRate: "",
          //   endDate: null,
          // },
        ],
      },
    ],
    patrimoine: [
      {
        plannedContribution: "",
        propertyOwner: "",
        propertiesDetails: [
          {
            propertyValue: "",
            propertyUse: "",
            capitalOwedOnThisProperty: "",
          },
        ],
        savingsAvailableAfterTransaction: "",
      },
    ],
    projet: {
      projectType: "",
      propertyAddress: "",
      propertyCity: "",
      propertyPostalCode: "",
      propertyPrice: "",
      amountOfWork: "",
      propertyRenegotiationAmount: "",
    },
  },
  reducers: {
    // getModalsSuccess: (state, { payload }) => {
    //   state.modals = payload;
    // },
  },
  extraReducers: {
    [getBesoin.fulfilled]: (state, { payload }) => {
      state.besoin = payload;
    },
    [getPatrimoine.fulfilled]: (state, { payload }) => {
      state.patrimoine = [
        ...payload.map((cv) => ({
          ...cv,
          propertyOwner: cv.propertyOwner ? "true" : "false",
        })),
      ];
    },
    [getSituation.fulfilled]: (state, { payload }) => {
      state.situation = [
        // ...state.situation,
        ...payload.map((pv) => ({
          ...pv,
          numberOfDependents:
            pv.numberOfDependents === ""
              ? 0
              : parseInt(pv.numberOfDependents, 10),
          isFirstTimeBuyer: pv.isFirstTimeBuyer ? "true" : "false",
          entryDate: pv.entryDate === "" ? null : pv.entryDate,
        })),
      ];
    },
    [getCoordonnees.fulfilled]: (state, { payload }) => {
      state.coordonnees = { owners: payload };
    },
    [getRevenus.fulfilled]: (state, { payload }) => {
      state.revenus.revenus = payload;
    },
    [getParainage.fulfilled]: (state, { payload }) => {
      state.parainage = payload;
    },
    [getProjet.fulfilled]: (state, { payload }) => {
      state.projet = payload;
    },
    [getMesCharges.fulfilled]: (state, { payload }) => {
      state.mesCharges = [
        ...payload.map((cv) => ({
          ...cv,
          hasCredits: cv.hasCredits ? "true" : "false",
        })),
      ];
    },
  },
});

export const {
  // getModalsSuccess,
  getBesoinSuccess,
  getParainageSuccess,
  getSituationSuccess,
  getCoordonneesSuccess,
  getPatrimoineSuccess,
  getRevenusSuccess,
  getProjetSuccess,
  getMesChargesSuccess,
} = slice.actions;

export default slice.reducer;
