import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn, setReturnUrl } from "../authenticationSlice";

const AuthRoute = ({ children, adminOnly, ...rest }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAdmin = false;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn) {
          if (adminOnly) {
            if (isAdmin) {
              return cloneElement(children, { ...props });
            }
            return (
              <Redirect
                to={{ pathname: "/accueil", state: { from: props.location } }}
              />
            );
          }
          return cloneElement(children, { ...props });
          // return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />;
        }
        dispatch(setReturnUrl(props.location));
        return (
          <Redirect
            to={{
              pathname: "/authentication",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

AuthRoute.propTypes = {
  children: PropTypes.node.isRequired,
  adminOnly: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

AuthRoute.defaultProps = {
  adminOnly: false,
  isAdmin: false,
};

export default AuthRoute;
