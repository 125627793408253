import React, { useState, useEffect, useRef } from "react";
// import { useSelector, useDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import Card from "../Partials/Card/Card";
import GuestModals from "./GuestModals";
import Background from "../Partials/Background/Background";
import { getConstants } from "../Partials/constantsSlice";

const GuestHome = (props) => {
  const [hasNext, setHasNext] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(true);
  // const popupStatus = useSelector(selectPopupStatus);
  const informationArray = [
    {
      imageName: "man",
      text: "Mon projet",
      status: "toComplete",
      modal: "besoin",
    },
    {
      imageName: "man",
      text: "Ma situation",
      status: "toComplete",
      modal: "additionalInfo",
    },
    {
      imageName: "man",
      text: "Mes coordonees",
      status: "toComplete",
      modal: "coordonees",
    },
    {
      imageName: "man",
      text: "Taxes",
      status: "toComplete",
      modal: "taxes",
    },
    {
      imageName: "man",
      text: "Dispatch",
      status: "toComplete",
      modal: "dispatch",
    },
    {
      imageName: "man",
      text: "Calendly",
      status: "toComplete",
      modal: "calendly",
    },
    {
      imageName: "man",
      text: "Calendly Complete",
      status: "toComplete",
      modal: "calendlyComplete",
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConstants());
  });
  // const [isModalOpened, setModal] = useState("calendly");
  const [isModalOpened, setModal] = useState("besoin");
  const indexOfOpenedModal = useRef(0);
  useEffect(() => {
    if (isModalOpened) {
      // document.body.style.overflow = "hidden";
      indexOfOpenedModal.current = informationArray.findIndex(
        (f) => f.modal === isModalOpened
      );
      setHasPrevious(indexOfOpenedModal.current !== 0);
      setHasNext(indexOfOpenedModal.current < informationArray.length - 1);
    } else {
      // document.body.style.overflow = "unset";
      indexOfOpenedModal.current = 0;
    }
  }, [isModalOpened, informationArray]);
  const handleNext = () => {
    if (informationArray.length < indexOfOpenedModal.current + 1) {
      setModal(false);
    }
    setModal(informationArray[indexOfOpenedModal.current + 1].modal);
  };
  const handlePrevious = () => {
    if (indexOfOpenedModal.current - 1 < 0) {
      setModal(false);
    }
    setModal(informationArray[indexOfOpenedModal.current - 1].modal);
  };
  return (
    <Background>
      <GuestModals
        hideModal={setModal}
        showModal={isModalOpened}
        onNextClick={handleNext}
        onPreviousClick={handlePrevious}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
      />
      {!isModalOpened && (
        <div className="h-full max-w-screen-lg p-4 mx-auto dossier lg:p-0">
          {/* <div className="pt-10 dossier-heading">
          <h1>Mon dossier</h1>
          <p className="pt-4">
            Cliquez sur chaque élément pour le compléter et valider.
          </p>
        </div> */}
          <div className="pt-10 dossier-heading">
            {/* <h2>Mes informations</h2>
          <p className="pt-2">
            Ces informations seront demandées par les banques qui étudieront
            votre dossier.
          </p> */}
            <div className="grid grid-cols-1 gap-6 pt-6 sm:grid-cols-6">
              {informationArray.map((info, index) => (
                <Card
                  key={info.modal}
                  imageName={info.imageName}
                  text={info.text}
                  status={info.status}
                  onClick={() => setModal(info.modal)}
                  onKeyDown={() => setModal(info.modal)}
                  role="button"
                  tabIndex={index}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </Background>
  );
};

export default GuestHome;
