import React from "react";
import Coordonees from "../Modals/Coordonees/Coordonees";
import Besoin from "../Modals/Besoin/Besoin";
import Situation from "../Modals/Situation/Situation";
import Revenus from "../Modals/Revenus/Revenus";
import Patrimoine from "../Modals/Patrimoine/Patrimoine";
import Projet from "../Modals/Projet/Projet";
import Parrainage from "../Modals/Parrainage/Parrainage";
import MesCharges from "../Modals/MesCharges/MesCharges";

const DossierModals = ({
  showModal,
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
}) => {
  if (!showModal) {
    return null;
  }
  switch (showModal) {
    case "charges":
      return (
        <MesCharges
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "parrainage":
      return (
        <Parrainage
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "patrimoine":
      return (
        <Patrimoine
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "besoin":
      return (
        <Besoin
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "situation":
      return (
        <Situation
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "coordonees":
      return (
        <Coordonees
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "projet":
      return (
        <Projet
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    case "revenus":
      return (
        <Revenus
          hideModal={hideModal}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      );
    default:
      return null;
  }
};

export default DossierModals;
