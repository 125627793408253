import React from "react";
import classNames from "classnames";
import moment from "moment";
import { useDispatch } from "react-redux";
import check from "../../../images/check.svg";
import { cardIcons } from "./CardHelper";
import "./Card.scss";
import { downloadDocument } from "../../Documents/documentsSlice";

const Card = ({
  imageName,
  text,
  status,
  statusText,
  date,
  onClick,
  tabIndex,
  onKeyDown,
  signingLink,
  downloadLink,
}) => {
  const dispatch = useDispatch();
  const signedDoc = () => {
    return `Signé le: ${moment(date).format("DD MMM yyyy")}`;
  };
  const mapStatusToHuman = {
    toComplete: "A compléter",
    completed: "Complété",
    active: "A signer",
    toConsult: "A consulter",
    signed: signedDoc(),
  };

  const imageNameFromStatus = {
    active: "crayon",
    toConsult: "crayon",
    signed: "crayon",
  };

  const completedStatuses = ["completed", "signed"];
  const isSuccessful = completedStatuses.includes(status);

  const className = classNames(
    {
      "border-green-600": isSuccessful,
      "border-blue-500": !isSuccessful,
    },
    "relative dossier-card flex items-center px-6 shadow-lg col-span-2 bg-white border-b-2 border-solid select-none"
  );
  const handleSign = () => {
    if (signingLink) {
      if (status === "signed") {
        dispatch(downloadDocument(downloadLink));
        return;
      }
      window.open(signingLink, "_blank", "noopener");
    } else {
      dispatch(downloadDocument(downloadLink));
    }
  };

  return (
    <div
      className={className}
      onClick={onClick || handleSign}
      role="button"
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
    >
      <div className="flex">
        <div className="card-image">
          <img
            height="32px"
            width="32px"
            src={cardIcons[imageName || imageNameFromStatus[status]]}
            alt="document status"
          />
        </div>
        <div className="ml-6">
          <div className="flex items-center">
            <div className="font-qBold text-headline">{text}</div>
            {isSuccessful && (
              <div className="pl-1">
                <img src={check} alt="completed" />
              </div>
            )}
          </div>
          {isSuccessful ? (
            <div>
              <div className="text-body font-qRegular">
                {mapStatusToHuman[status]}
              </div>
              <div
                className="absolute underline text-blue-500 font-qSCBold text-subhead"
                style={{ bottom: "24px", right: "24px" }}
              >
                Voir
              </div>
            </div>
          ) : (
            <div className="dossier-card-status mt-2 font-qBold text-blue-500 text-headline">
              {mapStatusToHuman[status]}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
