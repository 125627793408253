import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import InfoIcon from "@material-ui/icons/Info";
import { Tooltip, withStyles } from "@material-ui/core";
import besoinIcons from "../../Modals/Besoin/BesoinIcons";
import Select from "../Select/Select";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const RadioSelect = ({
  options,
  heading,
  name,
  onChange,
  value,
  hasDropdown,
  handleChange: formikHandleChange,
}) => {
  const handleChange = (e, selectedValue) => {
    onChange(name, selectedValue);
  };
  const handleKeyDown = (e, selectedValue) => {
    if (e.keyCode === 13) {
      onChange(name, selectedValue);
    }
  };
  const customRadioClass = (selected) => {
    return classNames(
      {
        "font-qBold text-headline text-blue-500 selected-radio": selected,
        "font-qRegular text-headline": !selected,
      },
      "inline-block p-4 rounded xs:w-full md:w-1/3 h-14 flex xs:items-left md:items-center select-none relative"
    );
  };
  return (
    <div className="flex xs:items-left md:items-center flex-col md:flex-row">
      <div className="font-qBold text-headline xs:w-full sm:w-2/12 lg:w-3/12">
        {heading}
      </div>
      <div className="flex flex-col md:flex-row xs:items-left md:items-center sm:ml-12 md:ml-10 lg:ml-24 mr-0 xs:w-full sm:w-10/12  lg:w-9/12">
        {hasDropdown ? (
          <Select
            label=""
            emptyOptionLabel="Nature de votre projet"
            value={value}
            name="projectType"
            // error={errors.projectType}
            // touched={touched.projectType}
            onChange={formikHandleChange}
            noError
            trailIconName="arrowUpDown"
            options={options.map((opt) => ({
              label: opt.label,
              value: opt.value,
            }))}
          />
        ) : (
          options.map((opt, index) => (
            <div
              key={opt.value}
              className={customRadioClass(value === opt.value)}
              role="button"
              id={`${opt.label}-clickable`}
              tabIndex={index}
              onClick={() => handleChange(null, opt.value)}
              onKeyDown={(e) => handleKeyDown(e, opt.value)}
            >
              <img
                className="inline flex-shrink-0"
                src={besoinIcons[opt.icon]}
                alt="ceva"
              />
              <span className="ml-2 inline">{opt.label}</span>
              {opt.value === "NEW" && (
                <span className="ml-2 inline">
                  <LightTooltip
                    enterTouchDelay={300}
                    title="VEFA ou terrain + construction"
                    placement="top"
                  >
                    <InfoIcon style={{ fill: "#4860BD" }} />
                  </LightTooltip>
                </span>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

RadioSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  heading: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  hasDropdown: PropTypes.bool,
};
RadioSelect.defaultProps = {
  value: "",
  hasDropdown: false,
};
export default RadioSelect;
