import React from "react";
import { ClickAwayListener } from "@material-ui/core";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { ReactComponent as PartnershipGCD } from "./partnership_gcd.svg";
import Button from "../Button/Button";
import close from "../../../images/close.svg";
import { cardIcons } from "../Card/CardHelper";
import "./modal.scss";
import { selectPartnership } from "../../Guest/guestSlice";

const Modal = ({
  title,
  children,
  hideModal,
  submitForm,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
  isGuest,
  hideControls,
}) => {
  const onExit = () => {
    if (hideModal) {
      hideModal(false);
    }
  };

  const partnership = useSelector(selectPartnership);

  const previousButtonClass = classNames(
    {
      "bg-white text-blue-500 border-solid border-2 border-blue-500": isGuest,
      "bg-orange-500 text-red-500 border border-transparent": !isGuest,
    },
    "font-qSCBold uppercase shadow-sm py-4 px-4 sm:py-2 sm:px-6 sm:text-sm sm:leading-5"
  );

  const dialogClassName = classNames(
    {
      // "mt-16": partnership !== "",
    },
    "absolute right-0 sm:right-auto top-24 bottom-24 w-full px-4 pt-5 pb-4 overflow-auto transition-all transform bg-gray-600 rounded-sm shadow-xl sm:max-w-screen-lg sm:p-8 modal-body"
  );
  const modalClassName = classNames(
    {
      "py-16": partnership !== "",
      "py-4": partnership === "",
    },
    "fixed inset-x-0 bottom-0 z-50 h-full px-4 overflow-hidden sm:inset-x-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
  );

  const nextButtonClass = classNames(
    {
      "bg-blue-500 border-4 border-solid border-blue-500": isGuest,
      "bg-red-500 hover:bg-red-50 border-4 border-transparent": !isGuest,
    },
    "font-qSCBold uppercase text-white shadow-sm 0 py-4 px-4 sm:py-2 sm:px-6 sm:text-sm sm:leading-5"
  );

  return (
    <div className={modalClassName}>
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-500 opacity-75" />
      </div>
      {partnership !== "" && (
        <div className="fixed top-0 left-0 z-50 w-screen h-20 pt-2 pl-4 bg-white">
          <PartnershipGCD />
        </div>
      )}
      <ClickAwayListener onClickAway={isGuest ? () => {} : onExit}>
        <div
          className={dialogClassName}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="mt-1 text-center sm:mt-1">
              <div className="flex items-center justify-center">
                <h1
                  className="text-red-500 font-qSCBold text-modalHeader"
                  id="modal-headline"
                >
                  {title}
                </h1>
                {!isGuest && (
                  <button
                    type="button"
                    className="absolute text-left right-6"
                    onClick={onExit}
                  >
                    <img src={close} alt="close-btn" />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div>{children}</div>
          {!hideControls && (
            <div className="-mx-4 sm:-mx-8">
              <div className="border-t-2 border-white border-solid">
                <div className="flex items-center px-4 pt-5 mt-5 sm:mt-6 sm:px-8 sm:pt-6">
                  <div className="w-1/3 text-left">
                    {hasPrevious && (
                      <Button
                        className={previousButtonClass}
                        variant="next"
                        withIcon
                        // type="submit"
                        onClick={onPreviousClick}
                      >
                        <div className="flex items-center h-full">
                          <img
                            className="sm:mr-2"
                            src={
                              isGuest
                                ? cardIcons.arrowLeftBlue
                                : cardIcons.arrowLeftOrange
                            }
                            alt="button"
                          />
                        </div>
                        <span className="hidden sm:block">|</span>
                        <span className="hidden ml-2 sm:block">Précédent</span>
                      </Button>
                    )}
                  </div>
                  <div className="w-1/3 text-center">
                    {hasPrevious && !isGuest && (
                      <Button
                        className="text-gray-700 uppercase font-qSCBold sm:py-2 sm:px-6"
                        variant="transparentButton"
                        onClick={onExit}
                        noPadding
                      >
                        <span className="hidden text-gray-700 sm:block">
                          Continuer plus tard
                        </span>
                        <span className="text-gray-700 sm:hidden">
                          Plus tard
                        </span>
                      </Button>
                    )}
                  </div>
                  <div className="w-1/3 text-right">
                    {hasNext ? (
                      <Button
                        className={nextButtonClass}
                        variant="next"
                        withIcon
                        type="submit"
                      >
                        <span className="hidden mr-2 sm:block">
                          {isGuest ? `Continuer` : `Suivant`}
                        </span>
                        <span className="hidden sm:block">|</span>
                        <div className="flex items-center h-full">
                          <img
                            className="sm:ml-2"
                            src={cardIcons.arrowRightWhite}
                            alt="button"
                          />
                        </div>
                      </Button>
                    ) : (
                      <Button
                        className="px-4 py-4 text-white uppercase bg-blue-500 border-4 border-transparent shadow-sm font-qSCBold hover:bg-blue-500 sm:py-2 sm:px-6 sm:text-sm sm:leading-5"
                        variant="next"
                        withIcon
                        type="submit"
                      >
                        <span className="hidden mr-2 sm:block">
                          {isGuest ? `Continuer` : `Terminer`}
                        </span>
                        <span className="hidden sm:block">|</span>
                        <div className="flex items-center h-full">
                          <img
                            className="sm:ml-2"
                            src={cardIcons.arrowRightWhite}
                            alt="button"
                          />
                        </div>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Modal;
