/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import classNames from "classnames";

export default function BooleanRadioSelect(props) {
  const { label, name, onChange, value, index, error, touched } = props;
  const yesRadioClass = classNames(
    {
      "font-qBold": value === "true",
      "font-qRegular": value === "false",
    },
    "flex items-center cursor-pointer text-xl text-headline"
  );
  const noRadioClass = classNames(
    {
      "font-qBold": value === "false",
      "font-qRegular": value === "true",
    },
    "flex items-center cursor-pointer text-xl text-headline"
  );
  return (
    <>
      <div className="col-span-6 sm:col-span-2">
        <p className="font-qBold text-headline">{label}</p>
      </div>
      <div className="col-span-3 sm:col-span-1">
        <input
          id={`radio-${index}-yes`}
          type="radio"
          name={`${name}-true`}
          className="hidden"
          value="true"
          onChange={(e) => {
            onChange(name, e.target.value);
          }}
          checked={value === "true"}
        />
        <label htmlFor={`radio-${index}-yes`} className={yesRadioClass}>
          <span className="w-5 h-5 inline-block mr-2 rounded-full flex-no-shrink" />
          Oui
        </label>
      </div>
      <div className="col-span-3 sm:col-span-1">
        <input
          id={`radio-${index}-no`}
          type="radio"
          name={`${name}-false`}
          className="hidden"
          value="false"
          onChange={(e) => {
            onChange(name, e.target.value);
          }}
          checked={value === "false"}
        />
        <label htmlFor={`radio-${index}-no`} className={noRadioClass}>
          <span className="w-5 h-5 inline-block mr-2 rounded-full flex-no-shrink" />
          Non
        </label>
      </div>
      {!!error && !!touched && (
        <>
          <div className="hidden sm:col-span-3 sm:block" />
          <div className="col-span-6 sm:col-span-2">{error}</div>
        </>
      )}
    </>
  );
}
