import React from "react";
import { Paper, useMediaQuery } from "@material-ui/core";
import "./Taxes.scss";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import numeral from "numeral";
import Button from "../../Partials/Button/Button";
import { cardIcons } from "../../Partials/Card/CardHelper";
import { selectTaxes } from "../guestSlice";

numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal(number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€",
  },
});
numeral.locale("fr");

export default function Taxes({ onNextClick }) {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getTaxes());
  // }, [dispatch]);
  const numberOfYears = [15, 20, 25];
  const taxes = useSelector(selectTaxes);
  const taxesArr = [];
  numberOfYears.forEach((n) => {
    taxesArr.push({
      years: `${n} ans`,
      borrowedSum: `${numeral(taxes[n].creditAmount).format()} €`,
      monthlyAmount: `${numeral(taxes[n].monthlyRate).format()} €`,
      creditCost: `${numeral(taxes[n].creditCost).format()} €`,
      tax: `${taxes[n].annualInterest} %`,
    });
  });
  // const taxesArr = [
  //   {
  //     years: `15 ans`,
  //     borrowedSum: `${numeral(taxes["15"].creditAmount).format("0 0,0")} €`,
  //     monthlyAmount: `${numeral(taxes["15"].monthlyRate).format("0 0,0")} €`,
  //     creditCost: `${numeral(taxes["15"].creditCost).format("0 0,0")} €`,
  //     tax: `${taxes["15"].annualInterest} %`,
  //   },
  //   {
  //     years: `20 ans`,
  //     borrowedSum: `${numeral(taxes["20"].creditAmount).format("0 0,0")} €`,
  //     monthlyAmount: `${numeral(taxes["20"].monthlyRate).format("0 0,0")} €`,
  //     creditCost: `${numeral(taxes["20"].creditCost).format("0 0,0")} €`,
  //     tax: `${taxes["20"].annualInterest} %`,
  //   },
  //   {
  //     years: `25 ans`,
  //     borrowedSum: `${numeral(taxes["25"].creditAmount).format("0 0,0")} €`,
  //     monthlyAmount: `${numeral(taxes["25"].monthlyRate).format("0 0,0")} €`,
  //     creditCost: `${numeral(taxes["25"].creditCost).format("0 0,0")} €`,
  //     tax: `${taxes["25"].annualInterest} %`,
  //   },
  // ];
  // const taxesArr = Object.keys(taxes).map((key) => ({
  //   years: `${key} ans`,
  //   borrowedSum: `${numeral(taxes[key].creditAmount).format("0 0,0")} €`,
  //   monthlyAmount: `${numeral(taxes[key].monthlyRate).format("0 0,0")} €`,
  //   creditCost: `${numeral(taxes[key].creditCost).format("0 0,0")} €`,
  //   tax: `${taxes[key].annualInterest} %`,
  // }));
  return (
    <div className="max-w-screen-lg mx-auto">
      <div className="px-4 pt-20 text-center text-red-500 font-qSCBold text-header sm:px-0">
        Barème des taux dans votre région
      </div>
      <div className="px-4 mt-6 text-center font-qRegular text-headline sm:px-0">
        Artémis Courtage vous propose les meilleurs taux régionaux négociés avec
        les banques
      </div>
      <div className="hidden mt-20 sm:block">
        <div className="grid grid-flow-col grid-cols-11 col-gap-6">
          <div className="col-span-2 row-span-5">
            <div className="grid grid-cols-1">
              <div className="custom-padding-empty" />
              <div>
                <div className="text-right custom-padding">
                  <span className="font-qRegular text-headline">
                    Montant emprunté
                  </span>
                </div>
                <div
                  className="ml-auto border-b border-white border-solid custom-border"
                  style={{ width: "28px" }}
                />
              </div>
              <div>
                <div className="text-right custom-padding">
                  <span className="font-qRegular text-headline">
                    Mensualités
                  </span>
                </div>
                <div
                  className="ml-auto border-b border-white border-solid custom-border"
                  style={{ width: "28px" }}
                />
              </div>
              <div>
                <div className="text-right custom-padding">
                  <span className="font-qRegular text-headline">Taux</span>
                </div>
                <div
                  className="ml-auto border-b border-white border-solid custom-border"
                  style={{ width: "28px" }}
                />
              </div>
              <div>
                <div className="text-right custom-padding">
                  <span className="font-qRegular text-headline">
                    Coût de crédit
                  </span>
                </div>
              </div>
            </div>
          </div>
          {taxesArr.map((taxInfo) => (
            <div key={taxInfo.years} className="col-span-3 row-span-5">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <TaxesCard {...taxInfo} />
            </div>
          ))}
        </div>
      </div>
      <div className="block mt-20 sm:hidden">
        <Slider slidesToShow={1} centerMode arrows={false} infinite={false}>
          {taxesArr.map((taxInfo) => (
            <div key={taxInfo.years} className="max-w-xs px-4 py-1">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <TaxesCard {...taxInfo} isUnder />
            </div>
          ))}
        </Slider>
      </div>
      <div className="mt-16 text-center">
        <Button
          className="px-4 py-4 text-white uppercase bg-red-500 border-4 border-transparent shadow-sm font-qSCBold hover:bg-red-500 sm:py-2 sm:px-6 sm:text-sm sm:leading-5"
          variant="next"
          withIcon
          onClick={onNextClick}
        >
          <span className="hidden mr-2 sm:block">Continuer</span>
          <span className="hidden sm:block">|</span>
          <div className="flex items-center h-full">
            <img
              className="sm:ml-3 sm:mt-1"
              src={cardIcons.chevronRight}
              alt="button"
            />
          </div>
        </Button>
      </div>
    </div>
  );
}

function TaxesCard({
  years,
  monthlyAmount,
  borrowedSum,
  tax,
  creditCost,
  isUnder,
}) {
  return (
    <Paper className="h-full">
      <div className="grid grid-cols-1">
        <div className="py-5 mx-4 text-center text-red-500 border-b border-blue-300 border-opacity-25 border-solid font-qSCBold text-taxesHeader">
          {years}
        </div>
        <div className="py-5 mx-4 text-center border-b border-blue-300 border-opacity-25 border-solid font-qBold text-subhead">
          {isUnder && "Montant emprunté: "} {borrowedSum}
        </div>
        <div className="py-5 mx-4 text-center border-b border-blue-300 border-opacity-25 border-solid font-qBold text-subhead">
          {isUnder && "Mensualités: "} {monthlyAmount}
        </div>
        <div className="py-5 mx-4 text-center border-b border-blue-300 border-opacity-25 border-solid font-qBold text-subhead">
          {isUnder && "Taux: "}
          {tax}
        </div>
        <div className="py-5 mx-4 text-center font-qBold text-subhead">
          {isUnder && "Coût de crédit: "}
          {creditCost}
        </div>
      </div>
    </Paper>
  );
}
