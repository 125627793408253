import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import Card from "../Partials/Card/Card";
import Chat from "../Chat/Chat";
import DossierModals from "./DossierModals";
import LargeCard from "../Partials/LargeCard/LargeCard";
import Background from "../Partials/Background/Background";
import "./Dossier.scss";
import {
  selectDocuments,
  getDocuments,
  getPopupStatus,
  selectPopupStatus,
} from "./dossierSlice";
import CollapseIcon from "./CollapseIcon.svg";

const Dossier = (props) => {
  const uploadedDocuments = useSelector(selectDocuments);
  const location = useLocation();
  const justificatifsRef = useRef(null);
  useEffect(() => {
    if (location.hash !== "") {
      const elem = location.hash.substring(1);
      if (elem === "justificatifs") {
        window.scrollTo(0, justificatifsRef.current.offsetTop);
      }
    }
  }, [location.hash]);
  const [hasNext, setHasNext] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(true);
  const [isInformationArrayExpanded, setIsInformationArrayExpanded] = useState(
    true
  );
  // const [
  //   isJustificatifsArrayExpanded,
  //   setIsJustificatifsArrayExpanded,
  // ] = useState(true);
  const documentsLeft = uploadedDocuments.filter((x, index) => index % 2 === 0);
  const documentsRight = uploadedDocuments.filter(
    (x, index) => index % 2 === 1
  );
  const popupStatus = useSelector(selectPopupStatus);
  const informationArray = [
    {
      imageName: "man",
      text: "Mon projet",
      status: popupStatus.isStep1Completed ? "completed" : "toComplete",
      modal: "besoin",
    },
    {
      imageName: "envelope",
      text: "Mes coordonnées",
      status: popupStatus.isStep2Completed ? "completed" : "toComplete",
      modal: "coordonees",
    },
    {
      imageName: "pig",
      text: "Ma situation",
      status: popupStatus.isStep3Completed ? "completed" : "toComplete",
      modal: "situation",
    },
    {
      imageName: "money",
      text: "Mes revenus",
      status: popupStatus.isStep4Completed ? "completed" : "toComplete",
      modal: "revenus",
    },
    {
      imageName: "building",
      text: "Mon patrimoine",
      status: popupStatus.isStep5Completed ? "completed" : "toComplete",
      modal: "patrimoine",
    },
    {
      imageName: "pie",
      text: "Mes charges",
      status: popupStatus.isStep6Completed ? "completed" : "toComplete",
      modal: "charges",
    },
    {
      imageName: "home",
      text: "Détails du projet",
      status: popupStatus.isStep7Completed ? "completed" : "toComplete",
      modal: "projet",
    },
    {
      imageName: "bar",
      text: "Parrainage",
      status: popupStatus.isStep8Completed ? "completed" : "toComplete",
      modal: "parrainage",
    },
  ];
  // const uploadedDocuments = useSelector(selectDocuments);
  const [isModalOpened, setModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPopupStatus());
    dispatch(getDocuments());
  }, [dispatch]);
  const indexOfOpenedModal = useRef(0);
  useEffect(() => {
    if (isModalOpened) {
      document.body.style.overflow = "hidden";
      indexOfOpenedModal.current = informationArray.findIndex(
        (f) => f.modal === isModalOpened
      );
      setHasPrevious(indexOfOpenedModal.current !== 0);
      setHasNext(indexOfOpenedModal.current < informationArray.length - 1);
    } else {
      document.body.style.overflow = "unset";
      indexOfOpenedModal.current = 0;
    }
  }, [isModalOpened, informationArray]);
  const handleNext = () => {
    if (informationArray.length < indexOfOpenedModal.current + 1) {
      setModal(false);
    }
    setModal(informationArray[indexOfOpenedModal.current + 1].modal);
  };
  const handlePrevious = () => {
    if (indexOfOpenedModal.current - 1 < 0) {
      setModal(false);
    }
    setModal(informationArray[indexOfOpenedModal.current - 1].modal);
  };
  const cardInformationArray = () => {
    return classnames("grid grid-cols-1 sm:grid-cols-6 gap-6 pt-6", {
      hidden: !isInformationArrayExpanded,
      block: isInformationArrayExpanded,
    });
  };
  const justificatifsInformationArray = () => {
    return classnames("flex flex-wrap flex-row pt-4", {
      // hidden: !isJustificatifsArrayExpanded,
      // block: isJustificatifsArrayExpanded,
    });
  };
  return (
    <Background>
      <DossierModals
        hideModal={setModal}
        showModal={isModalOpened}
        onNextClick={handleNext}
        onPreviousClick={handlePrevious}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
      />
      <div className="dossier mx-auto max-w-screen-lg h-full p-4 lg:p-0">
        <div className="dossier-heading pt-10">
          <h1>Mon dossier</h1>
          <p className="pt-4">
            Cliquez sur chaque rubrique pour compléter et valider votre dossier.
          </p>
        </div>
        <div className="dossier-heading pt-10">
          <div
            className="flex items-baseline cursor-pointer"
            onClick={() => setIsInformationArrayExpanded((v) => !v)}
            role="presentation"
          >
            <div>
              <h2>Mes informations</h2>
              <p className="pt-2">
                Ces informations seront demandées par les banques qui étudieront
                votre dossier.
              </p>
            </div>
            <div className="flex-grow" />
            <div>
              <img src={CollapseIcon} alt="collapse" />
            </div>
          </div>
          <div className={cardInformationArray()}>
            {informationArray.map((info, index) => (
              <Card
                key={info.modal}
                imageName={info.imageName}
                text={info.text}
                status={info.status}
                onClick={() => setModal(info.modal)}
                onKeyDown={() => setModal(info.modal)}
                role="button"
                tabIndex={index}
              />
            ))}
          </div>
        </div>
        <div
          className="dossier-heading pt-10"
          id="justificatifs"
          ref={justificatifsRef}
        >
          <div
            className="flex items-baseline"
            // onClick={() => setIsJustificatifsArrayExpanded((v) => !v)}
            role="presentation"
          >
            <div>
              <h2>Mes justificatifs</h2>
              <p className="pt-2">
                Ces justificatifs seront demandés par les banques qui étudieront
                votre dossier.
              </p>
            </div>
            <div className="flex-grow" />
            {/* <div>
              <img src={CollapseIcon} alt="collapse" />
            </div> */}
          </div>
          <div className={justificatifsInformationArray()}>
            <div className="sm:w-1/2 w-full sm:pr-4">
              {documentsLeft.map((doc) => (
                <div key={doc.name} className="pb-4">
                  <LargeCard
                    document={doc}
                    // files={uploadedDocuments[doc.documentType]}
                    // imageName={doc.imageName}
                    // documentType={doc.documentType}
                    // text={doc.text}
                    // count="0"
                    // arrow={doc.arrow}
                    // caption={doc.caption}
                    hasPanel
                  />
                </div>
              ))}
            </div>
            <div className="sm:w-1/2 w-full sm:pl-4">
              {documentsRight.map((doc) => (
                <div key={doc.name} className="pb-4">
                  <LargeCard
                    document={doc}
                    // files={uploadedDocuments[doc.documentType]}
                    // imageName={doc.imageName}
                    // documentType={doc.documentType}
                    // text={doc.text}
                    // count="0"
                    // arrow={doc.arrow}
                    // caption={doc.caption}
                    hasPanel
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Chat />
    </Background>
  );
};

export default Dossier;
