// /projects/constants
import { createSlice } from "@reduxjs/toolkit";
import { handleError } from "../../utils";
import { selectAccessToken } from "../Authentication/authenticationUtils";
import { constantsAPI } from "./constantsUtils";

export { selectConstants, selectDynamicConstants } from "./constantsUtils";

export const slice = createSlice({
  name: "constants",
  initialState: { constants: {}, dynamicConstants: {} },
  reducers: {
    getConstantsSuccess: (state, { payload }) => {
      state.constants = payload;
    },
    getDynamicConstantsSuccess: (state, { payload }) => {
      state.dynamicConstants = payload;
    },
  },
});

export const {
  getConstantsSuccess,
  getDynamicConstantsSuccess,
} = slice.actions;

// export const { setReturnUrl } = slice.actions;

// export const selectRefreshToken = (state) => state.auth.refreshToken;

export const getConstants = () => async (dispatch, getState) => {
  try {
    const jwt = selectAccessToken(getState());
    const res = await constantsAPI.getConstants({ jwt });
    dispatch(getConstantsSuccess(res.body));
  } catch (err) {
    handleError(err);
  }
};
export const getDynamicConstants = () => async (dispatch, getState) => {
  try {
    const jwt = selectAccessToken(getState());
    const res = await constantsAPI.getDynamicConstants({ jwt });
    dispatch(getDynamicConstantsSuccess(res.body));
  } catch (err) {
    handleError(err);
  }
};

export default slice.reducer;
