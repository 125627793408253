import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import authReducer from "../components/Authentication/authenticationSlice";
import dossierReducer from "../components/Dossier/dossierSlice";
import modalsReducer from "../components/Dossier/modalsSlice";
import documentsReducer from "../components/Documents/documentsSlice";
import constantsReducer from "../components/Partials/constantsSlice";
import guestReducer from "../components/Guest/guestSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    dossier: dossierReducer,
    modals: modalsReducer,
    documents: documentsReducer,
    constants: constantsReducer,
    guest: guestReducer,
  },
  middleware:
    process.env.NODE_ENV === "production"
      ? [...getDefaultMiddleware()]
      : [...getDefaultMiddleware(), logger],
});
