import React from "react";
// import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
// import Background from "../Partials/Background/Background";
// import user from "../../images/man.svg";
// import clip from "../../images/clip.svg";
// import crayon from "../../images/crayon.svg";
// import lightBulb from "../../images/light-bulb.svg";
import calendarWhite from "../../images/calendar-white.svg";
import Button from "../Partials/Button/Button";
// import { createAccount } from "./guestSlice";

const Dispatch = ({
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
}) => {
  // const history = useHistory();
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   toast.info(
  //     <>
  //       <span className="text-red-500 font-qSCBold text-xl">
  //         Félicitations !
  //       </span>
  //       <p className="font-qSCBold text-lg text-left">
  //         Vous venez de déposer votre demande de prêt et nous vous en
  //         remercions. Vous allez être recontacté dans les meilleurs délais par
  //         l’un de nos experts ou choisir un horaire de rappel ci-dessous.
  //       </p>
  //     </>,
  //     {
  //       autoClose: 20000,
  //     }
  //   );
  // }, []);
  // const navigateTo = async () => {
  //   await dispatch(createAccount());
  //   history.push("/authentication");
  // };
  return (
    <div className="md:flex content-center justify-center items-center flex-wrap max-w-screen-lg mx-auto computedHeight">
      {/* <div className="grid grid-cols-1 row-gap-5 col-gap-6 sm:grid-cols-6 relative"> */}
      <div className="flex justify-center items-center w-full h-full">
        <Paper
          elevation={3}
          className="py-12 px-4 sm:px-16 rounded bg-white shadow-xl text-center flex flex-col sm:flex-row"
        >
          <div className="flex flex-col">
            <p className="font-qSCBold text-modalHeader text-red-500">
              DEMANDE ENVOYÉE !
            </p>
            <p className="font-qRegular pt-4">
              Nous avons bien reçu votre demande, nous l&apos;étudions et
              revenons vers vous rapidement. <br />
              Pour une étude personalisée, nos experts sont à votre disposition.
            </p>
            <div className="w-32 m-auto mt-6 mb-5">
              <img
                src="https://d3v0px0pttie1i.cloudfront.net/uploads/user/avatar/5856170/63050a80.jpg"
                alt="chloe"
                className="border-4 border-solid rounded-full border-orange-500"
              />
            </div>
            <div>
              {/* <p className="font-qSCBold text-dispatchSubHeading text-red-500">
                Chloé Chatel
              </p> */}
              {/* <p className="font-qBold text-subhead uppercase mt-2">Paris</p> */}
              {/* <p className="font-qRegular text-caption mt-1">
                  {" "}
                  01 12 23 45 67
                </p> */}
            </div>
            <div>
              <Button
                className="font-qSCBold uppercase bg-red-500 text-white shadow-sm hover:bg-red-500 py-4 px-4 sm:py-2 sm:px-6 sm:text-sm sm:leading-5 mb-4"
                variant="next"
                withIcon
                type="button"
                onClick={onNextClick}
              >
                <span className="mr-2">Prendre rendez-vous</span>
                <span className="hidden sm:block">|</span>
                <div className="h-full flex items-center">
                  <img className="sm:ml-2" src={calendarWhite} alt="button" />
                </div>
              </Button>
            </div>
            <div className="mt-3">
              <p className="font-qBold text-caption opacity-50">
                Pour une étude personnalisée
              </p>
            </div>
          </div>
        </Paper>
      </div>
      {/* <div
            className="hidden sm:block absolute top-1/2 rounded-full bg-red-500 text-center"
            style={{ right: "47%" }}
          >
            <span className="py-2 px-5 font-qSCBold text-dispatchSubHeading text-white capitalize">
              Ou
            </span>
          </div>
          <div className="sm:col-span-3">
            <Paper
              elevation={3}
              className="py-12 px-16 rounded bg-white shadow-xl flex flex-col"
            >
              <p className="font-qSCBold text-modalHeader text-blue-500  text-center">
                Créer mon dossier
              </p>
              <p>&nbsp;</p>
              <div className="mx-auto">
                <div className="flex flex-row items-start justify-center mb-8 mt-16">
                  <div className="w-8">
                    <img src={user} alt="dispatch_image" />
                  </div>
                  <p className="font-qBold text-blue-500 text-subhead uppercase ml-4">
                    Mes informations
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center mb-8">
                  <div className="w-8">
                    <img src={clip} alt="dispatch_image" />
                  </div>
                  <p className="font-qBold text-blue-500 text-subhead uppercase ml-4">
                    mes justificatifs
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center mb-8">
                  <div className="w-8">
                    <img src={crayon} alt="dispatch_image" />
                  </div>
                  <p className="font-qBold text-blue-500 text-subhead uppercase ml-4">
                    mes Documents
                  </p>
                </div>
              </div>
              <div className="flex-1" />
              <div className="flex flex-row items-start justify-center">
                <Button
                  className="font-qSCBold uppercase bg-blue-500 text-white shadow-sm hover:bg-blue-500 py-4 px-4 sm:py-2 sm:px-6 sm:text-sm sm:leading-5 mt-4"
                  variant="next"
                  withIcon
                  type="button"
                  onClick={navigateTo}
                >
                  <span className="mr-2">Poursuivre</span>
                  <span className="hidden sm:block">|</span>
                  <div className="h-full flex items-center">
                    <img className="sm:ml-2" src={lightBulb} alt="button" />
                  </div>
                </Button>
              </div>
              <div className="mt-3 text-center">
                <p className="font-qBold text-caption opacity-50">
                  Mon dossier en quelques minutes
                </p>
              </div>
            </Paper>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Dispatch;
