import React from "react";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Completion.scss";

const Completion = ({ text, percentage }) => {
  // const options = {
  //   strokeLinecap: "butt",
  //   textColor: "#4860bd",
  //   // textColor: "#FFFFFF",
  //   pathColor: "#4860bd",
  //   // pathColor: "#F8F8F8",
  //   trailColor: "#a6b3e2",
  //   // trailColor: "#A6B3E2",
  // };

  return (
    <div className="completion-card">
      {/* <span className="completion-card-icon">
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles(options)}
        />
      </span> */}
      <span className="completion-card-text">{text}</span>
    </div>
  );
};

export default Completion;
