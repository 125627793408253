import React from "react";
// import background from "./background.svg";

export default function BackgroundImage(props) {
  const { children } = props;
  return (
    <div
      style={
        {
          // backgroundImage: `url(${background})`,
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          // height: "100%",
          // minHeight: "100%",
          // display: "flex",
          // justifyContent: "center",
        }
      }
    >
      {children}
    </div>
  );
}
