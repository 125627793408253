import React from "react";
import "../Input/input.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import userIcon from "../../../images/user.svg";
import homeIcon from "../../../images/home.svg";
import airplaneIcon from "../../../images/airplane.svg";
import mobileIcon from "../../../images/mobile.svg";
import documentIcon from "../../../images/document.svg";
import euroIcon from "../../../images/euro.svg";
import calendarIcon from "../../../images/calendar.svg";
import arrowUpDownIcon from "../../../images/arrow-up-down.svg";

const icons = {
  user: userIcon,
  home: homeIcon,
  airplane: airplaneIcon,
  mobile: mobileIcon,
  document: documentIcon,
  euro: euroIcon,
  calendar: calendarIcon,
  arrowUpDown: arrowUpDownIcon,
};
//
export default function Select(props) {
  const {
    label,
    options,
    value,
    trailIconName,
    leadingIconName,
    error,
    touched,
    name,
    required,
    isRequired,
    onChange,
    noError,
    emptyOptionLabel,
    // hasEmptyOption,
    // emptyOptionText,
  } = props;
  const hasLeadingIcon = !!leadingIconName;
  const hasTrailIcon = !!trailIconName;
  const customWrapperClass = classNames(
    {
      // "sm:pl-4": hasTrailIcon,
    },
    "mt-1 relative rounded-md shadow-sm w-full"
  );
  const customTrailContainerClass = classNames(
    {
      "left-0 pl-3": !hasTrailIcon,
      "right-0 pr-3": hasTrailIcon,
    },
    "absolute inset-y-0 flex items-center pointer-events-none"
  );
  const customLeadingContainerClass = classNames(
    {
      "left-0 pl-3": hasLeadingIcon,
      "right-0 pr-3": !hasLeadingIcon,
    },
    "absolute inset-y-0 flex items-center pointer-events-none"
  );
  const customInputClass = classNames(
    {
      "border-red-500 border-solid border": error && touched,
      "focus:border-blue-500 focus:border-solid focus:border": !(
        error && touched
      ),
      "pl-10": hasLeadingIcon,
      "pr-10": hasTrailIcon,
      "select-no-value": value === "",
    },
    "form-input block w-full sm:text-sm sm:leading-5 artemis-input"
  );
  return (
    <div className="">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-5 artemis-label"
      >
        {label}
        {isRequired && <span>*</span>}
      </label>
      <div className={customWrapperClass}>
        {hasLeadingIcon && (
          <div className={customLeadingContainerClass}>
            <img src={icons[leadingIconName]} alt="input-icon" />
          </div>
        )}
        <select
          required={required}
          id={name}
          onChange={onChange}
          name={name}
          value={value}
          className={customInputClass}
          //   className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 sm:text-sm sm:leading-5 artemis-input"
        >
          <option value="" disabled hidden>
            {label || emptyOptionLabel}
          </option>
          {options.map((opt) => (
            <option
              className="text-sm font-medium leading-5"
              key={opt.value}
              value={opt.value}
            >
              {opt.label}
            </option>
          ))}
          {/* <option>United States</option>
          <option>Canada</option>
          <option>Mexico</option> */}
        </select>
        {hasTrailIcon && (
          <div className={customTrailContainerClass}>
            <img src={icons[trailIconName]} alt="input-icon" />
          </div>
        )}
      </div>
      {!noError && (
        <p
          className="mt-2 text-sm tracking-normal leading-none artemis-error-text"
          style={{ minHeight: "2em" }}
          id={`${name}-error`}
        >
          {!!error && !!touched ? error : ""}
        </p>
      )}
    </div>
  );
}

Select.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  value: PropTypes.string,
  trailIconName: PropTypes.string,
  leadingIconName: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  noError: PropTypes.bool,
};
