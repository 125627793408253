import React from "react";
import classNames from "classnames";
import Input from "../../Partials/Input/Input";
import Select from "../../Partials/Select/Select";
import "../../../styles/DatePicker.scss";
import "./Revenus.scss";
import ArtemisKeyboardDatePicker from "../../Partials/KeyboardDatePicker/ArtemisKeyboardDatePicker";

const RevenusForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  index,
  handleRemove,
  setFieldValue,
  constants,
}) => {
  const safeErrors = errors || {};
  const safeTouched = touched || {};
  const safeValues = values
    ? {
        employmentType: "",
        employerName: "",
        startDate: null,
        companyName: "",
        retirementDate: null,
        netMonthlyIncome: "",
        monthlyLandIncome: "",
        pension: "",
        dividends: "",
        taxIncomeN2: "",
        ...values,
      }
    : {
        employmentType: "",
        employerName: "",
        startDate: null,
        companyName: "",
        retirementDate: null,
        netMonthlyIncome: "",
        monthlyLandIncome: "",
        pension: "",
        dividends: "",
        taxIncomeN2: "",
      };
  const hideEmployerByEmployerType = (value) => {
    return classNames(
      {
        hidden: value !== "PRIVATE_EMPLOYEE" && value !== "PUBLIC_EMPLOYEE",
      },
      "col-span-6 sm:col-span-2"
    );
  };

  const hideCompanyFieldsByEmployerType = (value) => {
    return classNames(
      {
        hidden:
          value !== "ARTISAN_TRADER" &&
          value !== "SELF_EMPLOYED" &&
          value !== "COMPANY_MANAGER" &&
          value !== "FREELANCER",
      },
      "col-span-6 sm:col-span-2"
    );
  };

  const hideActivityDateByEmployerType = (value) => {
    return classNames(
      {
        hidden:
          value !== "PROJECT_BASED_CONTRACTOR" &&
          value !== "ARTISAN_TRADER" &&
          value !== "SELF_EMPLOYED" &&
          value !== "COMPANY_MANAGER" &&
          value !== "FREELANCER",
      },
      "col-span-6 sm:col-span-2"
    );
  };

  const hideRetirementDateByEmployerType = (value) => {
    return classNames(
      {
        hidden: value !== "RETIRED",
      },
      "col-span-6 sm:col-span-2"
    );
  };
  return (
    <div className="grid-cols-6 grid col-gap-8 items-end relative pt-2">
      <div className="col-span-6 sm:col-span-2">
        <Select
          label="Type d'emploi"
          value={safeValues.employmentType}
          name={`revenus.${index}.employmentType`}
          onChange={handleChange}
          trailIconName="arrowUpDown"
          options={Object.keys(constants.employmentType).map((key) => ({
            value: key,
            label: constants.employmentType[key],
          }))}
        />
      </div>
      <div className={hideEmployerByEmployerType(safeValues.employmentType)}>
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          errors={safeErrors.employerName}
          touched={safeTouched.employerName}
          label="Nom de l'employeur"
          placeholder="Nom de l'employeur"
          value={safeValues.employerName}
          name={`revenus.${index}.employerName`}
        />
      </div>
      <div className={hideEmployerByEmployerType(safeValues.employmentType)}>
        <ArtemisKeyboardDatePicker
          name={`revenus.${index}.startDate`}
          label="Date d'entrée"
          setFieldValue={setFieldValue}
          value={safeValues.startDate}
        />
      </div>
      <div
        className={hideCompanyFieldsByEmployerType(safeValues.employmentType)}
      >
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          errors={safeErrors.employerName}
          touched={safeTouched.employerName}
          label="Nom de l’entreprise"
          placeholder="Nom de l’entreprise"
          value={safeValues.employerName}
          name={`revenus.${index}.employerName`}
        />
      </div>
      <div
        className={hideActivityDateByEmployerType(safeValues.employmentType)}
      >
        <ArtemisKeyboardDatePicker
          name={`revenus.${index}.startDate`}
          label="Date de début d’activité"
          value={safeValues.startDate}
          setFieldValue={setFieldValue}
        />
      </div>
      <div
        className={hideRetirementDateByEmployerType(safeValues.employmentType)}
      >
        <ArtemisKeyboardDatePicker
          name={`revenus.${index}.startDate`}
          label="Date de départ à la retraite"
          value={safeValues.startDate}
          setFieldValue={setFieldValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={safeErrors.netMonthlyIncome}
          touched={safeTouched.netMonthlyIncome}
          label="Revenus professionnels mensuels nets avant impôt"
          value={safeValues.netMonthlyIncome}
          name={`revenus.${index}.netMonthlyIncome`}
          trailIconName="euro"
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={safeErrors.monthlyLandIncome}
          touched={safeTouched.monthlyLandIncome}
          label="Revenu foncier mensuel"
          value={safeValues.monthlyLandIncome}
          name={`revenus.${index}.monthlyLandIncome`}
          trailIconName="euro"
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={safeErrors.pension}
          touched={safeTouched.pension}
          label="Pension"
          value={safeValues.pension}
          name={`revenus.${index}.pension`}
          trailIconName="euro"
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={safeErrors.dividends}
          touched={safeTouched.dividends}
          label="Dividendes"
          value={safeValues.dividends}
          name={`revenus.${index}.dividends`}
          trailIconName="euro"
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={safeErrors.taxIncomeN2}
          touched={safeTouched.taxIncomeN2}
          label="Revenu fiscal de référence N-2"
          value={safeValues.taxIncomeN2}
          name={`revenus.${index}.taxIncomeN2`}
          trailIconName="euro"
        />
      </div>
    </div>
  );
};

export default RevenusForm;
