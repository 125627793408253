import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { StepButton, useMediaQuery, Drawer, Tooltip } from "@material-ui/core";
import GeneralText from "./DrawerText/GeneralText";

const texts = [
  {
    imageName: "text1Image",
    headline: "Les étapes pour constituer votre dossier",
    body: (
      <>
        Lorsque vous signez un compromis de vente ou un contrat de réservation,
        c’est un véritable compte à rebours qui commence pour respecter les
        échéances. Il faut donc constituer votre dossier rapidement afin de ne
        pas perdre de temps dans vos démarches.
        <br />
        <br />
        La première étape de votre dossier consiste à fournir à votre expert
        Artémis courtage <strong>tous les justificatifs</strong> concernant
        votre situation d’emprunteur et votre projet. Vous validerez ensuite
        ensemble votre <strong>plan de financement</strong> dans le but de
        signer votre <strong>mandat de recherche de prêt</strong> pour que votre
        courtier Artémis courtage entame les négociations avec les banques.
      </>
    ),
    proAdvice: (
      <>
        Pour présenter votre dossier aux banques et obtenir les meilleures
        conditions de prêt, il est indispensable d’avoir signé le Mandat de
        recherche d’Artémis courtage et de joindre l’ensemble des justificatifs.
      </>
    ),
  },
  {
    imageName: "text2Image",
    headline: "Négocier le prêt qui vous correspond le mieux !",
    body: (
      <>
        Votre expert Artémis courtage oriente votre dossier vers les banques qui
        proposent les meilleures conditions d’emprunt en fonction de votre
        profil d’emprunteur. Les meilleures conditions ne correspondent pas
        seulement au meilleur taux mais également à la négociation de{" "}
        <strong>l’assurance décès invalidité</strong>, de la garantie ou des
        souplesses de fonctionnement du prêt. Votre prêt immobilier sera
        entièrement <strong>sur-mesure</strong> et votre{" "}
        <strong>accompagnement personnalisé</strong>.
      </>
    ),
    proAdvice: (
      <>
        Inutile de démarcher vous-même votre propre banque ou tout autre
        établissement bancaire. Artémis courtage le fait pour vous. Votre
        courtier bénéficie de <strong>taux préférentiels</strong> auprès de
        toutes les banques du marché, y compris la vôtre. Pendant que votre
        courtier dédié travaille pour vous, vous gardez l’esprit libre pour le
        reste, comme la décoration de votre futur chez vous !
      </>
    ),
  },
  {
    imageName: "text3Image",
    headline: "Valider le meilleur accord bancaire",
    body: (
      <>
        Prenons rendez-vous ensemble <strong>en agence ou à distance</strong>{" "}
        pour relire attentivement les accords obtenus des banques et retenir la
        meilleure proposition. Nous validons ainsi l’accord choisi au travers de
        la signature de <strong>la Fiche Conseil</strong> et nous pourrons
        confirmer cet accord auprès des parties intéressées (notaire, vendeur,
        agent immobilier). Si l’assurance choisie n’est pas celle proposée par
        la banque, c’est à ce rendez-vous que votre expert Artémis courtage vous
        accompagnera dans le processus de souscription afin d’obtenir au plus
        vite votre certificat d’adhésion.
      </>
    ),
    proAdvice: (
      <>
        Le meilleur taux n’est pas forcément le moins cher, mais celui qui vous
        correspond le mieux, dans les moindres détails.
      </>
    ),
  },
  {
    imageName: "text4Image",
    headline: "Qu’est-ce qu’une offre de prêt ?",
    body: (
      <>
        L’offre de prêt est le document contractuel qui formalise l’accord
        définitif de la banque et dans laquelle vous retrouverez :
        <ul className="list-disc list-inside">
          <li>Les conditions particulières négociées pour votre crédit</li>

          <li>Les conditions générales du(des) prêt(s)</li>

          <li>Le tableau d’amortissement</li>

          <li>
            Les conditions particulières et générales de l’assurance si celle de
            la banque a été retenue
          </li>

          <li>
            La Fiche Standardisée d’Information de l’assurance le cas échéant
          </li>
        </ul>
        Vous devrez accepter et signer l’offre de prêt selon les instructions
        indiquées par la banque après un{" "}
        <strong>délai minimum de 11 jours</strong> à partir de la date de
        réception.
      </>
    ),
    proAdvice: (
      <>
        Votre conseiller Artémis courtage, présent à chaque étape de votre
        dossier, relira avec vous votre offre de prêt dans ses moindres détails.
        Il vous indiquera quand et où signer ce contrat avant de le renvoyer par
        courrier. Contactez-le dès réception !
      </>
    ),
  },
  {
    imageName: "text5Image",
    headline: "Un projet de vie qui se concrétise",
    body: (
      <>
        La signature de l’acte d’achat chez le notaire est la dernière étape de
        votre acquisition. Votre conseiller Artémis courtage s’assurera que le
        notaire a bien émis l’appel des fonds à la banque pour{" "}
        <strong>débloquer votre crédit</strong> et à votre attention pour{" "}
        <strong>débloquer votre apport personnel</strong>. Vous n’aurez donc
        plus qu’à vous rendre au rendez-vous chez le notaire afin de{" "}
        <strong>signer l’acte authentique de propriété</strong>. C’est au terme
        de cette étape que nous vous adresserons la facture de nos frais à de
        courtage à régler.
        <br />
        <br />
        Félicitations, vous voilà propriétaire !
      </>
    ),
    proAdvice: (
      <>
        Votre courtier est votre <strong>partenaire financier</strong>. Il
        s’occupe de vous tout au long de votre crédit jusqu’à la signature chez
        le notaire mais aussi au-delà. En effet, si vous avez des questions sur
        votre crédit ou un nouveau projet immobilier, votre expert Artémis
        courtage est à votre disposition.
      </>
    ),
  },
];

const createTexts = (textIndex, functions) => {
  return <GeneralText {...functions} {...texts[textIndex - 1]} />;
};

const ArtemisStepConnector = withStyles({
  alternativeLabel: {
    top: 18,
    // left: "calc(-82% + 20px)",
    // right: "calc(82% + 20px)",
  },
  active: {
    "& $line": {
      backgroundColor: "#ffffff",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#A6B3E2",
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: "#ffffff",
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles({
  root: {
    // alignItems: "flex-start",
    // textAlign: "left",
  },
  // text: {
  //   fill: "#4860bd",
  //   color: "#4860bd",
  // },
  stepper: {
    backgroundColor: "#4860bd",
    width: "100%",
    padding: "20px 0px",
  },
  alternativeLabel: {
    color: "#fff",
    fontFamily: ["QuinoaSC-Regular", "sans-serif", "!important"],
  },
  label: {
    // textAlign: "left !important",
  },
  iconContainer: {
    borderRadius: "25px",
    border: "6px solid rgba(255,255,255,0.15)",
  },
  active: {
    color: "#A6B3E2",
    "&$stepIcon": {
      color: "#fff",
      "& $text": {
        fill: "#4860bd",
      },
    },
    "&$alternativeLabel": {
      color: "#fff",
      fontFamily: ["QuinoaSC-Bold", "sans-serif", "!important"],
    },
  },
  completed: {
    "&$stepIcon": {
      color: "#A6B3E2",
      "& $text": {
        fill: "#fff",
      },
    },
    "&$alternativeLabel": {
      color: "#fff",
      fontFamily: ["QuinoaSC-Regular", "sans-serif", "!important"],
    },
  },
  stepIcon: {
    color: "#A6B3E2",
    "& $text": {
      fill: "#4860bd",
    },
  },
  text: {},
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const Steps = ({ currentStep }) => {
  const classes = useStyles();
  //   const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    {
      label: "Constitution du dossier",
      text: "Les étapes pour constituer votre dossier",
    },
    {
      label: "Dossier transmis aux banques",
      text: "Négocier le prêt qui vous correspond le mieux !",
    },
    {
      label: "Dossier accordé par les banques",
      text: "Valider le meilleur accord bancaire",
    },
    {
      label: "Edition de votre offre de prêt",
      text: "Qu’est-ce qu’une offre de prêt ?",
    },
    {
      label: "Signature de l’acte authentique",
      text: "Un projet de vie qui se concrétise",
    },
  ];
  const [drawerText, setDrawerText] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };
  const handleDrawerOpen = (index) => {
    setDrawerText(createTexts(index + 1, { handleClose: handleCloseDrawer }));
    setIsDrawerOpen(true);
  };
  const handleOpen = (index) => () => {
    handleDrawerOpen(index);
  };
  const isMobile = useMediaQuery("(max-width:720px)");
  return (
    <>
      <Stepper
        alternativeLabel
        nonLinear
        className={classes.stepper}
        connector={<ArtemisStepConnector />}
      >
        {steps.map((step, index) => (
          <LightTooltip
            arrow
            placement="top"
            key={step.label}
            aria-label={step.text}
            title={step.text}
          >
            <Step
              completed={index < currentStep}
              active={index === currentStep}
            >
              <StepButton
                onClick={handleOpen(index)}
                style={{ padding: "24px 8px", margin: "-24px -10px" }}
                // style={index === steps.length - 1 ? { padding: "24px 14px" } : {}}
              >
                <StepLabel
                  classes={{
                    root: classes.root,
                    alternativeLabel: classes.alternativeLabel,
                    completed: classes.completed,
                    active: classes.active,
                    iconContainer: classes.iconContainer,
                    label: classes.label,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.stepIcon,
                      completed: classes.completed,
                      active: classes.active,
                      text: classes.text,
                    },
                  }}
                >
                  {!isMobile && step.label}
                </StepLabel>
              </StepButton>
            </Step>
          </LightTooltip>
        ))}
      </Stepper>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
        {drawerText}
      </Drawer>
    </>
  );
};
export default Steps;
