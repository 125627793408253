import React from "react";
import * as yup from "yup";
import { Formik, Form } from "formik";
// import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import ArtemisKeyboardDatePicker from "../Partials/KeyboardDatePicker/ArtemisKeyboardDatePicker";
import Modal from "../Partials/Modal/Modal";
import Input from "../Partials/Input/Input";
import RadioSelect from "../Partials/RadioSelect/RadioSelect";
// import Consent from "../Partials/Consent/Consent";
import crossIcon from "../../images/cross-icon.svg";
import "./GuestBesoin.scss";
import { setGuestBesoin, selectGuestBesoin } from "./guestSlice";
import { selectConstants } from "../Partials/constantsUtils";
import { mapProjectTypeToHumanReadable } from "../../utils";
import FormikPatchTouched from "../Partials/FormikPatchTouched";

const GuestBesoin = ({ hideModal, onNextClick, onPreviousClick, hasNext }) => {
  const dispatch = useDispatch();
  const constants = useSelector(selectConstants);
  const formData = useSelector(selectGuestBesoin);
  const besoinConstants = {
    goodType: "Type de bien",
    goodUsage: "Usage du bien",
    projectType: "Nature de votre projet",
    projectProgress: "Avancement du projet",
  };
  const radioOptions =
    Object.keys(constants).length !== 0
      ? Object.keys(besoinConstants).map((k) => ({
          heading: `${besoinConstants[k]}*`,
          name: k,
          radios: Object.keys(constants[k]).map((c) => ({
            label: constants[k][c],
            value: c,
            icon: c,
          })),
        }))
      : [];
  const handleSubmitForm = (values) => {
    dispatch(setGuestBesoin(values));
    onNextClick();
  };

  const renderError = (errors, touched) => {
    let message = "";
    if (touched && errors) {
      Object.keys(errors).forEach((text) => {
        if (!!errors[text] && !!touched[text]) {
          message = errors[text];
        }
      });
    }
    return message;
  };

  const showError = (error, touched) => {
    return classNames(
      {
        "border-l-2 border-solid border-red-600 custom-red-background":
          error && touched,
      },
      "-mx-4 sm:-mx-8"
    );
  };
  const { projectTypeAmountFields } = constants;

  // console.log(formData);

  return (
    <Formik
      initialValues={{
        projectType: "",
        goodType: "",
        goodUsage: "",
        projectProgress: "",
        purchaseAmount: "",
        plannedContribution: "",
        privacyPolicy: false,
        deedOfSaleDate: null,
        ...formData,
      }}
      enableReinitialize
      validationSchema={yup.object().shape({
        projectType: yup
          .string()
          .required(
            "Veuillez répondre à toutes les questions avant de poursuivre"
          ),
        deedOfSaleDate: yup.string().when("projectProgress", {
          is: (val) => val === "SALE_AGREEMENT_SIGNED",
          then: yup
            .string()
            .required(
              "Veuillez répondre à toutes les questions avant de poursuivre"
            ),
          otherwise: yup.string().nullable(),
        }),
        goodType: yup
          .string()
          .required(
            "Veuillez répondre à toutes les questions avant de poursuivre"
          ),
        goodUsage: yup
          .string()
          .required(
            "Veuillez répondre à toutes les questions avant de poursuivre"
          ),
        projectProgress: yup.string().when("projectType", {
          is: (val) =>
            val !== "LAND_ONLY" &&
            val !== "LAND_AND_CONSTRUCTION" &&
            val !== "NEW_TURNKEY" &&
            val !== "NEW" &&
            val !== "OLD" &&
            val !== "OLD_WITHOUT_WORK" &&
            val !== "PURCHASE_OF_SCPI",
          then: yup.string(),
          otherwise: yup
            .string()
            .required(
              "Veuillez répondre à toutes les questions avant de poursuivre"
            ),
        }),
        plannedContribution: yup
          .number()
          .required(
            "Veuillez répondre à toutes les questions avant de poursuivre"
          ),
        propertyRenegotiationAmount: yup.number().when("projectType", {
          is: "CREDIT_REPURCHASE",
          then: yup
            .number()
            .required(
              "Veuillez répondre à toutes les questions avant de poursuivre"
            ),
          otherwise: yup.number(),
        }),
        balanceAmount: yup.number().when("projectType", {
          is: (val) =>
            val === "BALANCE_PAYMENT" || val === "BALANCE_PAYMENT_AND_WORK",
          then: yup
            .number()
            .required(
              "Veuillez répondre à toutes les questions avant de poursuivre"
            ),
          otherwise: yup.number(),
        }),
        amountOfWork: yup.number().when("projectType", {
          is: (val) =>
            val === "BALANCE_PAYMENT_AND_WORK" ||
            val === "CREDIT_REPURCHASE" ||
            val === "EXTENSION_WORK" ||
            val === "IMPROVEMENT_WORK" ||
            val === "OLD" ||
            val === "OTHER_WORK" ||
            val === "RENOVATION_WORK",
          then: yup
            .number()
            .required(
              "Veuillez répondre à toutes les questions avant de poursuivre"
            ),
          otherwise: yup.number(),
        }),
        purchaseAmount: yup.number().when("projectType", {
          is: (val) =>
            val === "CONSTRUCTION" ||
            val === "LAND_AND_CONSTRUCTION" ||
            val === "NEW" ||
            val === "NEW_TURNKEY" ||
            val === "OLD" ||
            val === "OLD_WITHOUT_WORK",
          then: yup
            .number()
            .required(
              "Veuillez répondre à toutes les questions avant de poursuivre"
            ),
          otherwise: yup.number(),
        }),
        landPrice: yup.number().when("projectType", {
          is: (val) => val === "LAND_AND_CONSTRUCTION" || val === "LAND_ONLY",
          then: yup
            .number()
            .required(
              "Veuillez répondre à toutes les questions avant de poursuivre"
            ),
          otherwise: yup.number(),
        }),
        sharesAmount: yup.number().when("projectType", {
          is: (val) => val === "PURCHASE_OF_SCPI",
          then: yup
            .number()
            .required(
              "Veuillez répondre à toutes les questions avant de poursuivre"
            ),
          otherwise: yup.number(),
        }),
      })}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => {
        let filteredRadioOptions = [];
        if (
          values.projectType !== "LAND_ONLY" &&
          values.projectType !== "LAND_AND_CONSTRUCTION" &&
          values.projectType !== "NEW_TURNKEY" &&
          values.projectType !== "NEW" &&
          values.projectType !== "OLD" &&
          values.projectType !== "OLD_WITHOUT_WORK" &&
          values.projectType !== "PURCHASE_OF_SCPI"
        ) {
          filteredRadioOptions = radioOptions.filter(
            (opt) => opt.name !== "projectProgress"
          );
        } else {
          filteredRadioOptions = radioOptions;
        }
        // console.log(filteredRadioOptions);
        // console.log(radioOptions);
        const errorMessage = renderError(errors, touched);
        return (
          <Form>
            <FormikPatchTouched />
            <Modal
              title="Mon Projet"
              hideModal={hideModal}
              submitForm={handleSubmit}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
              hasNext={hasNext}
              isGuest
            >
              <div className="mt-10">
                {filteredRadioOptions.map((opt, index) => (
                  <div
                    key={index}
                    className={showError(errors[opt.name], touched[opt.name])}
                  >
                    <div className="border-b-2 border-white border-solid">
                      <div className="px-8 py-4">
                        <RadioSelect
                          key={index}
                          hasDropdown={opt.name === "projectType"}
                          handleChange={handleChange}
                          onChange={setFieldValue}
                          value={values[opt.name]}
                          name={opt.name}
                          options={opt.radios}
                          heading={opt.heading}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {values.projectProgress === "SALE_AGREEMENT_SIGNED" && (
                  <div className="-mx-4 sm:-mx-8">
                    <div className="px-8 py-4">
                      <div className="flex flex-col py-4 md:flex-row xs:items-left md:items-center">
                        <div className="font-qBold text-headline md:w-2/12 lg:w-3/12">
                          Acte prévu le*
                        </div>
                        <div className="flex flex-col items-start mr-0 sm:ml-12 md:ml-10 lg:ml-24 xs:w-full sm:w-10/12 lg:w-9/12">
                          <ArtemisKeyboardDatePicker
                            name="deedOfSaleDate"
                            error={errors.deedOfSaleDate}
                            touched={touched.deedOfSaleDate}
                            value={values.deedOfSaleDate}
                            setFieldValue={setFieldValue}
                            noError
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {projectTypeAmountFields &&
                  projectTypeAmountFields[values.projectType] && (
                    <div className="-mx-4 sm:-mx-8">
                      <div className="border-b-2 border-white border-solid">
                        <div className="px-8 py-4">
                          {projectTypeAmountFields[values.projectType].map(
                            (fieldName) => (
                              <div
                                className="flex flex-col py-4 md:flex-row xs:items-left md:items-center"
                                key={fieldName}
                              >
                                <div className="font-qBold text-headline md:w-2/12 lg:w-3/12">
                                  {
                                    mapProjectTypeToHumanReadable[
                                      values.projectType
                                    ][fieldName]
                                  }
                                  *
                                </div>
                                <div className="flex flex-col items-start mr-0 sm:ml-12 md:ml-10 lg:ml-24 xs:w-full sm:w-10/12 lg:w-9/12">
                                  <Input
                                    type="number"
                                    noFullWidth
                                    noError
                                    value={values[fieldName]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors[fieldName]}
                                    touched={touched[fieldName]}
                                    name={fieldName}
                                    placeholder={
                                      mapProjectTypeToHumanReadable[
                                        values.projectType
                                      ][fieldName]
                                    }
                                    trailIconName="euro"
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                <div className="-mx-4 sm:-mx-8">
                  <div className="border-b-2 border-white border-solid">
                    <div className="px-8 py-4">
                      <div className="flex flex-col py-4 md:flex-row xs:items-left md:items-center">
                        <div className="font-qBold text-headline md:w-2/12 lg:w-3/12">
                          Apport disponible*
                        </div>
                        <div className="flex flex-col items-start mr-0 sm:ml-12 md:ml-10 lg:ml-24 xs:w-full sm:w-10/12 lg:w-9/12">
                          <Input
                            type="number"
                            noFullWidth
                            noError
                            value={values.plannedContribution}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.plannedContribution}
                            touched={touched.plannedContribution}
                            name="plannedContribution"
                            placeholder="Apport disponible"
                            trailIconName="euro"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="flex flex-col py-4 md:flex-row xs:items-left md:items-center">
                  <div className="font-qBold text-headline md:w-2/12 lg:w-3/12">
                    {values.projectType === "CREDIT_REPURCHASE"
                      ? "Montant à renégocier*"
                      : "Montant d'acquisition*"}
                  </div>
                  <div className="flex flex-col mr-0 md:flex-row xs:items-left md:items-center sm:ml-16 md:ml-10 lg:ml-24 md:w-10/12 lg:w-9/12">
                    <Input
                      type="number"
                      min={0}
                      name="purchaseAmount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.purchaseAmount}
                      noFullWidth
                      trailIconName="euro"
                      placeholder="Montant"
                      noError
                      error={errors.purchaseAmount}
                      touched={touched.purchaseAmount}
                    />
                  </div>
                </div> */}
                {/* <Consent
                  name="privacyPolicy"
                  checked={values.privacyPolicy}
                  onChange={() =>
                    setFieldValue("privacyPolicy", !values.privacyPolicy)
                  }
                  notInline="false"
                /> */}
                <div>
                  <p className="mt-8 auth-subheading">* Champs obligatoires</p>
                </div>
                {!!errorMessage && (
                  <div className="flex flex-col pt-2 pb-4 md:flex-row xs:items-left md:items-center">
                    <img src={crossIcon} alt="cross-icon" />
                    <div className="text-red-600 font-qBold text-small">
                      {errorMessage}
                    </div>
                  </div>
                )}
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GuestBesoin;
