import request from "superagent";
import { serverUrl } from "../../utils";

export const selectTaxes = (state) => state.guest.taxes;
export const selectGuestBesoin = (state) => state.guest.besoin;
export const selectGuestCoordonnees = (state) => state.guest.coordonnees;
export const selectGuestSituation = (state) => state.guest.additionalInfo;
export const selectCalendlyUrl = (state) => state.guest.calendlyUrl;
export const selectPartnership = (state) => state.guest.partnership;

export const guestAPI = {
  accountCreate: ({
    guestNeed,
    guestInfo,
    guestAdditionalInfo,
    partnership,
  }) => {
    return request
      .post(`${serverUrl}/projects/guest/signup`)
      .send({ guestNeed, guestInfo, guestAdditionalInfo, partnership });
  },
  taxesGet: ({ guestNeed, guestInfo, guestAdditionalInfo, partnership }) => {
    return request
      .post(`${serverUrl}/projects/guest`)
      .send({ guestNeed, guestInfo, guestAdditionalInfo, partnership });
  },
};
