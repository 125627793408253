import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import Input from "../../Partials/Input/Input";
import Select from "../../Partials/Select/Select";
import "./Coordonees.scss";
import { selectConstants } from "../../Partials/constantsUtils";

const CoordoneesForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  index,
  handleRemove,
  // showBorder,
  isGuest,
  isAllRequired,
  showSameAddressButton,
  setFieldValue,
  mainValues,
}) => {
  const { title } = useSelector(selectConstants);

  const safeErrors = errors || {};
  const safeTouched = touched || {};
  const safeValues = {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    postalCode: "",
    email: "",
    phoneNumber: "",
    monthlyIncome: "",
    title: "",
    sameAddress: false,
    ...values,
  };
  const containerClass = classNames(
    "grid grid-cols-1 row-gap-5 col-gap-6 sm:grid-cols-9 relative"
  );
  const handleSameAddressClick = (e, checked) => {
    if (checked) {
      setFieldValue(`owners.${index}.city`, mainValues.city);
      setFieldValue(`owners.${index}.postalCode`, mainValues.postalCode);
      setFieldValue(`owners.${index}.address`, mainValues.address);
    }
    setFieldValue(`owners.${index}.sameAddress`, checked);
  };
  const resetAddressIfChecked = () => {
    if (safeValues.sameAddress) {
      setFieldValue(`owners.${index}.sameAddress`, false);
    }
  };
  return (
    <div className={containerClass}>
      {handleRemove && (
        <div
          className="absolute cursor-pointer"
          style={{ right: "5px", top: "-25px" }}
          onClick={handleRemove}
        >
          <DeleteIcon className="icon-red" />
        </div>
      )}
      <div className="sm:block sm:col-span-3">
        <Select
          isRequired
          label="Civilité"
          value={safeValues.title || ""}
          name={`owners.${index}.title`}
          error={safeErrors.title}
          touched={safeTouched.title}
          onChange={handleChange}
          // noError={false}
          trailIconName="arrowUpDown"
          options={Object.keys(title).map((key) => ({
            label: title[key],
            value: key,
          }))}
        />
      </div>
      <div className="sm:col-span-3">
        <Input
          isRequired={isAllRequired || isGuest}
          value={safeValues.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={safeErrors.lastName}
          touched={safeTouched.lastName}
          name={`owners.${index}.lastName`}
          label="Nom"
          placeholder="Nom"
          leadingIconName="user"
        />
      </div>
      <div className="sm:col-span-3">
        <Input
          isRequired={isAllRequired || isGuest}
          value={safeValues.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={safeErrors.firstName}
          touched={safeTouched.firstName}
          name={`owners.${index}.firstName`}
          label="Prénom"
          placeholder="Prénom"
          leadingIconName="user"
        />
      </div>
      {showSameAddressButton && (
        <>
          <div className="sm:col-span-9">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleSameAddressClick}
                  name={`owners.${index}.sameAddress`}
                  checked={safeValues.sameAddress}
                />
              }
              label="Mon co-emprunteur a la même adresse"
            />
            <div style={{ minHeight: "2em" }} />
          </div>
          {/* <div className="hidden sm:block sm:col-span-6" /> */}
        </>
      )}
      <div className="sm:col-span-3">
        <Input
          isRequired={isAllRequired || isGuest}
          value={safeValues.address}
          onChange={(e) => {
            handleChange(e);
            resetAddressIfChecked();
          }}
          onBlur={handleBlur}
          error={safeErrors.address}
          touched={safeTouched.address}
          name={`owners.${index}.address`}
          label="Adresse"
          placeholder="Adresse"
          leadingIconName="home"
        />
      </div>
      <div className="sm:col-span-3">
        <Input
          isRequired={isAllRequired || isGuest}
          value={safeValues.city}
          onChange={(e) => {
            handleChange(e);
            resetAddressIfChecked();
          }}
          onBlur={handleBlur}
          error={safeErrors.city}
          touched={safeTouched.city}
          name={`owners.${index}.city`}
          label="Ville"
          placeholder="Ville"
          leadingIconName="home"
        />
      </div>
      <div className="sm:col-span-3">
        <Input
          isRequired={isAllRequired || isGuest}
          value={safeValues.postalCode}
          onChange={(e) => {
            handleChange(e);
            resetAddressIfChecked();
          }}
          onBlur={handleBlur}
          error={safeErrors.postalCode}
          touched={safeTouched.postalCode}
          name={`owners.${index}.postalCode`}
          label="Code postal"
          placeholder="Code postal"
          leadingIconName="home"
        />
      </div>
      <div className="sm:col-span-3">
        <Input
          isRequired={isAllRequired || isGuest}
          value={safeValues.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={safeErrors.email}
          touched={safeTouched.email}
          name={`owners.${index}.email`}
          label="Email"
          placeholder="Email"
          leadingIconName="airplane"
        />
      </div>
      <div className="sm:col-span-3">
        <Input
          isRequired={isAllRequired || isGuest}
          type="phoneNumber"
          value={safeValues.phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={safeErrors.phoneNumber}
          touched={safeTouched.phoneNumber}
          name={`owners.${index}.phoneNumber`}
          label="Téléphone"
          placeholder="Téléphone"
          leadingIconName="mobile"
        />
      </div>
      <div className="sm:col-span-3" />
      <div className="sm:col-span-3">
        <Input
          isRequired={isAllRequired || isGuest}
          value={safeValues.monthlyIncome}
          onChange={handleChange}
          onBlur={handleBlur}
          error={safeErrors.monthlyIncome}
          touched={safeTouched.monthlyIncome}
          name={`owners.${index}.monthlyIncome`}
          label="Revenu mensuel"
          helperText="(salaire, pension, dividendes…)"
          trailIconName="euro"
          type="number"
        />
      </div>
      {/* <div className="sm:col-span-3" />
      <div className="sm:col-span-3 flex justify-center items-center">
        {showSameAddressButton && (
          <Button onClick={handleSameAddressClick} fullWidth variant="primary">
            Ajouter la même adresse
          </Button>
        )}
      </div> */}
    </div>
  );
};

export default CoordoneesForm;
