import request from "superagent";
import { serverUrl } from "../../utils";

export const selectDocuments = (state) => state.dossier.documents;
export const selectPopupStatus = (state) => state.dossier.popups;
export const selectDossierStatus = (state) => state.dossier.dossierStatus;
export const selectUploadingDocumentId = (state) =>
  state.dossier.uploadingDocumentId;

export const dossierAPI = {
  getProjectStatus: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/status`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  getPopupStatus: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/step/status`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  getDocuments: ({ jwt }) => {
    return request
      .get(`${serverUrl}/documents/supporting-documents`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  removeDocument: ({ jwt, documentId }) => {
    return request
      .delete(`${serverUrl}/documents/supporting-documents/${documentId}`)
      .set("Authorization", `Bearer ${jwt}`);
  },
  uploadDocument: ({ jwt, form }) => {
    return request
      .post(`${serverUrl}/documents/upload`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(form);
  },
};
