import React from "react";
import classNames from "classnames";
import DeleteIcon from "@material-ui/icons/Delete";
import BooleanRadioSelect from "../../Partials/BooleanRadioSelect/BooleanRadioSelect";
import Select from "../../Partials/Select/Select";
import Input from "../../Partials/Input/Input";
import ArtemisKeyboardDatePicker from "../../Partials/KeyboardDatePicker/ArtemisKeyboardDatePicker";

export default function SituationForm({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  index,
  handleRemove,
  setFieldValue,
  constants,
}) {
  const safeErrors = errors || {};
  const safeTouched = touched || {};
  const safeValues = values || {
    familySituation: "",
    dateOfBirth: null,
    placeOfBirth: "",
    numberOfDependents: 0,
    currentAccommodation: "",
    amount: 0,
    entryDate: null,
    isFirstTimeBuyer: "",
    ...values,
  };
  const displayAmountByLangement = (value) => {
    return classNames(
      {
        hidden:
          value === "OWNER" || value === "" || value === "HOST_FREE_OF_CHARGE",
      },
      "col-span-6 sm:col-span-2"
    );
  };

  const displayEntryDateByLangement = (value) => {
    return classNames(
      {
        hidden: value === "OWNER" || value === "",
      },
      "col-span-6 sm:col-span-2"
    );
  };
  const booleanOptions = {
    label: "Êtes-vous Primo-accédant* de votre résidence principale ?",
  };
  return (
    <div className="relative">
      {handleRemove && (
        <div
          className="absolute cursor-pointer"
          style={{ right: "5px", top: "-25px" }}
          onClick={handleRemove}
        >
          <DeleteIcon className="icon-red" />
        </div>
      )}
      <div className="grid-cols-6 grid col-gap-8 flex items-end">
        <div className="col-span-6 sm:col-span-2">
          <ArtemisKeyboardDatePicker
            name={`owners.${index}.dateOfBirth`}
            label="Date de naissance"
            value={safeValues.dateOfBirth}
            error={safeErrors.dateOfBirth}
            touched={safeTouched.dateOfBirth}
            setFieldValue={setFieldValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            leadingIconName="user"
            onChange={handleChange}
            onBlur={handleBlur}
            error={safeErrors.placeOfBirth}
            touched={safeTouched.placeOfBirth}
            label="Lieu de naissance"
            placeholder="Lieu de naissance"
            value={safeValues.placeOfBirth}
            name={`owners.${index}.placeOfBirth`}
          />
        </div>
        <div className="hidden sm:block sm:col-span-2" />
        <div className="col-span-6 sm:col-span-2">
          <Select
            leadingIconName="user"
            label="Situation familiale"
            value={safeValues.familySituation}
            name={`owners.${index}.familySituation`}
            onChange={handleChange}
            trailIconName="arrowUpDown"
            touched={safeTouched.familySituation}
            error={safeErrors.familySituation}
            options={Object.keys(constants.familySituation).map((k) => ({
              value: k,
              label: constants.familySituation[k],
            }))}
          />
        </div>
        <div className="col-span-6 sm:col-span-2 relative">
          <Input
            type="number"
            min={0}
            max={5}
            onBlur={handleBlur}
            error={safeErrors.numberOfDependents}
            touched={safeTouched.numberOfDependents}
            onChange={handleChange}
            label="Nombre de personnes à charge"
            name={`owners.${index}.numberOfDependents`}
            value={safeValues.numberOfDependents}
            placeholder="0"
            step={1}
            leadingIconName="user"
            hasTrailButton
            hasLabelIcon
            labelIconText="toutes les personnes qui habitent la même résidence principale que l(es)’emprunteur(s) : enfants, parents, proches"
          />
        </div>
        <div className="hidden sm:block sm:col-span-2" />
        <div className="col-span-6 sm:col-span-2">
          <Select
            leadingIconName="home"
            label="Logement actuel"
            value={safeValues.currentAccommodation}
            error={safeErrors.currentAccommodation}
            touched={safeTouched.currentAccommodation}
            name={`owners.${index}.currentAccommodation`}
            onChange={handleChange}
            trailIconName="arrowUpDown"
            options={[
              {
                value: "OWNER",
                label: constants.currentAccommodation.OWNER,
              },
              {
                value: "TENANT",
                label: constants.currentAccommodation.TENANT,
              },
              {
                value: "HOST_FREE_OF_CHARGE",
                label: constants.currentAccommodation.HOST_FREE_OF_CHARGE,
              },
            ]}
          />
        </div>
        <div
          className={displayAmountByLangement(safeValues.currentAccommodation)}
        >
          <Input
            type="number"
            min={0}
            onBlur={handleBlur}
            error={safeErrors.amount}
            touched={safeTouched.amount}
            onChange={handleChange}
            label="Montant du loyer"
            name={`owners.${index}.amount`}
            value={safeValues.amount}
            placeholder="0"
            trailIconName="euro"
          />
        </div>
        <div
          className={displayEntryDateByLangement(
            safeValues.currentAccommodation
          )}
        >
          <ArtemisKeyboardDatePicker
            name={`owners.${index}.entryDate`}
            label="Date d'entrée"
            value={safeValues.entryDate}
            error={safeErrors.entryDate}
            touched={safeTouched.entryDate}
            setFieldValue={setFieldValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2" />
      </div>
      <div className="grid-cols-6 grid col-gap-8 mt-2">
        <BooleanRadioSelect
          index={index}
          name={`owners.${index}.isFirstTimeBuyer`}
          value={safeValues.isFirstTimeBuyer}
          onChange={setFieldValue}
          label={booleanOptions.label}
          error={safeErrors.error}
          touched={safeTouched.touched}
        />
      </div>
    </div>
  );
}
