import request from "superagent";
import { serverUrl } from "../../utils";

export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectUser = (state) => state.auth.user;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectReturnUrl = (state) => state.auth.returnUrl;
export const selectDossierCompletion = (state) => state.auth.dossierCompletion;
export const selectBroker = (state) => state.auth.broker;

export const authenticationAPI = {
  updatePrivacy: ({ accessToken }) => {
    return request
      .put(`${serverUrl}/users/me/accept-privacy-terms`)
      .set("Authorization", `Bearer ${accessToken}`)
      .send({
        privacyTermsAccepted: 1,
      });
  },
  brokerGet: ({ accessToken }) => {
    return request
      .get(`${serverUrl}/projects/broker`)
      .set("Authorization", `Bearer ${accessToken}`);
  },
  getUserInformation: ({ accessToken }) => {
    return request
      .get(`${serverUrl}/users/me`)
      .set("Authorization", `Bearer ${accessToken}`);
  },
  getDossierCompletionInformation: ({ accessToken }) => {
    return request
      .get(`${serverUrl}/projects/completion`)
      .set("Authorization", `Bearer ${accessToken}`);
  },
  register: (registerFormValues) => {
    return request.post(`${serverUrl}/auth/signup`).send(registerFormValues);
  },
  login: ({ email, token }) => {
    return request.post(`${serverUrl}/auth/login`).send({ email, token });
  },
  sendMagicLink: ({ email }) => {
    return request.post(`${serverUrl}/auth/send-magic-link`).send({ email });
  },
  refreshToken: ({ refreshToken }) => {
    return request.post(`${serverUrl}/auth/refresh-access-token`).send({
      token: refreshToken,
    });
  },
  logout: ({ accessToken, refreshToken }) => {
    return request
      .post(`${serverUrl}/auth/invalidate-refresh-token`)
      .set("Authorization", `Bearer ${accessToken}`)
      .send({
        token: refreshToken,
      });
  },
  editUserInformation: ({ values, accessToken }) => {
    return request
      .put(`${serverUrl}/users/me`)
      .set("Authorization", `Bearer ${accessToken}`)
      .send(values);
  },
  deleteUser: ({ accessToken }) => {
    return request
      .delete(`${serverUrl}/users/me`)
      .set("Authorization", `Bearer ${accessToken}`);
  },
};
