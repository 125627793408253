import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import LargeCard from "../Partials/LargeCard/LargeCard";
import Chat from "../Chat/Chat";
import Completion from "../Completion/Completion";

import Steps from "../ProgressBar/Steps";
import podium from "../../images/podium.svg";

import "./Dashboard.scss";
// import Background from "../Partials/Background/Background";
// import BackgroundStyling from "../Partials/Background/BackgroundStyling";
import {
  selectUser,
  getDossierCompletionInformation,
  selectDossierCompletion,
} from "../Authentication/authenticationSlice";
import { getDocuments, selectDocuments } from "../Documents/documentsSlice";
import { selectDossierStatus, getDossierStatus } from "../Dossier/dossierSlice";

const dossierArray = [
  {
    imageName: "man",
    text: "Complétez vos informations",
    arrow: "arrowRight",
    linkTo: "/mon-dossier",
    percentageName: "myInformation",
  },
  {
    imageName: "clip",
    text: "Déposez vos justificatifs",
    arrow: "arrowRight",
    linkTo: "/mon-dossier#justificatifs",
    percentageName: "mySupportingDocuments",
  },
  {
    imageName: "clip",
    text: "Vos documents",
    arrow: "arrowRight",
    linkTo: "/mes-documents",
    percentageName: "mySigningDocuments",
  },
];

const Dashboard = (props) => {
  const user = useSelector(selectUser);
  const status = useSelector(selectDossierStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDossierCompletionInformation());
    dispatch(getDossierStatus());
    dispatch(getDocuments());
  }, [dispatch]);

  const documents = useSelector(selectDocuments);
  const showSignSection = documents.filter((v) => v.signingLink !== null);
  const dossierCompletion = useSelector(selectDossierCompletion);
  const isFullyCompleted =
    dossierCompletion.mySigningDocuments +
      dossierCompletion.mySupportingDocuments +
      dossierCompletion.myInformation ===
    300;
  return (
    // <Background>
    <div className="min-h-full mx-auto dashboard">
      {/* <div
          className="absolute z-10"
          style={{ height: "306px", top: "76px", left: 0, right: 0 }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor: "#4860bd",
              height: "100%",
            }}
            className="absolute"
          />
          <div
            className="relative flex max-w-screen-lg mx-auto banner-section"
            style={{
              backgroundColor: "#4860bd",
              height: "100%",
            }}
          />
        </div> */}
      <div className="relative">
        <div
          style={{
            width: "50%",
            backgroundColor: "#4860bd",
            height: "100%",
          }}
          className="absolute z-0"
        />
        {/* <div
            className="z-30 flex max-w-screen-lg mx-auto banner-section"
            style={{ backgroundColor: "#4860bd" }}
          >
            <div className="z-30 xs:w-full lg:w-3/4">
              <h1>Bonjour {user.firstName || ""}</h1>
              <p className="banner-information">
                Vous trouverez ci-dessous l’état d’avancement de votre demande
                de crédit immobilier.
              </p>
              <p className="banner-information above-steps">
                Nous vous tenons toujours informé dès qu’une étape est passée !
              </p>
              <Steps />
              <p className="status-header">Étape en cours</p>
              <p className="status-text">Dossier transmis aux banques</p>
            </div>
            <div className="xs:invisible lg:w-1/4 banner-right">
              <img src={podium} alt="talking-people" />
            </div>
        </div> */}
        <div
          className="relative z-30 flex max-w-screen-lg mx-auto banner-section"
          style={{ backgroundColor: "#4860bd" }}
        >
          <div className="xs:w-full lg:w-3/4">
            <h1>Bonjour {user.firstName || ""}</h1>
            <p className="banner-information">
              Vous trouverez ci-dessous l’état d’avancement de votre demande de
              crédit immobilier.
            </p>
            <p className="banner-information above-steps">
              Nous vous tenons toujours informé dès qu’une étape est passée !
            </p>
            <Steps currentStep={status.id - 1} />
            {/* <p className="status-header">Étape en cours</p>
            <p className="status-text">Dossier transmis aux banques</p> */}
          </div>
          <div className="xs:invisible lg:w-1/4 banner-right">
            <img src={podium} alt="talking-people" />
          </div>
        </div>
      </div>
      <div className="relative z-30 flex max-w-screen-lg mx-auto cards-section">
        <div className="xs:w-full lg:w-1/2">
          <h2 className="cards-header">
            Constitution de votre dossier de prêt
          </h2>
          <Completion
            percentage={dossierCompletion.myInformation || 0}
            text={
              isFullyCompleted
                ? "Votre dossier est finalisé !"
                : "Votre demande de prêt n’est pas finalisée !"
            }
          />
          {dossierArray.map((doc, index) => (
            <div className="mb-4" key={index}>
              <Link to={doc.linkTo}>
                <LargeCard
                  hasPercentage
                  percentage={dossierCompletion[doc.percentageName] || 0}
                  imageName={doc.imageName}
                  text={doc.text}
                  arrow={doc.arrow}
                />
              </Link>
            </div>
          ))}
          {showSignSection.length > 0 && (
            <Link to="/mes-documents">
              <LargeCard
                imageName="crayon"
                text="Signez vos documents"
                arrow="arrowRight"
              />
            </Link>
          )}
        </div>
        <div className="xs:w-full lg:w-1/2" />
      </div>
      <Chat />
      {/* <BackgroundStyling /> */}
    </div>
    // </Background>
  );
};
export default Dashboard;
