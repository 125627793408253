import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../Partials/Modal/Modal";
import {
  getDynamicConstants,
  selectConstants,
  selectDynamicConstants,
} from "../../Partials/constantsSlice";
import {
  sendMesCharges,
  selectMesCharges,
  getMesCharges,
} from "../../Dossier/modalsSlice";
import BigCardInModal from "../BigCardInModal/BigCardInModal";
import MesChargesForm from "./MesChargesForm";

export default function MesCharges({
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
}) {
  const dispatch = useDispatch();
  const constants = useSelector(selectConstants);
  const dyanmicConstants = useSelector(selectDynamicConstants);
  const handleSubmit = async (values) => {
    await dispatch(sendMesCharges(values));
    onNextClick();
  };
  const [openCoEmpIndex, setopenCoEmpIndex] = useState(-1);

  useEffect(() => {
    dispatch(getDynamicConstants());
    dispatch(getMesCharges());
  }, [dispatch]);
  const formData = useSelector(selectMesCharges);
  // console.log(formData);
  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={[...formData]}
        enableReinitialize
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Modal
                title="Mes charges"
                hideModal={hideModal}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                hasNext={hasNext}
                hasPrevious={hasPrevious}
                submitForm={handleSubmit}
              >
                <div className="mt-10 relative mx-auto">
                  <MesChargesForm
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    index={0}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    constants={constants}
                    dyanmicConstants={dyanmicConstants}
                    values={values}
                    includeCredits
                    coEmpNumber={values.length}
                  />
                  {values.length > 1 && (
                    <div className="mt-5 border-t-2 border-white border-solid">
                      <div className="pt-5 font-qSCBold text-modalHeader">
                        Mes Co-Emprunteurs
                      </div>
                      <div className="flex mt-6 flex-wrap">
                        {values.slice(1).map((co, index) => (
                          <div key={`co-${index}`}>
                            <BigCardInModal
                              onClick={() => setopenCoEmpIndex(index + 1)}
                              selected={openCoEmpIndex === index + 1}
                              text={`Co-Emprunteur #${index + 1}`}
                              noIcon
                            />
                          </div>
                        ))}
                      </div>
                      {openCoEmpIndex !== -1 && (
                        <div className="mt-10">
                          <MesChargesForm
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            index={openCoEmpIndex}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            constants={constants}
                            values={values}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
