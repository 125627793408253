import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { InlineWidget } from "react-calendly";
import { useSelector } from "react-redux";
import { selectCalendlyUrl } from "../guestSlice";
// import { calendlyUrl } from "../../../utils";
import "./CalendlyModal.scss";
// import { cardIcons } from "../../Partials/Card/CardHelper";
// import Button from "../../Partials/Button/Button";
// import classNames from "classnames";

function isCalendlyEvent(e) {
  return e.data.event && e.data.event.indexOf("calendly") === 0;
}

export default function CalendlyModal({
  hasPrevious,
  hasNext,
  isGuest,
  onNextClick,
  onPreviousClick,
  onExit,
}) {
  const calendlyUrl = useSelector(selectCalendlyUrl);
  const theme = useTheme();
  const isDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const widgetHeight = isDownSM ? `325px` : `750px`;
  // const [isFinished, setIsFinished] = useState(false);
  window.addEventListener("message", function (e) {
    if (isCalendlyEvent(e)) {
      // console.log(e.data);
      if (e.data.event === "calendly.event_scheduled") {
        // setIsFinished(true);
        onNextClick();
      }
    }
  });
  return (
    <div className="pt-16">
      <div className="font-qSCBold text-modalHeader text-red-500 text-center max-w-screen-lg mx-auto">
        Prendre rdv pour un appel téléphonique d’environ 15min
      </div>
      <div className="font-qRegular text-center text-headline text-gray-400 mt-6 max-w-screen-sm mx-auto">
        Choisissez un créneau dans l&apos;agenda de votre expert. Une fois le
        RDV confirmé, vous recevrez un email de confirmation.
      </div>
      <div className="mx-auto max-w-screen-lg">
        <InlineWidget
          pageSettings={{
            backgroundColor: "ffffff",
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: "00a2ff",
            textColor: "4d5055",
          }}
          prefill={{
            customAnswers: {
              // a1: "a1",
              // a10: "a10",
              // a2: "a2",
              // a3: "a3",
              // a4: "a4",
              // a5: "a5",
              // a6: "a6",
              // a7: "a7",
              // a8: "a8",
              // a9: "a9",
            },
            email: "",
            firstName: "",
            lastName: "",
            name: "",
          }}
          styles={{
            height: widgetHeight,
            width: "100%",
          }}
          url={calendlyUrl}
        />
      </div>
    </div>
  );
}
