import React from "react";
import { IconButton, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import text1Image from "./text1_2.jpg";
import text2Image from "./text2_2.jpg";
import text3Image from "./text3_2.jpg";
import text4Image from "./text4_2.jpg";
import text5Image from "./text5_2.jpg";

const images = {
  text1Image,
  text2Image,
  text3Image,
  text4Image,
  text5Image,
};

export default function GeneralText({
  handleClose,
  imageName,
  headline,
  body,
  proAdvice,
}) {
  // console.log(imageName);
  const isMobile = useMediaQuery("(max-width:720px)");
  return (
    <div className="w-full sm:max-w-screen-md sm:pl-16 relative">
      <div className="absolute left-0 top-0">
        <div className="sm:pl-4 sm:pt-4">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="py-14">
        <div
          className="text-red-500 text-header font-qSCBold mb-5"
          style={isMobile ? {} : { width: "590px" }}
        >
          {headline}
        </div>
        <div
          className="p-5 my-6"
          style={{
            width: isMobile ? "auto" : "590px",
            backgroundColor: "rgba(72, 96, 189, 0.16)",
          }}
        >
          <div className="text-blue-500 font-qSCBold text-taxesHeader text-opacity-100 pb-3">
            Le conseil du pro
          </div>
          <div className="font-qSCBold text-body text-gray-400">
            {proAdvice}
          </div>
        </div>
        <div className="pb-8">
          <img
            src={images[imageName]}
            className="mx-auto"
            alt="people talking"
          />
        </div>
        <div
          className="text-body font-qRegular ml-auto text-gray-400 sm:pr-5"
          style={{
            width: isMobile ? "auto" : "590px",
          }}
        >
          {body}
        </div>
      </div>
    </div>
  );
}
