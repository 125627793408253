import React from "react";
import classNames from "classnames";
import userIcon from "../../../images/man.svg";
import userBlueIcon from "../../../images/user-blue.svg";
import "../CardInModal/CardInModal.scss";

export default function BigCardInModal({
  text,
  children,
  onClick,
  open,
  cardIcon,
  noIcon,
  selected,
}) {
  const largeCardCustomClass = classNames(
    {
      "border-b-2 border-blue-500 shadow-lg": !open,
      "bg-blue-500": selected,
      "bg-white": !selected,
    },
    "px-2 py-6 cursor-pointer"
  );
  const customText = classNames(
    {
      "text-white": selected,
      "text-blue-500": !selected,
    },
    "card-text mr-4"
  );
  return (
    <div
      onClick={onClick}
      role="presentation"
      className="select-none my-4 mr-4"
    >
      <div className={largeCardCustomClass}>
        <div className="large-card">
          <span className="mr-2">
            <img
              height="32px"
              width="32px"
              src={selected ? userBlueIcon : userIcon}
              alt="icon"
            />
          </span>
          <span className={customText}>{text}</span>
        </div>
      </div>
      {children}
    </div>
  );
}
