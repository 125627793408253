import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { handleError } from "../../utils";
import {
  selectGuestBesoin,
  selectGuestCoordonnees,
  selectPartnership,
  selectGuestSituation,
  guestAPI,
} from "./guestUtils";

export {
  selectTaxes,
  selectGuestBesoin,
  selectGuestCoordonnees,
  selectGuestSituation,
  selectCalendlyUrl,
  selectPartnership,
} from "./guestUtils";

export const setPartnership = createAction("guest/setPartnership");

export const getTaxes = createAsyncThunk(
  "guest/getTaxes",
  async (_, thunkAPI) => {
    const coordonnees = selectGuestCoordonnees(thunkAPI.getState());
    const besoin = selectGuestBesoin(thunkAPI.getState());
    const additionalInfo = selectGuestSituation(thunkAPI.getState());
    const partnership = selectPartnership(thunkAPI.getState());
    try {
      const response = await guestAPI.taxesGet({
        guestNeed: besoin,
        guestInfo: coordonnees.owners,
        guestAdditionalInfo: additionalInfo,
        partnership,
      });
      return response.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const slice = createSlice({
  name: "guest",
  initialState: {
    calendlyUrl: "",
    partnership: "",
    taxes: {
      15: {},
      20: {},
      25: {},
    },
    besoin: {
      projectType: "",
      goodType: "",
      goodUsage: "",
      projectProgress: "",
      purchaseAmount: "",
    },
    coordonnees: {
      owners: [
        {
          title: "",
          firstName: "",
          lastName: "",
          address: "",
          city: "",
          postalCode: "",
          email: "",
          phoneNumber: "",
          monthlyIncome: "",
        },
      ],
    },
    additionalInfo: [
      {
        amount: "",
        currentAccommodation: "OWNER",
        dateOfBirth: null,
        entryDate: null,
        familySituation: "",
        isFirstTimeBuyer: "false",
        numberOfDependents: 0,
        placeOfBirth: "",
      },
    ],
    hasCompletedAdditionalInfo: false,
    hasCompletedCoordonnees: false,
    hasCompletedBesoin: false,
  },
  reducers: {
    setGuestBesoin: (state, { payload }) => {
      state.besoin = payload;
      state.hasCompletedBesoin = true;
    },
    setGuestCoordonees: (state, { payload }) => {
      state.coordonnees = payload;
      state.hasCompletedCoordonnees = true;
    },
    setGuestAdditionalInfo: (state, { payload }) => {
      state.additionalInfo = payload;
      state.hasCompletedAdditionalInfo = true;
      const coordonneesArray = [];
      for (let i = 0; i < payload.length; i += 1) {
        coordonneesArray.push({
          firstName: "",
          title: "",
          lastName: "",
          address: "",
          city: "",
          postalCode: "",
          email: "",
          phoneNumber: "",
          monthlyIncome: "",
        });
      }
      state.coordonnees = {
        owners: coordonneesArray,
      };
    },
  },
  extraReducers: {
    [getTaxes.fulfilled]: (state, { payload }) => {
      state.taxes = payload;
      state.calendlyUrl = payload.calendarUrl;
    },
    [setPartnership]: (state, { payload }) => {
      state.partnership = payload;
    },
  },
});

export const {
  setGuestBesoin,
  setGuestCoordonees,
  setGuestAdditionalInfo,
} = slice.actions;

export const createAccount = createAsyncThunk(
  "guest/signup",
  async (
    values = {
      hideToast: false,
    },
    thunkAPI
  ) => {
    const besoin = selectGuestBesoin(thunkAPI.getState());
    const coordonnees = selectGuestCoordonnees(thunkAPI.getState());
    const additionalInfo = selectGuestSituation(thunkAPI.getState());
    const partnership = selectPartnership(thunkAPI.getState());
    try {
      const response = await guestAPI.accountCreate({
        guestNeed: besoin,
        guestInfo: [
          {
            ...coordonnees.owners[0],
            noCommercialEmail: coordonnees.owners[0].noCommercialEmail,
          },
          ...coordonnees.owners.slice(1),
        ],
        guestAdditionalInfo: additionalInfo,
        partnership,
      });
      if (!values.hideToast) {
        toast.success(
          `Nous venons de vous envoyer un lien de connexion à ${coordonnees.owners[0].email}`
        );
      }
      return response.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export default slice.reducer;
