import React from "react";
import Slider from "react-slick";
import CustomArrow from "../CustomArrow/CustomArrow";
import CardInModal from "../../Modals/CardInModal/CardInModal";
import plusIcon from "../../../images/plus-white.svg";

/**
 *
 * arrayForCards needs to contain slice(1)
 * of array in case the first element is shown in a separate form
 */
export default function ArtemisSlider({
  setOpenIndex,
  setOpen,
  handleOpenIndex,
  arrayObject,
  arrayHelpers,
  openIndex,
  arrayForCards,
  maxSliderOptions,
  array,
  label,
  cardIcon,
  hideAddButton,
  cardText,
}) {
  return (
    <Slider
      slidesToShow={3}
      centerMode={false}
      infinite={false}
      nextArrow={<CustomArrow position="right" />}
      prevArrow={<CustomArrow position="left" />}
      beforeChange={() => {
        setOpenIndex(-1);
        setOpen(false);
      }}
      responsive={[
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ]}
    >
      {arrayForCards.map((revenue, index) => (
        <CardInModal
          key={index}
          setOpen={() => handleOpenIndex(index)}
          open={openIndex === index + 1}
          text={`${cardText} #${index + 1}`}
          cardIcon={cardIcon}
        />
      ))}
      {!hideAddButton && array.length < (maxSliderOptions || 6) && (
        <div
          role="presentation"
          className="cursor-pointer select-none  py-2 px-1"
          style={{ display: "flex" }}
          id="add-arrayObject-button"
          onClick={() => {
            arrayHelpers.push(arrayObject);
          }}
        >
          <div className="flex items-center">
            <div
              className="rounded-full bg-blue-500 inline-flex items-center justify-center"
              style={{ height: "32px", width: "32px" }}
            >
              <img src={plusIcon} alt="add co-owner" />
            </div>
            <span className="ml-4 font-qSCBold text-blue-500 text-body underline">
              {label}
            </span>
          </div>
        </div>
      )}
    </Slider>
  );
}
