import React, { useState } from "react";

export default function ImageWithFallback(props) {
  const [errored, setErrored] = useState(false);
  const onError = (e) => {
    if (!errored) {
      setErrored(true);
      e.target.src = props.fallbackSrc;
    }
  };
  return (
    <img
      className={props.className}
      src={props.src}
      alt={props.alt}
      onError={onError}
      {...props}
    />
  );
}
