import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import Input from "../Input/Input";

export default function ArtemisKeyboardDatePicker({
  name,
  label,
  value,
  setFieldValue,
  noError,
  error,
  touched,
}) {
  return (
    <KeyboardDatePicker
      cancelLabel="Annuler"
      helperText=""
      TextFieldComponent={Input}
      format="DD/MM/yyyy"
      name={name}
      inputProps={{
        autoComplete: "off",
        trailIconName: "calendar",
        placeholder: "JJ/MM/AAAA",
        noError,
        label,
        error,
        touched,
      }}
      value={value}
      onChange={(date) => {
        setFieldValue(
          name,
          moment(date).isValid() ? moment(date).format() : date
        );
      }}
      openTo="year"
      views={["year", "month", "date"]}
    />
  );
}
