import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { handleError } from "../../utils";
import { selectAccessToken } from "../Authentication/authenticationSlice";
import {
  // selectDocuments,
  // selectPopupStatus,
  // selectDossierStatus,
  dossierAPI,
} from "./dossierUtils";

export {
  selectDocuments,
  selectPopupStatus,
  selectDossierStatus,
} from "./dossierUtils";

export const getDossierStatus = createAsyncThunk(
  "dossier/getDossierStatus",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await dossierAPI.getProjectStatus({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const getPopupStatus = createAsyncThunk(
  "dossier/getPopupStatus",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await dossierAPI.getPopupStatus({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const getDocuments = createAsyncThunk(
  "dossier/getDocuments",
  async (_, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      const res = await dossierAPI.getDocuments({ jwt });
      return res.body;
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const removeDocument = createAsyncThunk(
  "dossier/removeDocument",
  async ({ documentId, name }, thunkAPI) => {
    const jwt = selectAccessToken(thunkAPI.getState());
    try {
      await dossierAPI.removeDocument({ jwt, documentId });
      thunkAPI.dispatch(getDocuments());
      return { documentId, name };
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const uploadDocument = createAsyncThunk(
  "dossier/uploadDocument",
  async ({ documentId, file, name }, thunkAPI) => {
    try {
      if (!file) {
        toast.error(
          "Seuls les formats PDF, PNG, JPEG/JPG sont acceptés. Merci de réessayer."
        );
        return false;
      }
      const jwt = selectAccessToken(thunkAPI.getState());
      const form = new FormData();
      form.append("documentId", documentId);
      form.append("document", file);
      await dossierAPI.uploadDocument({ jwt, form });
      thunkAPI.dispatch(getDocuments());
      return { documentId, name };
      // dispatch(
      //   uploadSuccess({
      //     documentId,
      //     name,
      //   })
      // );
    } catch (err) {
      handleError(err);
      throw err;
    }
  }
);

export const slice = createSlice({
  name: "dossier",
  initialState: {
    documents: [],
    docs: {
      id_card: {
        files: [],
      },
    },
    popups: {},
    dossierStatus: {},
    uploadingDocumentId: undefined,
  },
  reducers: {},
  extraReducers: {
    [getDossierStatus.fulfilled]: (state, { payload }) => {
      state.dossierStatus = {
        ...payload,
        // id: 5, // 0 === 1 for statuses (legacy dossier compatibility)
        id: payload.id === 0 ? 1 : payload.id, // 0 === 1 for statuses (legacy dossier compatibility)
      };
    },
    [getPopupStatus.fulfilled]: (state, { payload }) => {
      state.popups = payload;
    },
    [getDocuments.fulfilled]: (state, { payload }) => {
      state.documents = payload;
    },
    [uploadDocument.pending]: (state, { meta }) => {
      state.uploadingDocumentId = meta.arg.documentId;
    },
    [uploadDocument.rejected]: (state, { payload }) => {
      state.uploadingDocumentId = undefined;
    },
    [uploadDocument.fulfilled]: (state, { payload }) => {
      state.uploadingDocumentId = undefined;
    },
    // [removeDocument.fulfilled]: (state, { payload }) => {
    //   state.documents = state.documents.map((d) =>
    //     d.name === payload.name
    //       ? {
    //           ...d,
    //           files: d.files.map((f) =>
    //             f.documentId === payload.documentId
    //               ? {
    //                   ...f,
    //                   status: "REQUIRED",
    //                 }
    //               : f
    //           ),
    //         }
    //       : d
    //   );
    // },
    // [uploadDocument.fulfilled]: (state, { payload }) => {
    //   state.documents = state.documents.map((d) =>
    //     d.name === payload.name
    //       ? {
    //           ...d,
    //           files: d.files.map((f) =>
    //             f.documentId === payload.documentId
    //               ? {
    //                   ...f,
    //                   status: "SENT",
    //                 }
    //               : f
    //           ),
    //         }
    //       : d
    //   );
    // },
  },
});

export const {
  uploadSuccess,
  removeSuccess,
  getDocumentsSuccess,
  getPopupStatusSuccess,
  getDossierStatusSuccess,
} = slice.actions;

export default slice.reducer;
