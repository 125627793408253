import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// import { DatePicker } from "@material-ui/pickers";
import classNames from "classnames";
import {
  sendSituation,
  getSituation,
  selectSituation,
} from "../../Dossier/modalsSlice";
import Modal from "../../Partials/Modal/Modal";
import "../../../styles/DatePicker.scss";
import "./Situation.scss";
import { selectConstants } from "../../Partials/constantsSlice";
import SituationForm from "./SituationForm";
import ArtemisSlider from "../../Partials/Slider/ArtemisSlider";

const Situation = ({
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
}) => {
  const dispatch = useDispatch();
  const constants = useSelector(selectConstants);
  useEffect(() => {
    dispatch(getSituation());
  }, [dispatch]);
  const formData = useSelector(selectSituation);
  const handleSubmitForm = async (values) => {
    const realValues = values.owners.reduce(
      (pv, cv, index) => [
        ...pv,
        {
          ...formData[index],
          ...cv,
          isFirstTimeBuyer: cv.isFirstTimeBuyer === "true",
        },
      ],
      []
    );
    await dispatch(sendSituation(realValues));
    onNextClick();
  };
  const [open, setOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const handleOpenIndex = (index) => {
    if (index + 1 === openIndex) {
      setOpen(false);
      setOpenIndex(-1);
    } else {
      setOpen(true);
      setOpenIndex(index + 1);
    }
  };
  const panel = classNames(
    {
      "block border-b-2 border-blue-500": open,
      hidden: !open,
    },
    "pt-8 bg-white px-4 shadow-lg -mt-1"
  );
  return (
    <Formik
      enableReinitialize
      validationSchema={yup.object().shape({
        owners: yup.array().of(
          yup.object().shape({
            numberOfDependents: yup.number().min(0, "Too low"),
          })
        ),
      })}
      initialValues={{
        owners: formData,
      }}
      onSubmit={handleSubmitForm}
    >
      {({
        handleChange,
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        // submitForm,
        handleSubmit,
      }) => {
        return (
          <Form>
            <Modal
              title="Ma situation"
              hideModal={hideModal}
              submitForm={handleSubmit}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
            >
              <div className="mt-10">
                <SituationForm
                  values={values.owners[0]}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  index={0}
                  setFieldValue={setFieldValue}
                  constants={constants}
                />
              </div>
              <div className="sm:w-3/6 xs:w-full my-6">
                <p className="font-qRegular text-caption">
                  *Personne qui n’a pas été propriétaire de sa résidence
                  principale pendant les 2 dernières années
                </p>
              </div>
              <FieldArray
                name="owners"
                render={(arrayHelpers) => (
                  <div>
                    <ArtemisSlider
                      setOpenIndex={setOpenIndex}
                      setOpen={setOpen}
                      handleOpenIndex={handleOpenIndex}
                      arrayHelpers={arrayHelpers}
                      openIndex={openIndex}
                      array={values.owners}
                      cardText="Co-emprunteur"
                      arrayForCards={values.owners.slice(1)}
                      hideAddButton
                    />
                    <div className={panel}>
                      <div className="my-6">
                        <SituationForm
                          showBorder
                          constants={constants}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          handleRemove={() => {
                            setOpen(false);
                            setOpenIndex(-1);
                            arrayHelpers.remove(openIndex);
                          }}
                          handleBlur={handleBlur}
                          index={openIndex}
                          errors={
                            openIndex !== -1
                              ? errors.owners && errors.owners[openIndex]
                              : false
                          }
                          touched={
                            openIndex !== -1
                              ? touched.owners && touched.owners[openIndex]
                              : false
                          }
                          values={
                            openIndex !== -1
                              ? values.owners && values.owners[openIndex]
                              : false
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Situation;
