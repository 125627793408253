import request from "superagent";
import { serverUrl } from "../../utils";

export const selectConstants = (state) => state.constants.constants;
export const selectDynamicConstants = (state) =>
  state.constants.dynamicConstants;

export const constantsAPI = {
  getConstants: () => {
    return request.get(`${serverUrl}/projects/constants`);
  },
  getDynamicConstants: ({ jwt }) => {
    return request
      .get(`${serverUrl}/projects/dynamic-constants`)
      .set("Authorization", `Bearer ${jwt}`);
  },
};
