import React, { useState } from "react";
import {
  NavLink,
  useHistory,
  Link,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useSelector } from "react-redux";
import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  makeStyles,
} from "@material-ui/core";
import logo from "../../images/logo.svg";
import "./Header.scss";
import { selectIsLoggedIn } from "../Authentication/authenticationSlice";
import Dropdown from "../Partials/Dropdown/Dropdown";
import { selectPartnership } from "../Guest/guestUtils";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#4860BD",
    color: "white",
    padding: "1rem 2rem",
    borderRadius: "0",
    top: "0 !important",
    left: "auto !important",
    right: 0,
  },
});

export default function HeaderNew() {
  const history = useHistory();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (e) => {
    setOpenMenu((t) => !t);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = (e) => {
    setOpenMenu(false);
    setAnchorEl(null);
  };

  const handleGoTo = (location) => (e) => {
    setOpenMenu(false);
    setAnchorEl(null);
    history.push(location);
  };
  const classes = useStyles();
  return (
    <div className="relative z-50 header">
      <div className="flex items-center h-full max-w-screen-lg mx-auto">
        <div className="mr-12">
          <Link to="/accueil">
            <img src={logo} alt="Artemis logo" className="logo-artemis" />
          </Link>
        </div>
        <div className="items-end justify-between flex-grow hidden h-full header-spacing left md:flex">
          {isLoggedIn && (
            <>
              <NavLink
                to="/accueil"
                activeClassName="active"
                className="header-link font-qSCBold text-headline"
              >
                ACCUEIL
              </NavLink>
              <NavLink
                to="/mon-dossier"
                activeClassName="active"
                className="header-link font-qSCBold text-headline"
              >
                MON DOSSIER
              </NavLink>
              <NavLink
                to="/mes-documents"
                activeClassName="active"
                className="header-link font-qSCBold text-headline"
              >
                MES DOCUMENTS
              </NavLink>
            </>
          )}
        </div>
        <div className="flex-1 flex-grow" />
        <div className="flex items-center header-spacing right">
          {isLoggedIn && (
            <>
              <div className="hidden md:block">
                <Dropdown />
              </div>
              <div className="block md:hidden">
                <IconButton onClick={handleOpenMenu}>
                  <i className="fa fa-bars" />
                </IconButton>
              </div>
            </>
          )}

          <Menu
            classes={{
              paper: classes.paper,
            }}
            open={openMenu}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            keepMounted
          >
            <MenuItem onClick={handleGoTo("accueil")}>Accueil</MenuItem>
            <Divider />
            <MenuItem onClick={handleGoTo("/mon-dossier")}>
              Mon dossier
            </MenuItem>
            <MenuItem onClick={handleGoTo("/mon-dossier#justificatifs")}>
              Mes documents
            </MenuItem>
            <MenuItem onClick={handleGoTo("/mon-compte")}>Mon compte</MenuItem>
            <Divider />
            <MenuItem>Deconnexion</MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}
