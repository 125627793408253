import React, { useState } from "react";
import "./dropdown.scss";
import { ClickAwayListener } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import userCircleIcon from "./user-circle.svg";
import dropdownTriangleIcon from "../../../images/dropdown-triangle.svg";
import { logout } from "../../Authentication/authenticationSlice";
/*
  <!--
    Dropdown panel, show/hide based on dropdown state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"

    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
*/

export default function Dropdown() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClickAway = () => {
    setOpen(false);
  };
  const handleGoTo = (location) => {
    setOpen(false);
    history.push(location);
  };
  const handleLogout = () => {
    setOpen(false);
    dispatch(logout());
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="relative inline-block text-left z-40">
        <div>
          <span className="rounded-md focus:outline-none">
            <button
              onClick={() => setOpen((x) => !x)}
              type="button"
              className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none transition ease-in-out duration-150"
            >
              <img src={userCircleIcon} alt="user dropdown" />
              <div className="ml-4 mt-4">
                <img src={dropdownTriangleIcon} alt="dropdown triangle" />
              </div>
            </button>
          </span>
        </div>
        {open && (
          <div
            id="opened"
            className="select-none origin-top-right absolute right-0 mt-2 rounded-md z-50 dropdown-menu"
          >
            <div className="rounded-md bg-white">
              <div className="py-1">
                <div
                  id="mon-compte"
                  onClick={() => handleGoTo("/mon-compte")}
                  className="text-center block py-2 px-8 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                >
                  <span className="dropdown-text">Mon compte </span>
                </div>
                <div className="border-t border-gray-100 dropdown-border" />
                <button
                  id="logout"
                  onClick={handleLogout}
                  type="button"
                  className="text-center block py-2 px-8 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                >
                  <span className="dropdown-text">Déconnexion</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
