import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Background from "../../Partials/Background/Background";
import "./LoginHook.scss";
import { selectIsLoggedIn, selectReturnUrl } from "../authenticationSlice";

export default function LoginHook() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const history = useHistory();
  const returnUrl = useSelector(selectReturnUrl);
  useEffect(() => {
    if (isLoggedIn) {
      history.push(returnUrl.pathname);
    }
  }, [isLoggedIn, history, returnUrl.pathname]);
  return (
    <Background noImage>
      <div className="flex items-center justify-center absolute center w-full">
        <div>
          <div className="flex justify-center mb-4">
            <CircularProgress />
          </div>
          <div className="font-qBold text-body">Chargement en cours ...</div>
        </div>
      </div>
    </Background>
  );
}
