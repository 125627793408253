import { toast } from "react-toastify";

export const serverUrl = process.env.REACT_APP_SERVER_URL;
export const calendlyUrl = process.env.REACT_APP_CALENDLY_URL;
export const errors = {
  REQUIRE_AUTHENTICATION: "Veuillez vous authentifier",
  EMPTY_INPUT: "Veuillez remplir ce champ",
  INVALID_EMAIL: "Veuillez entrer un email valide",
  EMAIL_REQUIRED: "Veuillez remplir ce champ",
  EMAIL_EXISTS_IN_DB: "Cette adresse email est déjà utilisée",
  FIRST_NAME_REQUIRED: "Veuillez remplir ce champ",
  LAST_NAME_REQUIRED: "Veuillez remplir ce champ",
  PHONE_NUMBER_REQUIRED: "Veuillez remplir ce champ",
  INVALID_PHONE_NUMBER: "Veuillez entrer un numéro de téléphone valide",
  POSTAL_CODE_REQUIRED: "Veuillez remplir ce champ",
  INVALID_POSTAL_CODE: "Veuillez entrer un code postal valide",
  INVALID_CREDENTIALS: "Il n'y a pas de compte associé à cet e-mail",
};
export const handleError = (error) => {
  // console.log(error);
  if (error.response) {
    toast.error(
      error.response.body.errors
        ? errors[error.response.body.errors[0]]
        : errors[error.response.body.error]
    );
  } else {
    toast.error(error);
  }
};

export const mapProjectTypeToHumanReadable = {
  BALANCE_PAYMENT: { balanceAmount: "Montant de la soulte" },
  BALANCE_PAYMENT_AND_WORK: {
    balanceAmount: "Montant de la soulte",
    amountOfWork: "Montant des travaux",
  },
  CONSTRUCTION: {
    purchaseAmount: "Coût de la construction",
  },
  CREDIT_REPURCHASE: {
    propertyRenegotiationAmount: "Capital restant dû à renégocier",
    amountOfWork: "Montant des travaux",
  },
  EXTENSION_WORK: { amountOfWork: "Montant des travaux" },
  IMPROVEMENT_WORK: { amountOfWork: "Montant des travaux" },
  LAND_AND_CONSTRUCTION: {
    purchaseAmount: "Coût de la construction",
    landPrice: "Prix du terrain",
  },
  LAND_ONLY: { landPrice: "Prix du terrain" },
  NEW: { purchaseAmount: "Prix du bien" },
  NEW_TURNKEY: { purchaseAmount: "Prix du bien" },
  OLD: { purchaseAmount: "Prix du bien", amountOfWork: "Montant des travaux" },
  OLD_WITHOUT_WORK: { purchaseAmount: "Prix du bien" },
  OTHER_WORK: { amountOfWork: "Montant des travaux" },
  PURCHASE_OF_SCPI: { sharesAmount: "Montant des parts de SCPI" },
  RENOVATION_WORK: { amountOfWork: "Montant des travaux" },
};

/*
EMPTY_INPUT: 'EMPTY_INPUT',  => Veuillez remplir ce champ

INVALID_EMAIL: 'INVALID_EMAIL', => Veuillez entrer un email valide

EMAIL_REQUIRED: 'EMAIL_REQUIRED', => Veuillez remplir ce champ

EMAIL_EXISTS_IN_DB: 'EMAIL_EXISTS_IN_DB', => Cette adresse email est déjà utilisée

FIRST_NAME_REQUIRED: 'FIRST_NAME_REQUIRED', => Veuillez remplir ce champ

LAST_NAME_REQUIRED: 'LAST_NAME_REQUIRED', => Veuillez remplir ce champ

PHONE_NUMBER_REQUIRED: 'PHONE_NUMBER_REQUIRED', => Veuillez remplir ce champ

INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER', => Veuillez entrer un numéro de téléphone valide

POSTAL_CODE_REQUIRED: 'POSTAL_CODE_REQUIRED', => Veuillez remplir ce champ

INVALID_POSTAL_CODE: 'INVALID_POSTAL_CODE', => Veuillez entrer un code postal valide
*/
