import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const RedCheckbox = withStyles({
  root: {
    color: "#FF6B58",
    width: "32px",
    height: "32px",
    "&$checked": {
      color: "#FF6B58",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const signUpText = (
  <span>
    En cochant cette case, vous acceptez qu’Artémis Courtage collecte vos
    données pour gérer et suivre l’évolution de vos demandes de crédit
    immobilier en créant votre dossier en ligne. <br />
    Pour en savoir plus sur la gestion de vos données personnelles et exercer
    vos droits, cliquer{" "}
    <a
      href="https://www.artemiscourtage.com/confidentialite/"
      className="font-qBold underline text-blue-500 text-small"
      target="_blank"
      rel="noopener noreferrer"
    >
      ici
    </a>
    .
  </span>
);

const guestText = (
  <span>
    En cochant cette case, vous acceptez qu’Artémis Courtage collecte vos
    coordonnées pour prendre contact avec vous et discuter de votre projet
    immobilier.
    <br />
    Pour en savoir plus sur la gestion de vos données personnelles et exercer
    vos droits, cliquer{" "}
    <a
      href="https://www.artemiscourtage.com/confidentialite/"
      className="font-qBold underline text-blue-500 text-small"
      target="_blank"
      rel="noopener noreferrer"
    >
      ici
    </a>
    .
  </span>
);

const signUpText2Before = (
  <span>
    Nous vous informons que nous pouvons utiliser votre adresse email pour vous
    adresser des publicités concernant nos autres produits en matière de crédit
    immobilier et assurance emprunteur.
    <br />
    Vous pouvez vous y opposer en cochant la case suivante:
  </span>
);

const signUpText2 = (
  <span>
    Je ne souhaite pas recevoir d&apos;autres sollicitations commerciales
    d&apos;Artémis Courtage.
  </span>
);

const Consent = ({ name, onChange, checked, text, textBefore, location }) => {
  if (textBefore) {
    return (
      <div>
        <p className="leading-none">
          <span className="font-qBold text-gray-400 text-small opacity-50">
            {location === "signup2" ? signUpText2Before : textBefore}
          </span>
        </p>
        <div className="flex items-center relative mt-5 pb-5">
          <div>
            <RedCheckbox name={name} checked={checked} onChange={onChange} />
          </div>
          <div className="ml-2">
            <p className="leading-none">
              <span className="font-qBold text-gray-400 text-small opacity-50">
                {location === "signup2" ? signUpText2 : text}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex items-center relative mt-5 pb-5">
      <div>
        <RedCheckbox name={name} checked={checked} onChange={onChange} />
      </div>
      <div className="ml-2">
        <p className="leading-none">
          <span className="font-qBold text-gray-400 text-small opacity-50">
            {location === "signup" ? signUpText : guestText}
          </span>
        </p>
      </div>
    </div>
  );
};
// const Consent = ({ name, onChange, checked, notInline }) => {
//   return (
//     <div className="flex justify-between relative mt-5 pb-5">
//       <div>
//         <RedCheckbox name={name} checked={checked} onChange={onChange} />
//       </div>
//       <div>
//         <p className="leading-none">
//           <span className="font-qBold text-gray-400 text-small opacity-50">
//             En cochant cette case, j’accepte que mes information soient
//             utilisées exclusivement dans le cadre de ma demande et de la
//             relation commerciale personnalisée qui pourrait en découler.
//           </span>
//           {!notInline && <br />}
//           <span className="font-qBold text-gray-400 text-small opacity-50">
//             {" "}
//             Consulter la{" "}
//           </span>
//           <a
//             href="https://www.artemiscourtage.dev/confidentialite/"
//             className="font-qBold underline text-blue-500 text-small"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Politique de traitement des données personnelles
//           </a>
//         </p>
//       </div>
//     </div>
//   );
// };

export default Consent;
