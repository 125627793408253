import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Partials/Card/Card";
import Chat from "../Chat/Chat";
import Background from "../Partials/Background/Background";
import { getDocuments, selectDocuments } from "./documentsSlice";

// const documentsArray = [
//   {
//     imageName: "crayon",
//     text: "Mandat de recherche",
//     status: "signed",
//     statusText: "",
//     date: "12/10/2020",
//   },
//   {
//     imageName: "crayon",
//     text: "Fiche conseil",
//     status: "toSign",
//   },
//   {
//     imageName: "crayon",
//     text: "Simulation de prêt",
//     status: "toConsult",
//   },
// ];
/*
[
    {
        "id": 16,
        "userId": 4,
        "signingLink": "https://staging-app.yousign.com/procedure/sign?members=/members/db28b300-b1a5-446c-9d6f-2a2a696ac3c4",
        "downloadLink": "/documents/6dd56bb6-b363-4a20-b4bd-687729f266ac/download",
        "status": "active",
        "signingDate": null,
        "documentName": "document2.pdf"
    },
    {
        "id": 17,
        "userId": 4,
        "signingLink": "https://staging-app.yousign.com/procedure/sign?members=/members/025a6d91-5380-4e5b-a71e-22036a0e2086",
        "downloadLink": "/documents/2cf40ac3-171b-401a-9da7-9f4adb20f33d/download",
        "status": "active",
        "signingDate": null,
        "documentName": "document1.pdf"
    },
    {
        "id": 3,
        "userId": 4,
        "signingLink": null,
        "downloadLink": "/documents/bcdb3ce8-f08f-4cdb-8583-c41eb668f252/download",
        "status": "active",
        "signingDate": null,
        "documentName": "document3.pdf"
    }
]
*/
const Documents = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDocuments());
  }, [dispatch]);
  const documents = useSelector(selectDocuments);

  const mapDocToStatus = (doc) => {
    if (doc.status === "active") {
      if (doc.signingLink) {
        return "active";
      }
      return "toConsult";
    }
    return doc.status;
  };
  return (
    <Background>
      <div className="z-50 mx-auto max-w-screen-lg h-full p-4 lg:p-0">
        <div className="pt-10">
          <h1 className="font-qSCBold text-header text-red-500">
            Mes documents
          </h1>
          <p className="font-qRegular text-body pt-4">
            Cliquez sur un document pour le visualiser ou le signer
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-6 gap-6 pt-12">
          {documents.map((doc, index) => (
            <Card
              key={index}
              text={doc.documentName}
              status={mapDocToStatus(doc)}
              date={doc.signingDate || ""}
              signingLink={doc.signingLink}
              downloadLink={doc.downloadLink}
            />
          ))}
        </div>
      </div>
      <Chat />
    </Background>
  );
};

export default Documents;
