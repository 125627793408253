import React from "react";
import PropTypes from "prop-types";
import "./button.scss";
import classNames from "classnames";

/* eslint-disable react/button-has-type */

export default function Button(props) {
  const {
    type,
    children,
    variant,
    onClick,
    withIcon,
    disabled,
    noPadding,
    className,
  } = props;
  const buttonClass = classNames(
    {
      "px-4": !withIcon,
      "px-2": withIcon,
    },
    "font-qSCBold justify-center uppercase inline-flex items-center py-2 text-button text-white focus:outline-none"
  );
  if (variant === "next") {
    return (
      <button
        onClick={onClick}
        type={type}
        disabled={disabled}
        className={classNames(
          // buttonClass,
          className,
          "inline-flex items-center text-button focus:outline-none",
          "leading-6 font-medium rounded-full transition ease-in-out duration-150"
        )}
      >
        {children}
      </button>
    );
  }
  if (variant === "primary") {
    return (
      <button
        onClick={onClick}
        type={type}
        disabled={disabled}
        className={classNames(
          className,
          buttonClass,
          "artemis-button border border-transparent leading-6 font-medium rounded-full bg-blue-500 hover:bg-blue-600 transition ease-in-out duration-150"
        )}
      >
        {children}
      </button>
    );
  }
  if (variant === "secondary") {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        type="button"
        className="inline-flex items-center px-6 py-3 border border-gray-300 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
      >
        Button text
      </button>
    );
  }
  if (variant === "transparentButton") {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={classNames(
          className,
          {
            "pr-6 py-3": !noPadding,
          },
          "artemis-button-transparent uppercase inline-flex items-center  font-medium rounded-md"
        )}
      >
        {children}
      </button>
    );
  }
  if (variant === "outlined") {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={classNames(
          className,
          buttonClass,
          "artemis-button artemis-button-outlined border-2 border-white rounded-full bg-transparent transition ease-in-out duration-150"
        )}
      >
        {children}
      </button>
    );
  }
  return null;
}

Button.propTypes = {
  type: PropTypes.oneOf(["submit", "button", "reset"]),
};

Button.defaultProps = {
  type: "button",
};
