import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { sendProjet, selectProjet, getProjet } from "../../Dossier/modalsSlice";
import Input from "../../Partials/Input/Input";
// import AquisitionRadioSelect from "../../Partials/BooleanRadioSelect/AquisitionRadioSelect";
import Modal from "../../Partials/Modal/Modal";
import Select from "../../Partials/Select/Select";
import { selectConstants } from "../../Partials/constantsUtils";
import { mapProjectTypeToHumanReadable } from "../../../utils";

const Projet = ({
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjet());
  }, [dispatch]);
  const constants = useSelector(selectConstants);
  const formData = useSelector(selectProjet);
  // const aquisitions = [
  //   {
  //     radioLabel: "Achat neuf",
  //     value: "NEW",
  //   },
  //   {
  //     radioLabel: "Achat ancien",
  //     value: "OLD",
  //   },
  //   {
  //     radioLabel: "Rachat de crédit",
  //     value: "CREDIT_REPURCHASE",
  //   },
  // ];
  const { projectTypeAmountFields } = constants;
  const handleSubmitForm = (values) => {
    dispatch(sendProjet(values));
    onNextClick();
  };
  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={yup.object().shape({
          propertyPostalCode: yup
            .string()
            .length(5, "Veuillez entrer un code postal valide"),
        })}
        initialValues={{
          projectType: "NEW",
          propertyAddress: "",
          propertyCity: "",
          propertyPostalCode: "",
          purchaseAmount: "",
          amountOfWork: "",
          propertyRenegotiationAmount: "",
          ...formData,
        }}
        onSubmit={handleSubmitForm}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => {
          return (
            <Form>
              <Modal
                title="Détails du projet"
                hideModal={hideModal}
                submitForm={handleSubmit}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                hasNext={hasNext}
                hasPrevious={hasPrevious}
              >
                <div className="col-span-1 sm:col-span-6 mt-8">
                  <div className="grid-cols-1 col-gap-6 md:grid-cols-9 grid mt-4 items-center">
                    <div className="col-span-1 sm:col-span-3">
                      <p className="font-qBold text-headline">
                        Type d’acquisition
                      </p>
                    </div>
                    <div className="col-span-1 md:col-span-3">
                      <Select
                        label=""
                        value={values.projectType}
                        name="projectType"
                        // error={errors.projectType}
                        // touched={touched.projectType}
                        onChange={handleChange}
                        noError
                        trailIconName="arrowUpDown"
                        options={Object.keys(constants.projectType).map(
                          (key) => ({
                            label: constants.projectType[key],
                            value: key,
                          })
                        )}
                      />
                      {/* {aquisitions.map((opt, index) => (
                      <AquisitionRadioSelect
                        key={index}
                        name="projectType"
                        value={opt.value}
                        onChange={setFieldValue}
                        radioLabel={opt.radioLabel}
                        checked={values.projectType === opt.value}
                      />
                    ))} */}
                    </div>
                  </div>
                </div>
                <div className="border-t-2 border-white border-solid mt-6" />
                <div className="grid grid-cols-1 col-gap-6 sm:grid-cols-9 relative items-end mt-4">
                  <div className="sm:col-span-3">
                    <Input
                      value={values.propertyAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.propertyAddress}
                      touched={touched.propertyAddress}
                      name="propertyAddress"
                      label="Adresse du bien"
                      placeholder="Adresse du bien"
                      leadingIconName="home"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Input
                      value={values.propertyCity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.propertyCity}
                      touched={touched.propertyCity}
                      name="propertyCity"
                      label="Ville"
                      placeholder="Ville"
                      leadingIconName="home"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Input
                      value={values.propertyPostalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.propertyPostalCode}
                      touched={touched.propertyPostalCode}
                      name="propertyPostalCode"
                      label="Code postal"
                      placeholder="Code postal"
                      leadingIconName="home"
                    />
                  </div>
                  {projectTypeAmountFields[values.projectType] &&
                    projectTypeAmountFields[values.projectType].map(
                      (fieldName) => (
                        <div className="sm:col-span-3" key={fieldName}>
                          <Input
                            type="number"
                            value={values[fieldName]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors[fieldName]}
                            touched={touched[fieldName]}
                            name={fieldName}
                            placeholder={
                              mapProjectTypeToHumanReadable[values.projectType][
                                fieldName
                              ]
                            }
                            label={
                              mapProjectTypeToHumanReadable[values.projectType][
                                fieldName
                              ]
                            }
                            trailIconName="euro"
                          />
                        </div>
                      )
                    )}
                  {/* {(values.projectType === "OLD" ||
                    values.projectType === "NEW") && (
                    <div className="sm:col-span-3">
                      <Input
                        type="number"
                        value={values.purchaseAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.propertyPrice}
                        touched={touched.propertyPrice}
                        name="purchaseAmount"
                        label="Prix du bien"
                        trailIconName="euro"
                      />
                    </div>
                  )}
                  {values.projectType === "CREDIT_REPURCHASE" && (
                    <div className="sm:col-span-3">
                      <Input
                        type="number"
                        value={values.propertyRenegotiationAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.propertyRenegotiationAmount}
                        touched={touched.propertyRenegotiationAmount}
                        name="propertyRenegotiationAmount"
                        label="CRD (capital restant du) à renégocier"
                        trailIconName="euro"
                      />
                    </div>
                  )} */}
                  {/* <div className="sm:col-span-3">
                    <Input
                      type="number"
                      value={values.amountOfWork}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.amountOfWork}
                      touched={touched.amountOfWork}
                      name="amountOfWork"
                      label="Montant des travaux"
                      trailIconName="euro"
                    />
                  </div> */}
                </div>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Projet;
