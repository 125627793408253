import React, { useState, useEffect } from "react";
import { Formik, FieldArray, Form } from "formik";
import classNames from "classnames";
import "./Coordonees.scss";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ArtemisSlider from "../../Partials/Slider/ArtemisSlider";
import Modal from "../../Partials/Modal/Modal";
import OnSubmitValidationError from "../../Partials/OnSubmitValidationError";
import FormikPatchTouched from "../../Partials/FormikPatchTouched";
import CoordoneesForm from "./CoordoneesForm";
import "slick-carousel/slick/slick.css";
import {
  sendCoordonnees,
  getCoordonnees,
  selectCoordonnees,
} from "../../Dossier/modalsSlice";

const Coordonees = ({
  hideModal,
  onNextClick,
  onPreviousClick,
  hasNext,
  hasPrevious,
}) => {
  const [open, setOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCoordonnees());
  }, [dispatch]);
  const formData = useSelector(selectCoordonnees);
  const handleOpenIndex = (index) => {
    if (index + 1 === openIndex) {
      setOpen(false);
      setOpenIndex(-1);
    } else {
      setOpen(true);
      setOpenIndex(index + 1);
    }
  };
  const panel = classNames(
    {
      "block border-b-2 border-blue-500": open,
      hidden: !open,
    },
    "pt-8 bg-white px-4 shadow-lg -mt-1"
  );
  const handleSubmit = async (values) => {
    await dispatch(sendCoordonnees(values.owners));
    onNextClick();
  };
  const handleSubmitValidationError = ({ errors }) => {
    if (errors.owners.slice(1).some((x) => !!x)) {
      toast.error(
        "Merci de remplir les informations sur le ou les co-emprunteur(s)"
      );
    }
  };
  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={yup.object().shape({
        owners: yup.array().of(
          yup.object().shape({
            title: yup.string().required("Veuillez remplir ce champ"),
            firstName: yup.string().required("Veuillez remplir ce champ"),
            lastName: yup.string().required("Veuillez remplir ce champ"),
            address: yup.string().required("Veuillez remplir ce champ"),
            city: yup.string().required("Veuillez remplir ce champ"),
            monthlyIncome: yup.string().required("Veuillez remplir ce champ"),
            postalCode: yup
              .string()
              .required("Veuillez remplir ce champ")
              .length(5, "Veuillez entrer un code postal valide"),
            email: yup
              .string()
              .required("Veuillez remplir ce champ")
              .matches(/\S+@\S+\.\S+/, "Veuillez entrer un email valide"),
            phoneNumber: yup
              .string()
              .required("Veuillez remplir ce champ")
              .matches(
                /^(0)([0-9]{9})$/,
                "Veuillez entrez un numéro de téléphone valide"
              ),
          })
        ),
      })}
      initialValues={{
        // owners: [
        //   {
        //     firstName: "",
        //     lastName: "",
        //     address: "",
        //     city: "",
        //     postalCode: "",
        //     email: "",
        //     phoneNumber: "",
        //     monthlyIncome: "",
        //   },
        // ],
        ...formData,
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        const panelErrors =
          openIndex !== -1 ? errors.owners && errors.owners[openIndex] : false;
        const panelTouched =
          openIndex !== -1
            ? touched.owners && touched.owners[openIndex]
            : false;
        const panelValues =
          openIndex !== -1 ? values.owners && values.owners[openIndex] : false;
        return (
          <Form>
            <FormikPatchTouched />
            <OnSubmitValidationError callback={handleSubmitValidationError} />
            <Modal
              title="Mes coordonnées"
              hideModal={hideModal}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
            >
              <div className="mt-10 relative flex mx-auto">
                <FieldArray
                  name="owners"
                  render={(arrayHelpers) => (
                    <div className="w-full">
                      <CoordoneesForm
                        isAllRequired
                        index={0}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors.owners ? errors.owners[0] : {}}
                        touched={touched.owners ? touched.owners[0] : {}}
                        values={values.owners ? values.owners[0] : {}}
                      />
                      <div className="sm:mx-0 mx-4">
                        <ArtemisSlider
                          setOpenIndex={setOpenIndex}
                          setOpen={setOpen}
                          handleOpenIndex={handleOpenIndex}
                          arrayHelpers={arrayHelpers}
                          openIndex={openIndex}
                          array={values.owners}
                          cardText="Co-emprunteur"
                          arrayForCards={values.owners.slice(1)}
                          label="Ajouter un co-emprunteur"
                          hideAddButton={values.owners.length >= 6}
                        />
                      </div>
                      <div className={panel}>
                        <CoordoneesForm
                          isAllRequired
                          showBorder
                          showSameAddressButton
                          mainValues={values.owners[0]}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          handleRemove={() => {
                            setOpen(false);
                            setOpenIndex(-1);
                            arrayHelpers.remove(openIndex);
                          }}
                          handleBlur={handleBlur}
                          index={openIndex}
                          errors={panelErrors}
                          touched={panelTouched}
                          values={panelValues}
                        />
                      </div>
                      <div>
                        <p className="auth-subheading mt-8 mb-2">
                          * Champs obligatoires
                        </p>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Coordonees;
