import acquisitionIcon from "../../../images/aquisition.svg";
import renegociationIcon from "../../../images/renegociation.svg";
import propertyIcon from "../../../images/my-house.svg";
import apartementIcon from "../../../images/apartement.svg";
import firstHouseIcon from "../../../images/first-house.svg";
import secondHouseIcon from "../../../images/second-house.svg";
import houseInvestmentIcon from "../../../images/housing-investment.svg";
import houseSearchingIcon from "../../../images/house-searching.svg";
import houseNegotiationIcon from "../../../images/house-negotiation.svg";
import signedContractIcon from "../../../images/signed-contract.svg";
import coOwnersIcon from "../../../images/coowners-house.svg";

const besoinIcons = {
  acquisition: acquisitionIcon,
  renegociation: renegociationIcon,
  OLD: acquisitionIcon,
  NEW: acquisitionIcon,
  CREDIT_REPURCHASE: renegociationIcon,
  INDIVIDUAL_HOUSE: propertyIcon,
  property: propertyIcon,
  APARTMENT: apartementIcon,
  apartment: apartementIcon,
  BUILDING: coOwnersIcon,
  coOwners: coOwnersIcon,
  primaryHouse: firstHouseIcon,
  MAIN_RESIDENCE: firstHouseIcon,
  secondHouse: secondHouseIcon,
  SECONDARY_RESIDENCE: secondHouseIcon,
  houseInvestment: houseInvestmentIcon,
  INVESTMENT: houseInvestmentIcon,
  houseSearching: houseSearchingIcon,
  IN_SEARCH: houseSearchingIcon,
  houseNegotiation: houseNegotiationIcon,
  IN_NEGOTIATION: houseNegotiationIcon,
  signedContract: signedContractIcon,
  SALE_AGREEMENT_SIGNED: signedContractIcon,
};

export default besoinIcons;
