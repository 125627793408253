import React, { useState } from "react";
import { FieldArray } from "formik";
import classNames from "classnames";
import Input from "../../Partials/Input/Input";
import BooleanRadioSelect from "../../Partials/BooleanRadioSelect/BooleanRadioSelect";
import Slider from "../../Partials/Slider/ArtemisSlider";
import CreditForm from "./CreditForm";
import serviette from "../../../images/serviette.svg";

export default function MesChargesForm({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue,
  index,
  constants,
  includeCredits,
  coEmpNumber,
}) {
  const [open, setOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const handleOpenIndex = (i) => {
    if (i + 1 === openIndex) {
      setOpen(false);
      setOpenIndex(-1);
    } else {
      setOpen(true);
      setOpenIndex(i + 1);
    }
  };
  const panel = classNames(
    {
      "block border-b-2 border-blue-500": open,
      hidden: !open,
    },
    "pt-8 bg-white p-4 shadow-lg -mt-1"
  );
  return (
    <div>
      <div className="w-full grid grid-cols-1 row-gap-5 col-gap-6 sm:grid-cols-12 relative">
        <div className="col-span-12 sm:col-span-5">
          <Input
            label="Pension alimentaire"
            onChange={handleChange}
            onBlur={handleBlur}
            // touched={touched[index].alimony}
            value={values[index].alimony}
            // errors={errors[index].alimony}
            name={`${index}.alimony`}
            type="number"
            trailIconName="euro"
          />
        </div>
        <div className="hidden sm:block sm:col-span-7" />
        {includeCredits && (
          <div className="col-span-1 sm:col-span-12">
            <div className="grid-cols-6 grid col-gap-8">
              <BooleanRadioSelect
                index={index}
                name={`${index}.hasCredits`}
                value={values[index].hasCredits}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                  setOpen(false);
                  setOpenIndex(false);
                  setFieldValue(`${index}.credits`, []);
                }}
                label="Crédits en cours"
              />
            </div>
          </div>
        )}
      </div>
      {includeCredits && (
        <FieldArray
          name={`${index}.credits`}
          render={(arrayHelpers) => (
            <div className="mt-8">
              {values[index].hasCredits === "true" && (
                <Slider
                  setOpenIndex={setOpenIndex}
                  setOpen={setOpen}
                  handleOpenIndex={handleOpenIndex}
                  maxSliderOptions={coEmpNumber * 5}
                  arrayObject={{
                    creditType: "",
                    remainingCapital: "",
                    creditHolder: "",
                    monthlyRate: "",
                    endDate: null,
                  }}
                  arrayHelpers={arrayHelpers}
                  openIndex={openIndex}
                  arrayForCards={values[index].credits}
                  array={values[index].credits}
                  label="Ajouter un crédit"
                  cardIcon={serviette}
                  cardText="Credit"
                />
              )}
              <div className={panel}>
                <CreditForm
                  coEmpIndex={index}
                  handleChange={handleChange}
                  handleRemove={() => {
                    setOpen(false);
                    setOpenIndex(-1);
                    arrayHelpers.remove(openIndex - 1);
                  }}
                  handleBlur={handleBlur}
                  index={openIndex - 1}
                  // errors={
                  //   openIndex !== -1
                  //     ? errors[index].revenus &&
                  //       errors[index].revenus[openIndex - 1]
                  //     : false
                  // }
                  // touched={
                  //   openIndex !== -1
                  //     ? touched[index].revenus &&
                  //       touched[index].revenus[openIndex - 1]
                  //     : false
                  // }
                  values={
                    openIndex !== -1
                      ? values[index].credits &&
                        values[index].credits[openIndex - 1]
                      : false
                  }
                  constants={constants}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
          )}
        />
      )}
    </div>
  );
}
